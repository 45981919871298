import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function PlayCircleLine({ size, color }: LineIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="8"
            cy="8"
            r="6"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <path
            d="M6.66669 6.26757V9.73239C6.66669 10.1317 7.11176 10.3699 7.44404 10.1484L10.0426 8.416C10.3395 8.21809 10.3395 7.78187 10.0426 7.58395L7.44404 5.85155C7.11176 5.63003 6.66669 5.86822 6.66669 6.26757Z"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="10" cy="10" r="7.5" stroke={color ?? neutralDay.gray100} strokeWidth="1.75" />
          <path
            d="M8.33331 7.60094V12.3991C8.33331 12.7984 8.77839 13.0366 9.11066 12.8151L12.7093 10.416C13.0061 10.2181 13.0061 9.78191 12.7093 9.584L9.11066 7.18492C8.77839 6.9634 8.33331 7.2016 8.33331 7.60094Z"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="12" r="9" stroke={color ?? neutralDay.gray100} strokeWidth="2" />
          <path
            d="M10 8.93426V15.0657C10 15.4651 10.4451 15.7033 10.7773 15.4818L15.376 12.416C15.6728 12.2181 15.6728 11.7819 15.376 11.584L10.7773 8.51823C10.4451 8.29671 10 8.53491 10 8.93426Z"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );
  }
}
