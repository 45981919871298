import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function ArrowDropUpLine({ size, color }: LineIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.4697 10.5287C11.7626 10.8216 12.2374 10.8216 12.5303 10.5287C12.8232 10.2359 12.8232 9.76098 12.5303 9.46808L11.4697 10.5287ZM8 5.99841L8.53033 5.46808C8.23744 5.17519 7.76256 5.17519 7.46967 5.46808L8 5.99841ZM3.46967 9.46808C3.17678 9.76098 3.17678 10.2358 3.46967 10.5287C3.76256 10.8216 4.23744 10.8216 4.53033 10.5287L3.46967 9.46808ZM12.5303 9.46808L8.53033 5.46808L7.46967 6.52874L11.4697 10.5287L12.5303 9.46808ZM7.46967 5.46808L3.46967 9.46808L4.53033 10.5287L8.53033 6.52874L7.46967 5.46808Z"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.3813 13.1187C14.723 13.4604 15.277 13.4604 15.6187 13.1187C15.9604 12.777 15.9604 12.223 15.6187 11.8813L14.3813 13.1187ZM10 7.5L10.6187 6.88128C10.277 6.53957 9.72299 6.53957 9.38128 6.88128L10 7.5ZM4.38128 11.8813C4.03957 12.223 4.03957 12.777 4.38128 13.1187C4.72299 13.4604 5.27701 13.4604 5.61872 13.1187L4.38128 11.8813ZM15.6187 11.8813L10.6187 6.88128L9.38128 8.11872L14.3813 13.1187L15.6187 11.8813ZM9.38128 6.88128L4.38128 11.8813L5.61872 13.1187L10.6187 8.11872L9.38128 6.88128Z"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.2929 15.7071C17.6834 16.0976 18.3166 16.0976 18.7071 15.7071C19.0976 15.3166 19.0976 14.6834 18.7071 14.2929L17.2929 15.7071ZM12 9L12.7071 8.29289C12.3166 7.90237 11.6834 7.90237 11.2929 8.29289L12 9ZM5.29289 14.2929C4.90237 14.6834 4.90237 15.3166 5.29289 15.7071C5.68342 16.0976 6.31658 16.0976 6.70711 15.7071L5.29289 14.2929ZM18.7071 14.2929L12.7071 8.29289L11.2929 9.70711L17.2929 15.7071L18.7071 14.2929ZM11.2929 8.29289L5.29289 14.2929L6.70711 15.7071L12.7071 9.70711L11.2929 8.29289Z"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );
  }
}
