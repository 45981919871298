import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function ArrowRightLine({ size, color }: LineIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.53033 2.13532C6.23744 1.84243 5.76256 1.84243 5.46967 2.13532C5.17678 2.42821 5.17678 2.90309 5.46967 3.19598L6.53033 2.13532ZM11.3333 7.99898L11.8637 8.52931C12.1566 8.23642 12.1566 7.76155 11.8637 7.46865L11.3333 7.99898ZM5.46967 12.802C5.17678 13.0949 5.17678 13.5698 5.46967 13.8626C5.76256 14.1555 6.23744 14.1555 6.53033 13.8626L5.46967 12.802ZM5.46967 3.19598L10.803 8.52931L11.8637 7.46865L6.53033 2.13532L5.46967 3.19598ZM10.803 7.46865L5.46967 12.802L6.53033 13.8626L11.8637 8.52931L10.803 7.46865Z"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.11872 2.71459C7.77701 2.37289 7.22299 2.37289 6.88128 2.71459C6.53957 3.0563 6.53957 3.61032 6.88128 3.95203L8.11872 2.71459ZM14.1667 9.99998L14.7854 10.6187C15.1271 10.277 15.1271 9.72297 14.7854 9.38126L14.1667 9.99998ZM6.88128 16.0479C6.53957 16.3896 6.53957 16.9437 6.88128 17.2854C7.22299 17.6271 7.77701 17.6271 8.11872 17.2854L6.88128 16.0479ZM6.88128 3.95203L13.5479 10.6187L14.7854 9.38126L8.11872 2.71459L6.88128 3.95203ZM13.5479 9.38126L6.88128 16.0479L8.11872 17.2854L14.7854 10.6187L13.5479 9.38126Z"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.70711 3.29289C9.31658 2.90237 8.68342 2.90237 8.29289 3.29289C7.90237 3.68342 7.90237 4.31658 8.29289 4.70711L9.70711 3.29289ZM17 12L17.7071 12.7071C18.0976 12.3166 18.0976 11.6834 17.7071 11.2929L17 12ZM8.29289 19.2929C7.90237 19.6834 7.90237 20.3166 8.29289 20.7071C8.68342 21.0976 9.31658 21.0976 9.70711 20.7071L8.29289 19.2929ZM8.29289 4.70711L16.2929 12.7071L17.7071 11.2929L9.70711 3.29289L8.29289 4.70711ZM16.2929 11.2929L8.29289 19.2929L9.70711 20.7071L17.7071 12.7071L16.2929 11.2929Z"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );
  }
}
