enum IntellipickColors {
  Purple100 = 'purple100',
  Purple60 = 'purple60',
  Purple40 = 'purple40',
  Purple20 = 'purple20',
  Purple10 = 'purple10',
  Purple0 = 'purple0',
}

export const intellipick: Record<IntellipickColors, string> = {
  [IntellipickColors.Purple100]: '#2E087A',
  [IntellipickColors.Purple60]: '#5029D3',
  [IntellipickColors.Purple40]: '#6E50FF',
  [IntellipickColors.Purple20]: '#AB9BF9',
  [IntellipickColors.Purple10]: '#CFC6FD',
  [IntellipickColors.Purple0]: '#F4F2FF',
};
