import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineFillIcon } from '../type';

export function ContentsPlayCircle({ size, style, color }: LineFillIcon) {
  switch (size) {
    case 16:
      switch (style) {
        case 'line':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="8"
                cy="8"
                r="6"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinejoin="round"
              />
              <path
                d="M6.66669 6.26751V9.73233C6.66669 10.1317 7.11176 10.3699 7.44404 10.1484L10.0426 8.41594C10.3395 8.21803 10.3395 7.7818 10.0426 7.58389L7.44404 5.85149C7.11176 5.62997 6.66669 5.86816 6.66669 6.26751Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.25 8C1.25 4.27208 4.27208 1.25 8 1.25C11.7279 1.25 14.75 4.27208 14.75 8C14.75 11.7279 11.7279 14.75 8 14.75C4.27208 14.75 1.25 11.7279 1.25 8ZM6.66666 9.73233V6.26751C6.66666 5.86816 7.11173 5.62997 7.44401 5.85149L10.0426 7.58389C10.3395 7.7818 10.3395 8.21803 10.0426 8.41594L7.44401 10.1484C7.11173 10.3699 6.66666 10.1317 6.66666 9.73233Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 20:
      switch (style) {
        case 'line':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="10"
                cy="10"
                r="7.5"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
              />
              <path
                d="M8.33331 7.60101V12.3992C8.33331 12.7985 8.77839 13.0367 9.11066 12.8152L12.7093 10.4161C13.0061 10.2182 13.0061 9.78197 12.7093 9.58406L9.11066 7.18498C8.77839 6.96346 8.33331 7.20166 8.33331 7.60101Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.625 10C1.625 5.37462 5.37462 1.625 10 1.625C14.6254 1.625 18.375 5.37462 18.375 10C18.375 14.6254 14.6254 18.375 10 18.375C5.37462 18.375 1.625 14.6254 1.625 10ZM8.33334 12.3992V7.60101C8.33334 7.20166 8.77842 6.96346 9.11069 7.18498L12.7093 9.58406C13.0062 9.78197 13.0062 10.2182 12.7093 10.4161L9.11069 12.8152C8.77842 13.0367 8.33334 12.7985 8.33334 12.3992Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 24:
      switch (style) {
        case 'line':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="12" cy="12" r="9" stroke={color ?? neutralDay.gray90} strokeWidth="2" />
              <path
                d="M10 8.93426V15.0657C10 15.4651 10.4451 15.7033 10.7773 15.4818L15.376 12.416C15.6728 12.2181 15.6728 11.7819 15.376 11.584L10.7773 8.51823C10.4451 8.29671 10 8.53491 10 8.93426Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM10.7773 15.4818C10.4451 15.7033 10 15.4651 10 15.0657V8.93426C10 8.53491 10.4451 8.29671 10.7773 8.51823L15.376 11.584C15.6728 11.7819 15.6728 12.2181 15.376 12.416L10.7773 15.4818Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
  }
}
