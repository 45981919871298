import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type FillIcon } from '../type';
import { SVGProps } from 'react';

export function RadioOnFill({
  size,
  primaryColor = neutralDay.gray100,
  secondaryColor = neutralDay.gray100,
  ...props
}: FillIcon & SVGProps<SVGSVGElement>) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <circle cx="8" cy="8" r="6" stroke={primaryColor} strokeWidth="1.5" />
          <circle
            cx="8.00004"
            cy="7.99992"
            r="2.66667"
            fill={secondaryColor}
            stroke={secondaryColor}
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="10" cy="10" r="7.5" stroke={primaryColor} strokeWidth="1.75" />
          <circle
            cx="9.99996"
            cy="10.0001"
            r="3.33333"
            fill={secondaryColor}
            stroke={secondaryColor}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="12" r="9" stroke={primaryColor} strokeWidth="2" />
          <circle
            cx="12"
            cy="12"
            r="4"
            fill={secondaryColor}
            stroke={secondaryColor}
            strokeWidth="2"
          />
        </svg>
      );
  }
}
