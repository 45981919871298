enum NeutralDarkColors {
  White = 'white',
  Gray0 = 'gray0',
  Gray5 = 'gray5',
  Gray10 = 'gray10',
  Gray20 = 'gray20',
  Gray30 = 'gray30',
  Gray40 = 'gray40',
  Gray50 = 'gray50',
  Gray60 = 'gray60',
  Gray70 = 'gray70',
  Gray80 = 'gray80',
  Gray90 = 'gray90',
  Gray95 = 'gray95',
  Gray100 = 'gray100',
}

export const neutralDark: Record<NeutralDarkColors, string> = {
  [NeutralDarkColors.White]: '#141617',
  [NeutralDarkColors.Gray0]: '#1D1F20',
  [NeutralDarkColors.Gray5]: '#222425',
  [NeutralDarkColors.Gray10]: '#2B2D2E',
  [NeutralDarkColors.Gray20]: '#3A3E41',
  [NeutralDarkColors.Gray30]: '#484D51',
  [NeutralDarkColors.Gray40]: '#565D61',
  [NeutralDarkColors.Gray50]: '#6A7177',
  [NeutralDarkColors.Gray60]: '#81898F',
  [NeutralDarkColors.Gray70]: '#9DA7AE',
  [NeutralDarkColors.Gray80]: '#B4BFC6',
  [NeutralDarkColors.Gray90]: '#C7D2D8',
  [NeutralDarkColors.Gray95]: '#D9E2E8',
  [NeutralDarkColors.Gray100]: '#EEF3F6',
};
