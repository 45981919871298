import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function SleepLine({ size, color }: LineIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 4.66669V8.00002"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.2"
            strokeLinecap="round"
          />
          <path
            d="M10.667 8H8.00033"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.2"
            strokeLinecap="round"
          />
          <path
            d="M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <rect
            x="10.2798"
            y="2.08002"
            width="1.5"
            height="1.5"
            rx="0.75"
            fill={color ?? neutralDay.gray100}
          />
          <rect
            x="12.48"
            y="4.28003"
            width="1.5"
            height="1.5"
            rx="0.75"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 5.83337V10"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M13.3335 10H10.0002"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <rect
            x="12.8799"
            y="2.63"
            width="1.75"
            height="1.75"
            rx="0.875"
            fill={color ?? neutralDay.gray100}
          />
          <rect
            x="15.6201"
            y="5.38"
            width="1.75"
            height="1.75"
            rx="0.875"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 7V12"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M16 12H12"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinecap="round"
          />
          <rect
            x="15.5"
            y="3.20001"
            width="2"
            height="2"
            rx="1"
            fill={color ?? neutralDay.gray100}
          />
          <rect x="18.79" y="6.5" width="2" height="2" rx="1" fill={color ?? neutralDay.gray100} />
        </svg>
      );
  }
}
