import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function FileLine({ size, color }: LineIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 7.58232V9.99898C4 12.2081 5.79086 13.999 8 13.999V13.999C10.2091 13.999 12 12.2081 12 9.99898V4.66565"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.33333 6V4.66667C9.33333 3.19391 8.13943 2 6.66667 2V2C5.19391 2 4 3.19391 4 4.66667V10"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M6.66565 4.66565V9.99898C6.66565 10.7354 7.2626 11.3323 7.99898 11.3323V11.3323C8.73536 11.3323 9.33232 10.7354 9.33232 9.99898V7.99898V5.99898"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 9.47915V12.5C5 15.2614 7.23858 17.5 10 17.5V17.5C12.7614 17.5 15 15.2614 15 12.5V5.83331"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.6667 7.5V5.83333C11.6667 3.99238 10.1743 2.5 8.33333 2.5V2.5C6.49238 2.5 5 3.99238 5 5.83333V12.5"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <path
            d="M8.33337 5.83331V12.5C8.33337 13.4205 9.07957 14.1666 10 14.1666V14.1666C10.9205 14.1666 11.6667 13.4205 11.6667 12.5V9.99998V7.49998"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 11.375V15C6 18.3137 8.68629 21 12 21V21C15.3137 21 18 18.3137 18 15V7"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14 9V7C14 4.79086 12.2091 3 10 3V3C7.79086 3 6 4.79086 6 7V15"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M10 7V15C10 16.1046 10.8954 17 12 17V17C13.1046 17 14 16.1046 14 15V12V9"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      );
  }
}
