import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineFillIcon } from '../type';

export function NavigationMyPage({ size, style, color }: LineFillIcon) {
  switch (size) {
    case 16:
      switch (style) {
        case 'line':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="8.0021"
                cy="5.33333"
                r="2.58333"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
              />
              <path
                d="M2.79999 13.3344C3.83742 11.5409 5.77645 10.3344 7.99729 10.3344C10.2181 10.3344 12.1572 11.5409 13.1946 13.3344"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="8.0021" cy="5.33333" r="3.33333" fill={color ?? neutralDay.gray90} />
              <path
                d="M7.9973 9.58447C5.58806 9.58447 2.94828 10.8421 2.09927 13.0671C2.01131 13.2976 2.04236 13.5567 2.18232 13.7599C2.32228 13.9631 2.55324 14.0845 2.79999 14.0845H13.1946C13.441 14.0845 13.6717 13.9634 13.8117 13.7607C13.9518 13.5579 13.9833 13.2993 13.896 13.0689C13.0525 10.8412 10.405 9.58447 7.9973 9.58447Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 20:
      switch (style) {
        case 'line':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="9.99998"
                cy="6.66667"
                r="3.29167"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
              />
              <path
                d="M3.50262 16.6666C4.79941 14.4249 7.2232 12.9166 9.99926 12.9166C12.7753 12.9166 15.1991 14.4249 16.4959 16.6666"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
                strokeLinecap="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="9.99998" cy="6.66667" r="4.16667" fill={color ?? neutralDay.gray90} />
              <path
                d="M9.99927 12.0415C8.45963 12.0415 6.81553 12.4767 5.47552 13.212C4.15901 13.9344 2.97859 15.0386 2.64982 16.4707C2.59019 16.7304 2.65217 17.0032 2.81818 17.2116C2.9842 17.4201 3.23615 17.5415 3.50263 17.5415H16.4959C16.7623 17.5415 17.0141 17.4202 17.1802 17.2118C17.3462 17.0035 17.4083 16.7309 17.3488 16.4713C17.0209 15.0387 15.8401 13.9343 14.5235 13.2119C13.1833 12.4766 11.5389 12.0415 9.99927 12.0415Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 24:
      switch (style) {
        case 'line':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="12" cy="8" r="4" stroke={color ?? neutralDay.gray90} strokeWidth="2" />
              <path
                d="M4.20703 19.998C5.76317 17.3079 8.67172 15.498 12.003 15.498C15.3343 15.498 18.2428 17.3079 19.799 19.998"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="12" cy="8" r="5" fill={color ?? neutralDay.gray90} />
              <path
                d="M12.003 14.498C8.40977 14.498 4.78788 16.126 3.28879 19.602C3.15551 19.9111 3.18662 20.2664 3.37157 20.5476C3.55651 20.8288 3.87049 20.998 4.20703 20.998H19.799C20.1358 20.998 20.45 20.8285 20.6349 20.5469C20.8198 20.2653 20.8504 19.9096 20.7165 19.6005C19.2115 16.1268 15.5976 14.498 12.003 14.498Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
  }
}
