import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function RadioOnLine({ size, color }: LineIcon) {
  switch (size) {
    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="10" cy="10" r="7.5" stroke={color ?? neutralDay.gray100} strokeWidth="1.75" />
          <circle
            cx="10"
            cy="10"
            r="3.33333"
            fill={color ?? neutralDay.gray100}
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="12" r="9" stroke={color ?? neutralDay.gray100} strokeWidth="2" />
          <circle
            cx="12"
            cy="12"
            r="4"
            fill={color ?? neutralDay.gray100}
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
          />
        </svg>
      );
    default:
      return null;
  }
}
