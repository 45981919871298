import { type LineOnlyIcon } from '../type';
import { neutralDay } from '@/_global/_styles/design-system/foundation/color';

export function ContentsChart({ size, color }: LineOnlyIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 4.00008C6 3.2637 6.59695 2.66675 7.33333 2.66675H8.66667C9.40305 2.66675 10 3.2637 10 4.00008V13.3334H6V4.00008Z"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
          />
          <path
            d="M2.66663 9.33333C2.66663 8.59695 3.26358 8 3.99996 8H5.99996V13.3333H3.33329C2.9651 13.3333 2.66663 13.0349 2.66663 12.6667V9.33333Z"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
          />
          <path
            d="M10 5.33325H12C12.7364 5.33325 13.3333 5.93021 13.3333 6.66659V12.6666C13.3333 13.0348 13.0349 13.3333 12.6667 13.3333H10V5.33325Z"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.5 5.00004C7.5 4.07957 8.24619 3.33337 9.16667 3.33337H10.8333C11.7538 3.33337 12.5 4.07957 12.5 5.00004V16.6667H7.5V5.00004Z"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
          />
          <path
            d="M3.33337 11.6667C3.33337 10.7462 4.07957 10 5.00004 10H7.50004V16.6667H4.16671C3.70647 16.6667 3.33337 16.2936 3.33337 15.8333V11.6667Z"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
          />
          <path
            d="M12.5 6.66663H15C15.9205 6.66663 16.6667 7.41282 16.6667 8.33329V15.8333C16.6667 16.2935 16.2936 16.6666 15.8333 16.6666H12.5V6.66663Z"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 6C9 4.89543 9.89543 4 11 4H13C14.1046 4 15 4.89543 15 6V20H9V6Z"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
          />
          <path
            d="M4 14C4 12.8954 4.89543 12 6 12H9V20H5C4.44772 20 4 19.5523 4 19V14Z"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
          />
          <path
            d="M15 8H18C19.1046 8 20 8.89543 20 10V19C20 19.5523 19.5523 20 19 20H15V8Z"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
          />
        </svg>
      );
  }
}
