enum NeutralDayColors {
  White = 'white',
  Gray0 = 'gray0',
  Gray5 = 'gray5',
  Gray10 = 'gray10',
  Gray20 = 'gray20',
  Gray30 = 'gray30',
  Gray40 = 'gray40',
  Gray50 = 'gray50',
  Gray60 = 'gray60',
  Gray70 = 'gray70',
  Gray80 = 'gray80',
  Gray90 = 'gray90',
  Gray95 = 'gray95',
  Gray100 = 'gray100',
}

export const neutralDay: Record<NeutralDayColors, string> = {
  [NeutralDayColors.White]: '#FFFFFF',
  [NeutralDayColors.Gray0]: '#F6F9FA',
  [NeutralDayColors.Gray5]: '#F2F6F8',
  [NeutralDayColors.Gray10]: '#EEF3F6',
  [NeutralDayColors.Gray20]: '#E4EBF0',
  [NeutralDayColors.Gray30]: '#D7E0E6',
  [NeutralDayColors.Gray40]: '#C7D2D8',
  [NeutralDayColors.Gray50]: '#B4BFC6',
  [NeutralDayColors.Gray60]: '#9DA7AE',
  [NeutralDayColors.Gray70]: '#81898F',
  [NeutralDayColors.Gray80]: '#5F666B',
  [NeutralDayColors.Gray90]: '#3A3E41',
  [NeutralDayColors.Gray95]: '#25282A',
  [NeutralDayColors.Gray100]: '#141617',
};
