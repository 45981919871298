import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type FillIcon } from '../type';

export function PlayFill({ size, primaryColor }: FillIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 4.3737V11.6263C6 11.786 6.17803 11.8813 6.31094 11.7927L11.7504 8.16641C11.8691 8.08725 11.8691 7.91275 11.7504 7.83359L6.31094 4.20729C6.17803 4.11869 6 4.21396 6 4.3737Z"
            fill={primaryColor ?? neutralDay.gray100}
            stroke={primaryColor ?? neutralDay.gray100}
            strokeWidth="1.5"
          />
        </svg>
      );
    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.5 5.3737V14.6263C7.5 14.786 7.67803 14.8813 7.81094 14.7927L14.7504 10.1664C14.8691 10.0872 14.8691 9.91275 14.7504 9.83359L7.81094 5.20729C7.67803 5.11869 7.5 5.21396 7.5 5.3737Z"
            fill={primaryColor ?? neutralDay.gray100}
            stroke={primaryColor ?? neutralDay.gray100}
            strokeWidth="1.5"
          />
        </svg>
      );
    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 6.3737V17.6263C9 17.786 9.17803 17.8813 9.31094 17.7927L17.7504 12.1664C17.8691 12.0872 17.8691 11.9128 17.7504 11.8336L9.31094 6.20729C9.17803 6.11869 9 6.21396 9 6.3737Z"
            fill={primaryColor ?? neutralDay.gray100}
            stroke={primaryColor ?? neutralDay.gray100}
            strokeWidth="1.5"
          />
        </svg>
      );
  }
}
