import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function NoticeLine({ size, color }: LineIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 14.6656H10"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M2 12H14"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M3.33435 6.66667C3.33435 4.08934 5.42369 2 8.00102 2V2C10.5783 2 12.6677 4.08934 12.6677 6.66667V12H3.33435V6.66667Z"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.5 18.3333H12.5"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <path
            d="M2.5 15H17.5"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <path
            d="M4.16663 8.33333C4.16663 5.11167 6.7783 2.5 9.99996 2.5V2.5C13.2216 2.5 15.8333 5.11167 15.8333 8.33333V15H4.16663V8.33333Z"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 22H15"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M3 18H21"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M5 10C5 6.13401 8.13401 3 12 3V3C15.866 3 19 6.13401 19 10V18H5V10Z"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
          />
        </svg>
      );
  }
}
