import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineFillIcon } from '../type';

export function InfoTimer({ size, style, color }: LineFillIcon) {
  switch (size) {
    case 16:
      switch (style) {
        case 'line':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.3323 8.66781C13.3323 11.6133 10.9445 14.0011 7.99898 14.0011C5.05346 14.0011 2.66565 11.6133 2.66565 8.66781C2.66565 5.72229 5.05346 3.33447 7.99898 3.33447C10.9445 3.33447 13.3323 5.72229 13.3323 8.66781Z"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
              />
              <path
                d="M8 6V8.66667"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M13 3.66553L12.1144 4.55115"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M6 1.33447H10"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.25 1.33447C5.25 0.920259 5.58579 0.584473 6 0.584473H10C10.4142 0.584473 10.75 0.920259 10.75 1.33447C10.75 1.74869 10.4142 2.08447 10 2.08447H6C5.58579 2.08447 5.25 1.74869 5.25 1.33447Z"
                fill={color ?? neutralDay.gray90}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.91553 8.66781C1.91553 5.30807 4.63913 2.58447 7.99886 2.58447C9.40816 2.58447 10.7055 3.0637 11.7369 3.86808L12.4697 3.13531C12.7626 2.84242 13.2374 2.84242 13.5303 3.13531C13.8232 3.4282 13.8232 3.90308 13.5303 4.19597L12.7977 4.92861C13.6026 5.96017 14.0822 7.25798 14.0822 8.66781C14.0822 12.0275 11.3586 14.7511 7.99886 14.7511C4.63913 14.7511 1.91553 12.0275 1.91553 8.66781ZM8.5999 5.9999C8.5999 5.66853 8.33127 5.3999 7.9999 5.3999C7.66853 5.3999 7.3999 5.66853 7.3999 5.9999V8.66657C7.3999 8.99794 7.66853 9.26657 7.9999 9.26657C8.33127 9.26657 8.5999 8.99794 8.5999 8.66657V5.9999Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 20:
      switch (style) {
        case 'line':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.6666 10.8334C16.6666 14.5153 13.6819 17.5001 9.99998 17.5001C6.31808 17.5001 3.33331 14.5153 3.33331 10.8334C3.33331 7.15152 6.31808 4.16675 9.99998 4.16675C13.6819 4.16675 16.6666 7.15152 16.6666 10.8334Z"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
              />
              <path
                d="M10 7.5V10.8333"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
                strokeLinecap="round"
              />
              <path
                d="M16.25 4.58325L15.143 5.69027"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
                strokeLinecap="round"
              />
              <path
                d="M7.5 1.66675H12.5"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
                strokeLinecap="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.625 1.66675C6.625 1.1835 7.01675 0.791748 7.5 0.791748H12.5C12.9832 0.791748 13.375 1.1835 13.375 1.66675C13.375 2.15 12.9832 2.54175 12.5 2.54175H7.5C7.01675 2.54175 6.625 2.15 6.625 1.66675Z"
                fill={color ?? neutralDay.gray90}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.45825 10.8334C2.45825 6.66827 5.83477 3.29175 9.99992 3.29175C11.7672 3.29175 13.3925 3.89965 14.6782 4.91775L15.6313 3.96467C15.973 3.62296 16.527 3.62296 16.8687 3.96467C17.2104 4.30637 17.2104 4.86039 16.8687 5.2021L15.9156 6.1552C16.9337 7.44083 17.5416 9.06614 17.5416 10.8334C17.5416 14.9986 14.1651 18.3751 9.99992 18.3751C5.83477 18.3751 2.45825 14.9986 2.45825 10.8334ZM10.75 7.5C10.75 7.08579 10.4142 6.75 10 6.75C9.58579 6.75 9.25 7.08579 9.25 7.5V10.8333C9.25 11.2475 9.58579 11.5833 10 11.5833C10.4142 11.5833 10.75 11.2475 10.75 10.8333V7.5Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 24:
      switch (style) {
        case 'line':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 13C20 17.4183 16.4183 21 12 21C7.58172 21 4 17.4183 4 13C4 8.58172 7.58172 5 12 5C16.4183 5 20 8.58172 20 13Z"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="2"
              />
              <path
                d="M12 9V13"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M19.5 5.5L18.1716 6.82843"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M9 2H15"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 2C8 1.44772 8.44772 1 9 1H15C15.5523 1 16 1.44772 16 2C16 2.55228 15.5523 3 15 3H9C8.44772 3 8 2.55228 8 2Z"
                fill={color ?? neutralDay.gray90}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 13C3 8.02944 7.02944 4 12 4C14.125 4 16.078 4.73646 17.6176 5.96806L18.7929 4.7928C19.1834 4.40228 19.8166 4.40228 20.2071 4.7928C20.5976 5.18332 20.5976 5.81649 20.2071 6.20701L19.0319 7.38226C20.2635 8.92194 21 10.875 21 13C21 17.9706 16.9706 22 12 22C7.02944 22 3 17.9706 3 13ZM12.875 9C12.875 8.51675 12.4832 8.125 12 8.125C11.5168 8.125 11.125 8.51675 11.125 9V13C11.125 13.4832 11.5168 13.875 12 13.875C12.4832 13.875 12.875 13.4832 12.875 13V9Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
  }
}
