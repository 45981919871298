import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineFillIcon } from '../type';

export function InfoBell({ size, style, color }: LineFillIcon) {
  switch (size) {
    case 16:
      switch (style) {
        case 'line':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 14.6655H10"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M2 12H14"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M3.33435 6.66667C3.33435 4.08934 5.42369 2 8.00102 2V2C10.5783 2 12.6677 4.08934 12.6677 6.66667V12H3.33435V6.66667Z"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.00114 1.25C5.0096 1.25 2.58447 3.67512 2.58447 6.66667V11.25H2C1.58579 11.25 1.25 11.5858 1.25 12C1.25 12.4142 1.58579 12.75 2 12.75H14C14.4142 12.75 14.75 12.4142 14.75 12C14.75 11.5858 14.4142 11.25 14 11.25H13.4178V6.66667C13.4178 3.67512 10.9927 1.25 8.00114 1.25Z"
                fill={color ?? neutralDay.gray90}
              />
              <path
                d="M6 13.9155C5.58579 13.9155 5.25 14.2513 5.25 14.6655C5.25 15.0797 5.58579 15.4155 6 15.4155H10C10.4142 15.4155 10.75 15.0797 10.75 14.6655C10.75 14.2513 10.4142 13.9155 10 13.9155H6Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 20:
      switch (style) {
        case 'line':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.5 18.3333H12.5"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
                strokeLinecap="round"
              />
              <path
                d="M2.5 15H17.5"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
                strokeLinecap="round"
              />
              <path
                d="M4.16669 8.33333C4.16669 5.11167 6.77836 2.5 10 2.5V2.5C13.2217 2.5 15.8334 5.11167 15.8334 8.33333V15H4.16669V8.33333Z"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.0001 1.625C6.29517 1.625 3.29175 4.62842 3.29175 8.33333V14.125H2.5C2.01675 14.125 1.625 14.5168 1.625 15C1.625 15.4832 2.01675 15.875 2.5 15.875H17.5C17.9832 15.875 18.375 15.4832 18.375 15C18.375 14.5168 17.9832 14.125 17.5 14.125H16.7084V8.33333C16.7084 4.62842 13.705 1.625 10.0001 1.625Z"
                fill={color ?? neutralDay.gray90}
              />
              <path
                d="M7.5 17.4583C7.01675 17.4583 6.625 17.85 6.625 18.3333C6.625 18.8165 7.01675 19.2083 7.5 19.2083H12.5C12.9832 19.2083 13.375 18.8165 13.375 18.3333C13.375 17.85 12.9832 17.4583 12.5 17.4583H7.5Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 24:
      switch (style) {
        case 'line':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 18H21"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M5 10C5 6.13401 8.13401 3 12 3V3C15.866 3 19 6.13401 19 10V18H5V10Z"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="2"
              />
              <path
                d="M9 22H15"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 10C4 5.58172 7.58172 2 12 2C16.4183 2 20 5.58172 20 10V17H21C21.5523 17 22 17.4477 22 18C22 18.5523 21.5523 19 21 19H3C2.44772 19 2 18.5523 2 18C2 17.4477 2.44772 17 3 17H4V10Z"
                fill={color ?? neutralDay.gray90}
              />
              <path
                d="M9 21C8.44772 21 8 21.4477 8 22C8 22.5523 8.44772 23 9 23H15C15.5523 23 16 22.5523 16 22C16 21.4477 15.5523 21 15 21H9Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
  }
}
