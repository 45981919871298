import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineFillIcon } from '../type';

export function ActionThumbUp({ size, style, color }: LineFillIcon) {
  switch (size) {
    case 16:
      switch (style) {
        case 'line':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="2"
                y="6.66565"
                width="1.33333"
                height="6"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinejoin="round"
              />
              <path
                d="M6 6.96547C6 6.34164 6.21871 5.73755 6.61808 5.25831L9.1972 2.16336C9.28346 2.05985 9.41124 2 9.54598 2V2C9.79673 2 10 2.20327 10 2.45402V4.66667C10 5.40305 10.597 6 11.3333 6H13.3333C13.7015 6 14 6.29848 14 6.66667V8.91304C14 9.1866 13.9159 9.45354 13.759 9.67765L12.0648 12.098C11.8153 12.4544 11.4075 12.6667 10.9725 12.6667H6.66667C6.29848 12.6667 6 12.3682 6 12V6.96547Z"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinejoin="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.62103 1.68322C8.84979 1.40872 9.18866 1.25 9.54598 1.25C10.2109 1.25 10.75 1.78906 10.75 2.45402V4.66667C10.75 4.98883 11.0112 5.25 11.3333 5.25H13.3333C14.1157 5.25 14.75 5.88426 14.75 6.66667V8.91304C14.75 9.34048 14.6185 9.75758 14.3734 10.1078L12.6792 12.528C12.2893 13.085 11.6523 13.4167 10.9725 13.4167H6.66667C5.88426 13.4167 5.25 12.7824 5.25 12V6.96547C5.25 6.16618 5.53022 5.3922 6.04191 4.77817L8.62103 1.68322Z"
                fill={color ?? neutralDay.gray90}
              />
              <path
                d="M1.25 6.66565C1.25 6.25144 1.58579 5.91565 2 5.91565H3.33333C3.74755 5.91565 4.08333 6.25144 4.08333 6.66565V12.6656C4.08333 13.0799 3.74755 13.4156 3.33333 13.4156H2C1.58579 13.4156 1.25 13.0799 1.25 12.6656V6.66565Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 20:
      switch (style) {
        case 'line':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="2.5"
                y="8.33337"
                width="1.66667"
                height="7.5"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
                strokeLinejoin="round"
              />
              <path
                d="M7.5 8.70683C7.5 7.92704 7.77339 7.17193 8.2726 6.57288L11.4965 2.7042C11.6043 2.57481 11.7641 2.5 11.9325 2.5V2.5C12.2459 2.5 12.5 2.75409 12.5 3.06752V5.83333C12.5 6.75381 13.2462 7.5 14.1667 7.5H16.6667C17.1269 7.5 17.5 7.8731 17.5 8.33333V11.1413C17.5 11.4832 17.3948 11.8169 17.1987 12.0971L15.081 15.1224C14.7691 15.568 14.2594 15.8333 13.7156 15.8333H8.33333C7.8731 15.8333 7.5 15.4602 7.5 15V8.70683Z"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
                strokeLinejoin="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.8243 2.14404C11.0984 1.81516 11.5044 1.625 11.9325 1.625C12.7292 1.625 13.375 2.27084 13.375 3.06752V5.83333C13.375 6.27056 13.7294 6.625 14.1667 6.625H16.6667C17.6102 6.625 18.375 7.38985 18.375 8.33333V11.1413C18.375 11.6628 18.2146 12.1716 17.9155 12.5988L15.7978 15.6242C15.3222 16.3037 14.545 16.7083 13.7156 16.7083H8.33333C7.38985 16.7083 6.625 15.9435 6.625 15V8.70683C6.625 7.72235 6.97015 6.76902 7.6004 6.01272L10.8243 2.14404Z"
                fill={color ?? neutralDay.gray90}
              />
              <path
                d="M1.625 8.33337C1.625 7.85012 2.01675 7.45837 2.5 7.45837H4.16667C4.64992 7.45837 5.04167 7.85012 5.04167 8.33337V15.8334C5.04167 16.3166 4.64992 16.7084 4.16667 16.7084H2.5C2.01675 16.7084 1.625 16.3166 1.625 15.8334V8.33337Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 24:
      switch (style) {
        case 'line':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="3"
                y="10"
                width="2"
                height="9"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="2"
                strokeLinejoin="round"
              />
              <path
                d="M9 10.4482C9 9.51245 9.32806 8.60632 9.92711 7.88746L13.7958 3.24504C13.9252 3.08977 14.1169 3 14.319 3V3C14.6951 3 15 3.30491 15 3.68102V7C15 8.10457 15.8954 9 17 9H20C20.5523 9 21 9.44772 21 10V13.3696C21 13.7799 20.8738 14.1803 20.6385 14.5165L18.0972 18.1469C17.7229 18.6816 17.1113 19 16.4587 19H10C9.44772 19 9 18.5523 9 18V10.4482Z"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="2"
                strokeLinejoin="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.0276 2.60486C13.347 2.2216 13.8201 2 14.319 2C15.2474 2 16 2.75262 16 3.68103V7C16 7.55228 16.4477 8 17 8H20C21.1046 8 22 8.89543 22 10V13.3696C22 13.9851 21.8107 14.5857 21.4577 15.0899L18.9164 18.7204C18.355 19.5224 17.4376 20 16.4587 20H10C8.89543 20 8 19.1046 8 18V10.4482C8 9.27852 8.41008 8.14585 9.15889 7.24728L13.0276 2.60486Z"
                fill={color ?? neutralDay.gray90}
              />
              <path
                d="M2 10C2 9.44772 2.44772 9 3 9H5C5.55228 9 6 9.44772 6 10V19C6 19.5523 5.55228 20 5 20H3C2.44772 20 2 19.5523 2 19V10Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
  }
}
