import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function NoteLine({ size, color }: LineIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="2.66669"
            y="2"
            width="10.6667"
            height="12"
            rx="1.33333"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <path
            d="M5.33331 5.33331L10.6666 5.33331"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.33331 8L10.6666 8"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.33331 10.6667L8.66665 10.6667"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="3.33325"
            y="2.5"
            width="13.3333"
            height="15"
            rx="1.66667"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinejoin="round"
          />
          <path
            d="M6.66675 6.6665L13.3334 6.6665"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M6.66675 10L13.3334 10"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M6.66675 13.3335L10.8334 13.3335"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="4"
            y="3"
            width="16"
            height="18"
            rx="2"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinejoin="round"
          />
          <path
            d="M8 8L16 8"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <path
            d="M8 12L16 12"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <path
            d="M8 16L13 16"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
        </svg>
      );
  }
}
