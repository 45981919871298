import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';
import { ComponentProps } from 'react';

export function CheckboxLine({ size, color, ...props }: LineIcon & ComponentProps<'svg'>) {
  switch (size) {
    case 16:
      return <div>이미지가 없습니다.</div>;
    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <rect
            x="3.33331"
            y="3.33331"
            width="13.3333"
            height="13.3333"
            rx="2"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <rect
            x="4"
            y="4"
            width="16"
            height="16"
            rx="2"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
}
