import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function CalendarLine({ size, color }: LineIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="2.66669"
            y="3.83325"
            width="10.6667"
            height="10.6667"
            rx="1.33333"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
          />
          <path
            d="M2.66669 7.16675L13.3334 7.16675"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M5.33331 2.5V3.83333"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M10.6667 2.5V3.83333"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="3.33331"
            y="4.66675"
            width="13.3333"
            height="13.3333"
            rx="1.66667"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.66667"
            strokeLinejoin="round"
          />
          <path
            d="M3.33331 8.83325L16.6666 8.83325"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.66667"
            strokeLinecap="round"
          />
          <path
            d="M6.66669 3V4.66667"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.66667"
            strokeLinecap="round"
          />
          <path
            d="M13.3333 3V4.66667"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.66667"
            strokeLinecap="round"
          />
        </svg>
      );
    case 24:
      return (
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="4"
            y="5.5"
            width="16"
            height="16"
            rx="2"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinejoin="round"
          />
          <path
            d="M4 10.5L20 10.5"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M8 3.5V5.5"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M16 3.5V5.5"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      );
  }
}
