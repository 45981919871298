import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineFillIcon } from '../type';

export function InfoHelp({ size, style, color }: LineFillIcon) {
  switch (size) {
    case 16:
      switch (style) {
        case 'line':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="8" cy="8" r="6" stroke={color ?? neutralDay.gray90} strokeWidth="1.5" />
              <path
                d="M6.35303 6.56885C6.35303 6.23551 6.55303 5.50281 7.35303 5.23614C8.35303 4.90281 9.45214 5.39923 9.61969 6.23617C9.75303 6.90218 9.35303 7.23551 9.01969 7.56936C8.68662 7.90295 7.99886 8.23551 7.99886 8.90218"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <rect
                x="7.33447"
                y="10.1655"
                width="1.33333"
                height="1.33333"
                rx="0.666667"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 1.25C4.27208 1.25 1.25 4.27208 1.25 8C1.25 11.7279 4.27208 14.75 8 14.75C11.7279 14.75 14.75 11.7279 14.75 8C14.75 4.27208 11.7279 1.25 8 1.25ZM8.59662 5.75614C8.28769 5.61879 7.8917 5.586 7.51236 5.71244C6.98775 5.88731 6.85425 6.36663 6.85425 6.57081C6.85425 6.84695 6.63039 7.07081 6.35425 7.07081C6.07811 7.07081 5.85425 6.84695 5.85425 6.57081C5.85425 6.10831 6.12074 5.12222 7.19613 4.76376C7.8168 4.55687 8.47036 4.60562 9.00288 4.84239C9.53237 5.0778 9.98793 5.52433 10.1112 6.13998C10.2038 6.60234 10.1097 6.97816 9.92465 7.28655C9.78182 7.52459 9.57995 7.72276 9.43712 7.86298C9.41472 7.88497 9.39378 7.90553 9.37474 7.9246C9.27281 8.02668 9.15029 8.12401 9.04948 8.20409L9.03207 8.21792C8.91862 8.30812 8.82067 8.38697 8.73459 8.47087C8.56447 8.6367 8.50008 8.76512 8.50008 8.90414C8.50008 9.18028 8.27622 9.40414 8.00008 9.40414C7.72394 9.40414 7.50008 9.18028 7.50008 8.90414C7.50008 8.37649 7.77957 8.00529 8.0366 7.75477C8.16409 7.6305 8.30053 7.522 8.40972 7.43518C8.52849 7.34074 8.60842 7.27679 8.66709 7.21803C8.69567 7.18941 8.7227 7.16259 8.74829 7.13719C8.89536 6.99121 8.99499 6.89232 9.06718 6.77202C9.13213 6.66378 9.17141 6.53992 9.13064 6.33628C9.08634 6.11499 8.90857 5.89484 8.59662 5.75614ZM7.99593 10.2458C7.67376 10.2458 7.4126 10.507 7.4126 10.8292C7.4126 11.1513 7.67376 11.4125 7.99593 11.4125C8.3181 11.4125 8.57926 11.1513 8.57926 10.8292C8.57926 10.507 8.3181 10.2458 7.99593 10.2458Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 20:
      switch (style) {
        case 'line':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="10"
                cy="10"
                r="7.5"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
              />
              <path
                d="M7.94275 8.21289C7.94275 7.79622 8.19275 6.88035 9.19275 6.54701C10.4427 6.13035 11.8166 6.75087 12.0261 7.79705C12.1927 8.62955 11.6927 9.04622 11.2761 9.46353C10.8597 9.88051 10 10.2962 10 11.1296"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <rect
                x="9.16663"
                y="12.7083"
                width="1.66667"
                height="1.66667"
                rx="0.833333"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 1.625C5.37462 1.625 1.625 5.37462 1.625 10C1.625 14.6254 5.37462 18.375 10 18.375C14.6254 18.375 18.375 14.6254 18.375 10C18.375 5.37462 14.6254 1.625 10 1.625ZM10.7456 7.19457C10.3594 7.02288 9.86444 6.98189 9.39027 7.13994C8.73451 7.35853 8.56763 7.95768 8.56763 8.2129C8.56763 8.55807 8.28781 8.8379 7.94263 8.8379C7.59745 8.8379 7.31763 8.55807 7.31763 8.2129C7.31763 7.63478 7.65075 6.40217 8.99498 5.95409C9.77081 5.69548 10.5878 5.75642 11.2534 6.05237C11.9153 6.34664 12.4847 6.9048 12.6388 7.67436C12.7545 8.25232 12.6369 8.72209 12.4056 9.10757C12.2271 9.40512 11.9748 9.65284 11.7962 9.82811C11.7683 9.85549 11.742 9.88138 11.7182 9.90514C11.5908 10.0327 11.4377 10.1544 11.3117 10.2545L11.2899 10.2718C11.1481 10.3845 11.0257 10.4831 10.9181 10.588C10.7054 10.7953 10.6249 10.9558 10.6249 11.1296C10.6249 11.4747 10.3451 11.7546 9.99992 11.7546C9.65474 11.7546 9.37492 11.4747 9.37492 11.1296C9.37492 10.47 9.72429 10.006 10.0456 9.69285C10.2049 9.53752 10.3755 9.40189 10.512 9.29336C10.6604 9.17532 10.7603 9.09538 10.8337 9.02193C10.8695 8.9861 10.9033 8.95252 10.9353 8.92073C11.1192 8.73826 11.2436 8.61479 11.3338 8.46441C11.415 8.32911 11.4641 8.1743 11.4131 7.91974C11.3577 7.64313 11.1355 7.36794 10.7456 7.19457ZM9.99675 12.8093C9.59404 12.8093 9.26758 13.1358 9.26758 13.5385C9.26758 13.9412 9.59404 14.2677 9.99675 14.2677C10.3995 14.2677 10.7259 13.9412 10.7259 13.5385C10.7259 13.1358 10.3995 12.8093 9.99675 12.8093Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 24:
      switch (style) {
        case 'line':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="12" cy="12" r="9" stroke={color ?? neutralDay.gray90} strokeWidth="2" />
              <path
                d="M9.53125 9.8562C9.53125 9.3562 9.83125 8.25715 11.0312 7.85715C12.5312 7.35715 14.1799 8.10178 14.4313 9.35719C14.6312 10.3562 14.0312 10.8562 13.5312 11.357C13.0316 11.8573 12 12.3562 12 13.3562"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <rect
                x="11"
                y="15.25"
                width="2"
                height="2"
                rx="1"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM12.8948 8.63348C12.4314 8.42745 11.8374 8.37826 11.2684 8.56793C10.4815 8.83024 10.2812 9.54922 10.2812 9.85548C10.2812 10.2697 9.94546 10.6055 9.53125 10.6055C9.11704 10.6055 8.78125 10.2697 8.78125 9.85548C8.78125 9.16174 9.18099 7.6826 10.7941 7.14491C11.7251 6.83458 12.7054 6.9077 13.5042 7.26285C14.2984 7.61597 14.9818 8.28576 15.1667 9.20924C15.3055 9.90278 15.1644 10.4665 14.8869 10.9291C14.6726 11.2861 14.3698 11.5834 14.1556 11.7937C14.122 11.8267 14.0905 11.8576 14.062 11.8862C13.9091 12.0393 13.7254 12.1852 13.5742 12.3053L13.548 12.3262C13.3778 12.4614 13.2309 12.5797 13.1018 12.7056C12.8466 12.9543 12.75 13.147 12.75 13.3555C12.75 13.7697 12.4142 14.1055 12 14.1055C11.5858 14.1055 11.25 13.7697 11.25 13.3555C11.25 12.564 11.6692 12.0072 12.0548 11.6314C12.246 11.445 12.4507 11.2823 12.6145 11.152C12.7926 11.0104 12.9125 10.9144 13.0005 10.8263C13.0434 10.7833 13.084 10.743 13.1225 10.7049C13.3431 10.4859 13.4924 10.3378 13.6006 10.1573C13.6981 9.99494 13.757 9.80915 13.6958 9.50369C13.6294 9.17176 13.3627 8.84152 12.8948 8.63348ZM11.9961 15.3701C11.5128 15.3701 11.1211 15.7619 11.1211 16.2451C11.1211 16.7284 11.5128 17.1201 11.9961 17.1201C12.4793 17.1201 12.8711 16.7284 12.8711 16.2451C12.8711 15.7619 12.4793 15.3701 11.9961 15.3701Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
  }
}
