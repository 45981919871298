import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function HelpLine({ size, color }: LineIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="8" cy="8" r="6" stroke={color ?? neutralDay.gray100} strokeWidth="1.5" />
          <path
            d="M6.35315 6.56873C6.35315 6.23539 6.55315 5.50269 7.35315 5.23602C8.35315 4.90269 9.45226 5.39911 9.61982 6.23605C9.75315 6.90205 9.35315 7.23539 9.01982 7.56924C8.68674 7.90282 7.99898 8.23539 7.99898 8.90206"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <rect
            x="7.33435"
            y="10.1656"
            width="1.33333"
            height="1.33333"
            rx="0.666667"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="10" cy="10" r="7.5" stroke={color ?? neutralDay.gray100} strokeWidth="1.75" />
          <path
            d="M7.94275 8.21289C7.94275 7.79622 8.19275 6.88035 9.19275 6.54701C10.4427 6.13035 11.8166 6.75087 12.0261 7.79705C12.1927 8.62955 11.6927 9.04622 11.2761 9.46353C10.8597 9.88051 10 10.2962 10 11.1296"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <rect
            x="9.16663"
            y="12.7083"
            width="1.66667"
            height="1.66667"
            rx="0.833333"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="12" r="9" stroke={color ?? neutralDay.gray100} strokeWidth="2" />
          <path
            d="M9.53125 9.85626C9.53125 9.35626 9.83125 8.25721 11.0312 7.85721C12.5312 7.35721 14.1799 8.10184 14.4313 9.35725C14.6312 10.3563 14.0312 10.8563 13.5312 11.357C13.0316 11.8574 12 12.3563 12 13.3563"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <rect x="11" y="15.25" width="2" height="2" rx="1" fill={color ?? neutralDay.gray100} />
        </svg>
      );
  }
}
