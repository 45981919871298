import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function SearchLine({ size, color }: LineIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="6.66768"
            cy="6.66768"
            r="4.58333"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
          />
          <path
            d="M10.5303 9.46967C10.2374 9.17678 9.76256 9.17678 9.46967 9.46967C9.17678 9.76256 9.17678 10.2374 9.46967 10.5303L10.5303 9.46967ZM13.4697 14.5303C13.7626 14.8232 14.2374 14.8232 14.5303 14.5303C14.8232 14.2374 14.8232 13.7626 14.5303 13.4697L13.4697 14.5303ZM9.46967 10.5303L13.4697 14.5303L14.5303 13.4697L10.5303 9.46967L9.46967 10.5303Z"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="8.33329"
            cy="8.33335"
            r="5.79167"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
          />
          <path
            d="M13.1187 11.8813C12.777 11.5396 12.223 11.5396 11.8813 11.8813C11.5396 12.223 11.5396 12.777 11.8813 13.1187L13.1187 11.8813ZM16.8813 18.1187C17.223 18.4604 17.777 18.4604 18.1187 18.1187C18.4604 17.777 18.4604 17.223 18.1187 16.8813L16.8813 18.1187ZM11.8813 13.1187L16.8813 18.1187L18.1187 16.8813L13.1187 11.8813L11.8813 13.1187Z"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="10" cy="10" r="7" stroke={color ?? neutralDay.gray100} strokeWidth="2" />
          <path
            d="M15.7071 14.2929C15.3166 13.9024 14.6834 13.9024 14.2929 14.2929C13.9024 14.6834 13.9024 15.3166 14.2929 15.7071L15.7071 14.2929ZM20.2929 21.7071C20.6834 22.0976 21.3166 22.0976 21.7071 21.7071C22.0976 21.3166 22.0976 20.6834 21.7071 20.2929L20.2929 21.7071ZM14.2929 15.7071L20.2929 21.7071L21.7071 20.2929L15.7071 14.2929L14.2929 15.7071Z"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );
  }
}
