import { type LineOnlyIcon } from '../type';

export function InfoLoading({ size }: LineOnlyIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.3334 8.00008C13.3334 5.05456 10.9456 2.66675 8.00008 2.66675C5.05456 2.66675 2.66675 5.05456 2.66675 8.00008C2.66675 10.9456 5.05456 13.3334 8.00008 13.3334"
            stroke="url(#paint0_linear_4921_9030)"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M8.00008 13.3334C5.05456 13.3334 2.66675 10.9456 2.66675 8.00008C2.66675 5.05456 5.05456 2.66675 8.00008 2.66675C10.9456 2.66675 13.3334 5.05456 13.3334 8.00008C13.3334 8.97151 13.0737 9.88229 12.6199 10.6667"
            stroke="url(#paint1_linear_4921_9030)"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_4921_9030"
              x1="5.33341"
              y1="13.3334"
              x2="13.6667"
              y2="10.3334"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#1C1D1E" />
              <stop offset="0.382331" stopColor="#1C1D1E" />
              <stop offset="0.818132" stopColor="#1C1D1E" stopOpacity="0.36" />
              <stop offset="1" stopColor="#1C1D1E" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_4921_9030"
              x1="12.0001"
              y1="-2.99992"
              x2="7.33341"
              y2="11.3334"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#1C1D1E" />
              <stop offset="0.703125" stopColor="#1C1D1E" stopOpacity="0.208333" />
              <stop offset="0.848958" stopColor="#1C1D1E" stopOpacity="0" />
              <stop offset="1" stopColor="#1C1D1E" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.6666 9.99992C16.6666 6.31802 13.6819 3.33325 9.99998 3.33325C6.31808 3.33325 3.33331 6.31802 3.33331 9.99992C3.33331 13.6818 6.31808 16.6666 9.99998 16.6666"
            stroke="url(#paint0_linear_4921_8934)"
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <path
            d="M9.99998 16.6666C6.31808 16.6666 3.33331 13.6818 3.33331 9.99992C3.33331 6.31802 6.31808 3.33325 9.99998 3.33325C13.6819 3.33325 16.6666 6.31802 16.6666 9.99992C16.6666 11.2142 16.342 12.3527 15.7748 13.3333"
            stroke="url(#paint1_linear_4921_8934)"
            strokeWidth="1.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_4921_8934"
              x1="6.66665"
              y1="16.6666"
              x2="17.0833"
              y2="12.9166"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#1C1D1E" />
              <stop offset="0.382331" stopColor="#1C1D1E" />
              <stop offset="0.818132" stopColor="#1C1D1E" stopOpacity="0.36" />
              <stop offset="1" stopColor="#1C1D1E" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_4921_8934"
              x1="15"
              y1="-3.75008"
              x2="9.16665"
              y2="14.1666"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#1C1D1E" />
              <stop offset="0.703125" stopColor="#1C1D1E" stopOpacity="0.208333" />
              <stop offset="0.848958" stopColor="#1C1D1E" stopOpacity="0" />
              <stop offset="1" stopColor="#1C1D1E" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20"
            stroke="url(#paint0_linear_674_4867)"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 13.4571 19.6104 14.8233 18.9297 16"
            stroke="url(#paint1_linear_674_4867)"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_674_4867"
              x1="8"
              y1="20"
              x2="20.5"
              y2="15.5"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#1C1D1E" />
              <stop offset="0.382331" stopColor="#1C1D1E" />
              <stop offset="0.818132" stopColor="#1C1D1E" stopOpacity="0.36" />
              <stop offset="1" stopColor="#1C1D1E" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_674_4867"
              x1="18"
              y1="-4.5"
              x2="11"
              y2="17"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#1C1D1E" />
              <stop offset="0.703125" stopColor="#1C1D1E" stopOpacity="0.208333" />
              <stop offset="0.848958" stopColor="#1C1D1E" stopOpacity="0" />
              <stop offset="1" stopColor="#1C1D1E" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      );
  }
}
