import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function ImageLine({ size, color }: LineIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="2.66565"
            y="2.66565"
            width="10.6667"
            height="10.6667"
            rx="1.33333"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <path
            d="M13.3344 9.99898L9.33435 6.66565L3.33435 12.6656"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <rect
            x="4.66565"
            y="4.66565"
            width="2"
            height="2"
            rx="1"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="3.33337"
            y="3.33331"
            width="13.3333"
            height="13.3333"
            rx="1.66667"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinejoin="round"
          />
          <path
            d="M16.6666 12.5L11.6666 8.33331L4.16663 15.8333"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <rect
            x="5.83337"
            y="5.83331"
            width="2.5"
            height="2.5"
            rx="1.25"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="4"
            y="4"
            width="16"
            height="16"
            rx="2"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinejoin="round"
          />
          <path
            d="M20 15L14 10L5 19"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <rect x="7" y="7" width="3" height="3" rx="1.5" fill={color ?? neutralDay.gray100} />
        </svg>
      );
  }
}
