import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineFillIcon } from '../type';

export function NavigationHome({ size, style, color }: LineFillIcon) {
  switch (size) {
    case 16:
      switch (style) {
        case 'line':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.20496 2.92402L2.87161 6.14307C2.53299 6.39461 2.33337 6.79156 2.33337 7.21339V12.3334C2.33337 13.0698 2.93033 13.6667 3.66671 13.6667H4.13232H6.07412C6.4423 13.6667 6.74078 13.3682 6.74078 13V10.1238C6.74078 9.75566 7.03926 9.45718 7.40745 9.45718H8.59263C8.96082 9.45718 9.2593 9.75566 9.2593 10.1238V13C9.2593 13.3682 9.55778 13.6667 9.92597 13.6667H11.2381H12.3334C13.0698 13.6667 13.6667 13.0698 13.6667 12.3334V7.21339C13.6667 6.79156 13.4671 6.39461 13.1285 6.14307L8.79516 2.92402C8.3231 2.57334 7.67703 2.57334 7.20496 2.92402Z"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.2198 1.45582C8.49164 0.929929 7.50841 0.929928 6.78025 1.45582L2.11356 4.82619C1.57124 5.21786 1.25 5.84614 1.25 6.51511V11.8334C1.25 12.984 2.18274 13.9167 3.33333 13.9167H5.33333C6.11574 13.9167 6.75 13.2824 6.75 12.5V9.50002C6.75 9.17786 7.01117 8.91669 7.33333 8.91669H8.66667C8.98883 8.91669 9.25 9.17786 9.25 9.50002V12.5C9.25 13.2824 9.88426 13.9167 10.6667 13.9167H12.6667C13.8173 13.9167 14.75 12.9839 14.75 11.8334V6.5151C14.75 5.84614 14.4288 5.21787 13.8864 4.82619L9.2198 1.45582Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 20:
      switch (style) {
        case 'line':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.00611 3.65493L3.58942 7.67874C3.16614 7.99318 2.91663 8.48936 2.91663 9.01665V15.4166C2.91663 16.3371 3.66282 17.0833 4.58329 17.0833H5.1653H7.59255C8.05279 17.0833 8.42589 16.7102 8.42589 16.25V12.6547C8.42589 12.1945 8.79898 11.8214 9.25922 11.8214H10.7407C11.2009 11.8214 11.574 12.1945 11.574 12.6547V16.25C11.574 16.7102 11.9471 17.0833 12.4074 17.0833H14.0476H15.4166C16.3371 17.0833 17.0833 16.3371 17.0833 15.4166V9.01665C17.0833 8.48936 16.8338 7.99318 16.4105 7.67874L10.9939 3.65493C10.4038 3.21659 9.5962 3.21658 9.00611 3.65493Z"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.4638 2.02921C10.59 1.39814 9.41011 1.39814 8.53632 2.02921L2.70296 6.24217C2.05218 6.71218 1.66669 7.46611 1.66669 8.26887V14.9167C1.66669 16.2974 2.78598 17.4167 4.16669 17.4167H6.66669C7.58716 17.4167 8.33335 16.6705 8.33335 15.75V12C8.33335 11.5398 8.70645 11.1667 9.16669 11.1667H10.8334C11.2936 11.1667 11.6667 11.5398 11.6667 12V15.75C11.6667 16.6705 12.4129 17.4167 13.3334 17.4167H15.8334C17.2141 17.4167 18.3334 16.2974 18.3334 14.9167V8.26887C18.3334 7.46611 17.9479 6.71218 17.2971 6.24217L11.4638 2.02921Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 24:
      switch (style) {
        case 'line':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.8074 4.38596L4.30736 9.21454C3.79942 9.59186 3.5 10.1873 3.5 10.82V18.5C3.5 19.6046 4.39543 20.5 5.5 20.5H6.19841H9.11111C9.6634 20.5 10.1111 20.0523 10.1111 19.5V15.1857C10.1111 14.6334 10.5588 14.1857 11.1111 14.1857H12.8889C13.4412 14.1857 13.8889 14.6334 13.8889 15.1857V19.5C13.8889 20.0523 14.3366 20.5 14.8889 20.5H16.8571H18.5C19.6046 20.5 20.5 19.6046 20.5 18.5V10.82C20.5 10.1873 20.2006 9.59186 19.6926 9.21454L13.1927 4.38597C12.4846 3.85995 11.5155 3.85995 10.8074 4.38596Z"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="2"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.7565 2.53501C12.708 1.77772 11.2921 1.77772 10.2436 2.535L3.24353 7.59056C2.46259 8.15457 2 9.05929 2 10.0226V18C2 19.6568 3.34315 21 5 21H8C9.10457 21 10 20.1045 10 19V14.5C10 13.9477 10.4477 13.5 11 13.5H13C13.5523 13.5 14 13.9477 14 14.5V19C14 20.1045 14.8954 21 16 21H19C20.6569 21 22 19.6568 22 18V10.0226C22 9.05928 21.5374 8.15457 20.7565 7.59056L13.7565 2.53501Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
  }
}
