import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineFillIcon } from '../type';

export function ActionEye({ size, style, color }: LineFillIcon) {
  switch (size) {
    case 16:
      switch (style) {
        case 'line':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.6639 7.99711C13.3306 10.2828 11.3306 12.6638 7.99723 12.6638C4.6639 12.6638 2.6639 10.2828 1.33057 7.99711C2.6639 5.7114 4.6639 3.33044 7.99723 3.33044C11.3306 3.33044 13.3306 5.7114 14.6639 7.99711Z"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
              />
              <circle cx="7.99837" cy="7.99813" r="2.33333" fill={color ?? neutralDay.gray90} />
              <circle cx="7.99723" cy="7.99711" r="1.66667" fill={color ?? neutralDay.gray90} />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.66366 7.99711C9.66366 8.91759 8.91746 9.66378 7.99699 9.66378C7.07651 9.66378 6.33032 8.91759 6.33032 7.99711C6.33032 7.07664 7.07651 6.33044 7.99699 6.33044C8.91746 6.33044 9.66366 7.07664 9.66366 7.99711Z"
                fill={color ?? neutralDay.gray90}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.682602 7.61921C2.04384 5.28566 4.23782 2.58044 7.9971 2.58044C11.7564 2.58044 13.9504 5.28566 15.3116 7.61921L15.532 7.99711L15.3116 8.37501C13.9504 10.7086 11.7564 13.4138 7.9971 13.4138C4.23782 13.4138 2.04384 10.7086 0.682602 8.37501L0.462158 7.99711L0.682602 7.61921ZM10.6637 7.99711C10.6637 9.46987 9.46975 10.6638 7.99699 10.6638C6.52423 10.6638 5.33032 9.46987 5.33032 7.99711C5.33032 6.52435 6.52423 5.33044 7.99699 5.33044C9.46975 5.33044 10.6637 6.52435 10.6637 7.99711Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 20:
      switch (style) {
        case 'line':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.3319 9.99874C16.6653 12.8559 14.1653 15.8321 9.99862 15.8321C5.83195 15.8321 3.33195 12.8559 1.66528 9.99874C3.33195 7.1416 5.83195 4.16541 9.99862 4.16541C14.1653 4.16541 16.6653 7.1416 18.3319 9.99874Z"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
              />
              <circle cx="9.9987" cy="9.9987" r="2.91667" fill={color ?? neutralDay.gray90} />
              <circle cx="9.99862" cy="9.99874" r="2.08333" fill={color ?? neutralDay.gray90} />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.0819 9.99862C12.0819 11.1492 11.1492 12.0819 9.99862 12.0819C8.84802 12.0819 7.91528 11.1492 7.91528 9.99862C7.91528 8.84802 8.84802 7.91528 9.99862 7.91528C11.1492 7.91528 12.0819 8.84802 12.0819 9.99862Z"
                fill={color ?? neutralDay.gray90}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.909528 9.55773C2.60875 6.64478 5.33506 3.29028 9.99867 3.29028C14.6623 3.29028 17.3886 6.64478 19.0878 9.55773L19.345 9.99862L19.0878 10.4395C17.3886 13.3525 14.6623 16.707 9.99867 16.707C5.33506 16.707 2.60875 13.3525 0.909528 10.4395L0.652344 9.99862L0.909528 9.55773ZM13.3319 9.99862C13.3319 11.8396 11.8396 13.3319 9.99862 13.3319C8.15767 13.3319 6.66528 11.8396 6.66528 9.99862C6.66528 8.15767 8.15767 6.66528 9.99862 6.66528C11.8396 6.66528 13.3319 8.15767 13.3319 9.99862Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 24:
      switch (style) {
        case 'line':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22 12C20 15.4286 17 19 12 19C7 19 4 15.4286 2 12C4 8.57143 7 5 12 5C17 5 20 8.57143 22 12Z"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="2"
              />
              <circle cx="12" cy="12" r="3.5" fill={color ?? neutralDay.gray90} />
              <circle cx="12" cy="12" r="2.5" fill={color ?? neutralDay.gray90} />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.5 12C14.5 13.3807 13.3807 14.5 12 14.5C10.6193 14.5 9.5 13.3807 9.5 12C9.5 10.6193 10.6193 9.5 12 9.5C13.3807 9.5 14.5 10.6193 14.5 12Z"
                fill={color ?? neutralDay.gray90}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.13621 11.4961C3.17342 8.00378 6.43205 4 12 4C17.5679 4 20.8266 8.00378 22.8638 11.4961L23.1577 12L22.8638 12.5039C20.8266 15.9962 17.5679 20 12 20C6.43205 20 3.17342 15.9962 1.13621 12.5039L0.842285 12L1.13621 11.4961ZM16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
  }
}
