import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type FillIcon } from '../type';

export function WarningFill({
  size,
  primaryColor = neutralDay.white,
  secondaryColor = neutralDay.gray100,
}: FillIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_2208_937)">
            <path
              d="M6.68868 4.02697C7.26019 2.99826 8.73965 2.99826 9.31115 4.02697L13.4285 11.4383C13.984 12.4381 13.261 13.6667 12.1173 13.6667H3.88252C2.7388 13.6667 2.01584 12.4381 2.57129 11.4383L6.68868 4.02697Z"
              fill={secondaryColor}
              stroke={secondaryColor}
              strokeWidth="1.5"
            />
            <g filter="url(#filter0_d_2208_937)">
              <rect
                x="7.4165"
                y="11.6145"
                width="1.16667"
                height="1.16667"
                rx="0.583333"
                fill={primaryColor}
              />
            </g>
            <path d="M8 6.5L8 10.2733" stroke={primaryColor} strokeLinecap="round" />
          </g>
          <defs>
            <filter
              id="filter0_d_2208_937"
              x="3.4165"
              y="11.6145"
              width="9.16675"
              height="9.16675"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_2208_937"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_2208_937"
                result="shape"
              />
            </filter>
            <clipPath id="clip0_2208_937">
              <rect width="16" height="16" fill={primaryColor} />
            </clipPath>
          </defs>
        </svg>
      );
    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_674_4488)">
            <path
              d="M8.68884 4.44348C9.26035 3.41476 10.7398 3.41476 11.3113 4.44348L17.0954 14.8548C17.6508 15.8546 16.9279 17.0833 15.7841 17.0833H4.21602C3.07229 17.0833 2.34934 15.8546 2.90478 14.8548L8.68884 4.44348Z"
              fill={secondaryColor}
              stroke={secondaryColor}
              strokeWidth="1.75"
            />
            <g filter="url(#filter0_d_674_4488)">
              <rect
                x="9.271"
                y="14.2683"
                width="1.45833"
                height="1.45833"
                rx="0.729167"
                fill={primaryColor}
              />
            </g>
            <path
              d="M10 7.875L10 12.5917"
              stroke={primaryColor}
              strokeWidth="1.25"
              strokeLinecap="round"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_674_4488"
              x="5.271"
              y="14.2683"
              width="9.45825"
              height="9.45825"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_674_4488"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_674_4488"
                result="shape"
              />
            </filter>
            <clipPath id="clip0_674_4488">
              <rect width="20" height="20" fill={primaryColor} />
            </clipPath>
          </defs>
        </svg>
      );
    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_674_4477)">
            <path
              d="M10.6888 4.86023C11.2603 3.83151 12.7397 3.83151 13.3112 4.86022L20.762 18.2715C21.3174 19.2713 20.5945 20.5 19.4507 20.5H4.54927C3.40554 20.5 2.68259 19.2713 3.23804 18.2715L10.6888 4.86023Z"
              fill={secondaryColor}
              stroke={secondaryColor}
              strokeWidth="2"
            />
            <g filter="url(#filter0_d_674_4477)">
              <rect
                x="11.125"
                y="16.9219"
                width="1.75"
                height="1.75"
                rx="0.875"
                fill={primaryColor}
              />
            </g>
            <path
              d="M12 9.25L12 14.91"
              stroke={primaryColor}
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_674_4477"
              x="7.125"
              y="16.9219"
              width="9.75"
              height="9.75"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_674_4477"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_674_4477"
                result="shape"
              />
            </filter>
            <clipPath id="clip0_674_4477">
              <rect width="24" height="24" fill={primaryColor} />
            </clipPath>
          </defs>
        </svg>
      );
  }
}
