import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineOnlyIcon } from '../type';

export function ContentsFile({ size, color }: LineOnlyIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 7.58219V9.99886C4 12.208 5.79086 13.9989 8 13.9989V13.9989C10.2091 13.9989 12 12.208 12 9.99886V4.66553"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.33333 6V4.66667C9.33333 3.19391 8.13943 2 6.66667 2V2C5.19391 2 4 3.19391 4 4.66667V10"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M6.66562 4.66553V9.99886C6.66562 10.7352 7.26257 11.3322 7.99895 11.3322V11.3322C8.73533 11.3322 9.33229 10.7352 9.33229 9.99886V7.99886V5.99886"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 9.47909V12.4999C5 15.2613 7.23858 17.4999 10 17.4999V17.4999C12.7614 17.4999 15 15.2613 15 12.4999V5.83325"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.6667 7.5V5.83333C11.6667 3.99238 10.1743 2.5 8.33333 2.5V2.5C6.49238 2.5 5 3.99238 5 5.83333V12.5"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <path
            d="M8.33334 5.83325V12.4999C8.33334 13.4204 9.07954 14.1666 10 14.1666V14.1666C10.9205 14.1666 11.6667 13.4204 11.6667 12.4999V9.99992V7.49992"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 11.375V15C6 18.3137 8.68629 21 12 21V21C15.3137 21 18 18.3137 18 15V7"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14 9V7C14 4.79086 12.2091 3 10 3V3C7.79086 3 6 4.79086 6 7V15"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M10 7V15C10 16.1046 10.8954 17 12 17V17C13.1046 17 14 16.1046 14 15V12V9"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      );
  }
}
