import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type FillIcon } from '../type';
import { ComponentProps } from 'react';

export function CheckboxFill({
  size,
  primaryColor,
  secondaryColor,
  ...props
}: FillIcon & ComponentProps<'svg'>) {
  switch (size) {
    case 16:
      return <div>이미지가 없습니다.</div>;
    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <rect
            x="3.33331"
            y="3.33331"
            width="13.3333"
            height="13.3333"
            rx="2"
            fill={primaryColor ?? neutralDay.gray100}
            stroke={primaryColor ?? neutralDay.gray100}
            strokeWidth="1.75"
          />
          <path
            d="M6.8327 10.2727L8.91604 12.1667L13.4994 8"
            stroke={secondaryColor ?? neutralDay.white}
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <rect
            x="4"
            y="4"
            width="16"
            height="16"
            rx="2"
            fill={primaryColor ?? neutralDay.gray100}
            stroke={primaryColor ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinejoin="round"
          />
          <path
            d="M8.19922 12.3273L10.6992 14.6L16.1992 9.60001"
            stroke={secondaryColor ?? neutralDay.white}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
}
