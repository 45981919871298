import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineFillIcon } from '../type';

export function InfoVerified({ size, style, color }: LineFillIcon) {
  switch (size) {
    case 16:
      switch (style) {
        case 'line':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.8615 7.6122L12.8152 6.36655L12.9615 4.71547C12.9896 4.42168 12.7984 4.1514 12.5284 4.08676L10.9759 3.71659L10.1659 2.28879C10.0197 2.03613 9.72156 1.93037 9.46282 2.04788L8.00035 2.71184L6.5435 2.05963C6.28476 1.94212 5.98101 2.04788 5.84039 2.30054L5.02478 3.72247L3.47794 4.08676C3.20232 4.1514 3.01107 4.4158 3.0392 4.71547L3.18544 6.36067L2.13922 7.6122C1.95359 7.83548 1.95359 8.16452 2.13922 8.3878L3.18544 9.63345L3.0392 11.2904C3.01107 11.5842 3.20232 11.8545 3.47231 11.9191L5.02478 12.2893L5.83477 13.7112C5.98101 13.9639 6.27913 14.0696 6.5435 13.9521L8.00035 13.2882L9.45719 13.9404C9.71594 14.0579 10.0197 13.9521 10.1603 13.6995L10.9703 12.2717L12.5228 11.9015C12.7984 11.8369 12.984 11.5666 12.9559 11.2728L12.8096 9.6217L13.8559 8.37605C14.0471 8.16452 14.0471 7.83548 13.8615 7.6122Z"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.8667 8.19402L7.22087 9.46675L10.2 6.66675"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.815 1.91315C10.4916 1.35446 9.79108 1.07522 9.15278 1.36506L7.99819 1.88923L6.85143 1.37585C6.22317 1.09189 5.51058 1.35702 5.18791 1.93079L4.53612 3.06712L3.30601 3.35682C2.655 3.50985 2.23144 4.12388 2.29228 4.78344L2.4109 6.11792L1.5638 7.13126L1.56249 7.13283C1.14584 7.634 1.14583 8.36618 1.56248 8.86735L2.41099 9.8776L2.2923 11.2224C2.23196 11.8697 2.64902 12.4933 3.29836 12.6488L4.53421 12.9434L5.18307 14.0825L5.18567 14.087C5.51194 14.6507 6.21194 14.9204 6.84816 14.6376L8.00311 14.1112L9.14706 14.6233L9.14931 14.6243C9.77828 14.9086 10.4918 14.6425 10.8139 14.0674L11.4616 12.9256L12.694 12.6318L12.6953 12.6315C13.3595 12.475 13.7624 11.8441 13.7028 11.2046L13.5842 9.86575L14.4231 8.86685C14.8671 8.3635 14.8457 7.62302 14.4382 7.13282L13.5898 6.12268L13.7084 4.78383C13.7688 4.13646 13.3517 3.51276 12.7023 3.3573L11.4673 3.06281L10.8183 1.91879L10.815 1.91315ZM10.5425 7.03109C10.7437 6.84197 10.7535 6.52554 10.5644 6.32432C10.3753 6.1231 10.0588 6.11329 9.85761 6.30241L7.22087 8.78058L6.20913 7.82968C6.00791 7.64056 5.69148 7.65038 5.50236 7.85159C5.31324 8.05281 5.32305 8.36924 5.52427 8.55836L6.87844 9.83109C7.07089 10.012 7.37084 10.012 7.5633 9.83109L10.5425 7.03109Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 20:
      switch (style) {
        case 'line':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.3268 9.51525L16.0191 7.95818L16.2019 5.89433C16.237 5.5271 15.998 5.18924 15.6605 5.10845L13.7199 4.64574L12.7074 2.86098C12.5246 2.54516 12.152 2.41296 11.8285 2.55985L10.0004 3.3898L8.17938 2.57454C7.85595 2.42765 7.47627 2.55985 7.30049 2.87567L6.28098 4.65308L4.34742 5.10845C4.0029 5.18924 3.76384 5.51975 3.799 5.89433L3.98181 7.95084L2.67402 9.51525C2.44199 9.79435 2.44199 10.2057 2.67402 10.4847L3.98181 12.0418L3.799 14.113C3.76384 14.4802 4.0029 14.8181 4.34039 14.8989L6.28098 15.3616L7.29346 17.139C7.47627 17.4548 7.84892 17.587 8.17938 17.4401L10.0004 16.6102L11.8215 17.4255C12.1449 17.5724 12.5246 17.4401 12.7004 17.1243L13.7129 15.3396L15.6534 14.8769C15.998 14.7961 16.23 14.4582 16.1948 14.091L16.012 12.0271L17.3198 10.4701C17.5589 10.2057 17.5589 9.79435 17.3268 9.51525Z"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.33337 10.2423L9.02608 11.8333L12.75 8.33325"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.4647 2.42266C13.0752 1.7498 12.2331 1.4152 11.4668 1.76314L9.99792 2.43001L8.53862 1.77671C7.78407 1.43563 6.92739 1.75378 6.53924 2.44426L5.71087 3.88843L4.14685 4.25677C3.36435 4.4407 2.85426 5.17911 2.92759 5.97358L3.07818 7.66755L2.0027 8.95407L2.00117 8.9559C1.49961 9.55921 1.49961 10.4408 2.00117 11.0441L3.07828 12.3266L2.92762 14.0335C2.85485 14.8132 3.35736 15.5633 4.13745 15.7501L5.70865 16.1247L6.53315 17.5721L6.53618 17.5774C6.92901 18.256 7.77053 18.5795 8.53481 18.2398L10.0037 17.5703L11.4597 18.2222L11.4621 18.2233C12.2174 18.5648 13.0751 18.2457 13.4628 17.5537L14.2861 16.1024L15.8532 15.7288L15.855 15.7283C16.6528 15.5403 17.1382 14.7817 17.0662 14.0113L16.9157 12.3118L17.9816 11.0427C18.5156 10.4378 18.4906 9.54642 17.9997 8.95589L16.9227 7.67359L17.0732 5.9741C17.1461 5.19433 16.6436 4.4441 15.8634 4.25733L14.2931 3.88291L13.4685 2.42924L13.4647 2.42266ZM13.178 8.78868C13.4295 8.55228 13.4417 8.15674 13.2053 7.90522C12.9689 7.6537 12.5734 7.64143 12.3219 7.87783L9.02596 10.9755L7.76129 9.78692C7.50977 9.55052 7.11423 9.56279 6.87783 9.81431C6.64143 10.0658 6.6537 10.4614 6.90522 10.6978L8.59793 12.2887C8.8385 12.5148 9.21343 12.5148 9.454 12.2887L13.178 8.78868Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 24:
      switch (style) {
        case 'line':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.7922 11.4183L19.2229 9.54982L19.4422 7.0732C19.4844 6.63252 19.1976 6.22709 18.7926 6.13014L16.4639 5.57489L15.2489 3.43318C15.0295 3.0542 14.5823 2.89555 14.1942 3.07182L12.0005 4.06776L9.81525 3.08945C9.42714 2.91318 8.97152 3.07182 8.76059 3.45081L7.53717 5.5837L5.21691 6.13014C4.80348 6.22709 4.51661 6.6237 4.5588 7.0732L4.77817 9.54101L3.20882 11.4183C2.93039 11.7532 2.93039 12.2468 3.20882 12.5817L4.77817 14.4502L4.5588 16.9356C4.51661 17.3763 4.80348 17.7817 5.20847 17.8787L7.53717 18.4339L8.75215 20.5668C8.97152 20.9458 9.4187 21.1044 9.81525 20.9282L12.0005 19.9322L14.1858 20.9106C14.5739 21.0868 15.0295 20.9282 15.2405 20.5492L16.4554 18.4075L18.7841 17.8522C19.1976 17.7553 19.476 17.3499 19.4338 16.9092L19.2144 14.4326L20.7838 12.5641C21.0707 12.2468 21.0707 11.7532 20.7922 11.4183Z"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.80005 12.2909L10.8313 14.2L15.3 10"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.1144 2.93217C15.6588 2.14514 14.675 1.75519 13.7808 2.16122L11.9976 2.97079L10.2288 2.17891L10.2259 2.17759C9.34501 1.77935 8.34422 2.15052 7.89058 2.95771L6.88562 4.70974L4.98768 5.15673C4.07371 5.37154 3.47709 6.23433 3.56291 7.16369L3.74545 9.21718L2.4416 10.7769L2.43986 10.779C1.85339 11.4844 1.85338 12.5155 2.43985 13.2209L3.74556 14.7755L3.56294 16.8447C3.47777 17.7567 4.06572 18.6333 4.97654 18.8514L6.88308 19.306L7.88322 21.0617L7.88669 21.0677C8.34609 21.8614 9.32911 22.2386 10.2215 21.842L12.0042 21.0295L13.7723 21.821L13.7753 21.8224C14.657 22.2209 15.6589 21.8486 16.1119 21.0397L17.1106 19.2793L19.0124 18.8258L19.0148 18.8252C19.9461 18.6056 20.514 17.7194 20.4297 16.8179L20.4293 16.8138L20.2472 14.7578L21.54 13.2185C22.164 12.5121 22.1355 11.4698 21.5612 10.779L20.2556 9.2245L20.4377 7.16845L20.4381 7.16436C20.5234 6.25218 19.9354 5.37543 19.0245 5.15737L17.119 4.70302L16.1187 2.93969L16.1144 2.93217ZM15.8137 10.5465C16.1155 10.2628 16.1302 9.78819 15.8466 9.48636C15.5629 9.18453 15.0882 9.16982 14.7864 9.45349L10.8313 13.1707L9.31369 11.7444C9.01186 11.4607 8.53722 11.4754 8.25354 11.7773C7.96987 12.0791 7.98458 12.5537 8.28641 12.8374L10.3177 14.7465C10.6063 15.0178 11.0563 15.0178 11.3449 14.7465L15.8137 10.5465Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
  }
}
