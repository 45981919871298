import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function CopyLine({ size, color }: LineIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.3313 3.13V3.13C5.3313 2.50592 5.83722 2 6.4613 2H11.998C12.7343 2 13.3313 2.59695 13.3313 3.33333V10.2052C13.3313 10.8283 12.8262 11.3333 12.2032 11.3333V11.3333"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <rect
            x="2.6687"
            y="4.66565"
            width="8"
            height="9.33333"
            rx="1.33333"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.66663 4.16667V4.16667C6.66663 3.24619 7.41282 2.5 8.33329 2.5H15C15.9204 2.5 16.6666 3.24619 16.6666 4.16667V12.5C16.6666 13.4205 15.9204 14.1667 15 14.1667V14.1667"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <rect
            x="3.33337"
            y="5.83331"
            width="10"
            height="11.6667"
            rx="1.66667"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 6V5C8 3.89543 8.89543 3 10 3H18C19.1046 3 20 3.89543 20 5V15C20 16.1046 19.1046 17 18 17H17"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
          />
          <rect
            x="4"
            y="7"
            width="12"
            height="14"
            rx="2"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
          />
        </svg>
      );
  }
}
