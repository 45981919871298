import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineFillIcon } from '../type';

export function ContentsLockClose({ size, style, color }: LineFillIcon) {
  switch (size) {
    case 16:
      switch (style) {
        case 'line':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="3.33333"
                y="6"
                width="9.33333"
                height="8"
                rx="1.33333"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinejoin="round"
              />
              <rect
                x="6.66667"
                y="8.66675"
                width="2.66667"
                height="2.66667"
                rx="1.33333"
                fill={color ?? neutralDay.gray90}
              />
              <path
                d="M5.33333 6V4.66667C5.33333 3.19333 6.52666 2 7.99999 2C9.47333 2 10.6667 3.19333 10.6667 4.66667V6"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.58331 5.25164V4.66667C4.58331 2.77912 6.11243 1.25 7.99998 1.25C9.88753 1.25 11.4166 2.77912 11.4166 4.66667V5.25164C12.5286 5.29538 13.4166 6.21065 13.4166 7.33333V12.6667C13.4166 13.8173 12.4839 14.75 11.3333 14.75H4.66665C3.51605 14.75 2.58331 13.8173 2.58331 12.6667V7.33333C2.58331 6.21065 3.47135 5.29538 4.58331 5.25164ZM6.08331 4.66667C6.08331 3.60755 6.94086 2.75 7.99998 2.75C9.0591 2.75 9.91665 3.60755 9.91665 4.66667V5.25H6.08331V4.66667ZM6.66669 10.0001C6.66669 9.2637 7.26364 8.66675 8.00002 8.66675C8.7364 8.66675 9.33335 9.2637 9.33335 10.0001C9.33335 10.7365 8.7364 11.3334 8.00002 11.3334C7.26364 11.3334 6.66669 10.7365 6.66669 10.0001Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 20:
      switch (style) {
        case 'line':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="4.16667"
                y="7.5"
                width="11.6667"
                height="10"
                rx="2"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
              />
              <rect
                x="8.33333"
                y="10.8333"
                width="3.33333"
                height="3.33333"
                rx="1.66667"
                fill={color ?? neutralDay.gray90}
              />
              <path
                d="M6.66667 7.5V5.83333C6.66667 3.99167 8.15834 2.5 10 2.5C11.8417 2.5 13.3333 3.99167 13.3333 5.83333V7.5"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.91669 6.63572V5.83333C5.91669 3.57745 7.74414 1.75 10 1.75C12.2559 1.75 14.0834 3.57745 14.0834 5.83333V6.63572C15.5541 6.76241 16.7084 7.99641 16.7084 9.5V15.5C16.7084 17.0878 15.4212 18.375 13.8334 18.375H6.16669C4.57887 18.375 3.29169 17.0878 3.29169 15.5V9.5C3.29169 7.99641 4.44593 6.76241 5.91669 6.63572ZM7.41669 5.83333C7.41669 4.40588 8.57257 3.25 10 3.25C11.4275 3.25 12.5834 4.40588 12.5834 5.83333V6.625H7.41669V5.83333ZM8.33331 12.4999C8.33331 11.5794 9.0795 10.8333 9.99998 10.8333C10.9205 10.8333 11.6666 11.5794 11.6666 12.4999C11.6666 13.4204 10.9205 14.1666 9.99998 14.1666C9.0795 14.1666 8.33331 13.4204 8.33331 12.4999Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 24:
      switch (style) {
        case 'line':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="5"
                y="9"
                width="14"
                height="12"
                rx="2"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="2"
                strokeLinejoin="round"
              />
              <rect x="10" y="13" width="4" height="4" rx="2" fill={color ?? neutralDay.gray90} />
              <path
                d="M8 9V7C8 4.79 9.79 3 12 3C14.21 3 16 4.79 16 7V9"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="2"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 8V7C7 4.23772 9.23772 2 12 2C14.7623 2 17 4.23772 17 7V8C18.6569 8 20 9.34315 20 11V19C20 20.6569 18.6569 22 17 22H7C5.34315 22 4 20.6569 4 19V11C4 9.34315 5.34315 8 7 8ZM9 7C9 5.34228 10.3423 4 12 4C13.6577 4 15 5.34228 15 7V8H9V7ZM10 15C10 13.8954 10.8954 13 12 13C13.1046 13 14 13.8954 14 15C14 16.1046 13.1046 17 12 17C10.8954 17 10 16.1046 10 15Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
  }
}
