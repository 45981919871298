import { type LineFillIcon } from '../type';
import { neutralDay } from '@/_global/_styles/design-system/foundation/color';

export function InfoError({ size, style, color }: LineFillIcon) {
  switch (size) {
    case 16:
      switch (style) {
        case 'line':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="8" cy="8" r="6" stroke={color ?? neutralDay.gray90} strokeWidth="1.4" />
              <rect
                x="7.33325"
                y="10.1667"
                width="1.33333"
                height="1.33333"
                rx="0.666667"
                fill={color ?? neutralDay.gray90}
              />
              <path
                d="M8 5.1333L8 8.90663"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.2"
                strokeLinecap="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.00005 1.30005C4.29974 1.30005 1.30005 4.29974 1.30005 8.00005C1.30005 11.7004 4.29974 14.7 8.00005 14.7C11.7004 14.7 14.7 11.7004 14.7 8.00005C14.7 4.29974 11.7004 1.30005 8.00005 1.30005ZM7.33325 10.8334C7.33325 10.4652 7.63173 10.1667 7.99992 10.1667C8.36811 10.1667 8.66658 10.4652 8.66658 10.8334C8.66658 11.2016 8.36811 11.5001 7.99992 11.5001C7.63173 11.5001 7.33325 11.2016 7.33325 10.8334ZM8.5999 5.13345C8.5999 4.80208 8.33127 4.53345 7.9999 4.53345C7.66853 4.53345 7.3999 4.80208 7.3999 5.13345L7.3999 8.90678C7.3999 9.23815 7.66853 9.50678 7.9999 9.50678C8.33127 9.50678 8.5999 9.23815 8.5999 8.90678L8.5999 5.13345Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 20:
      switch (style) {
        case 'line':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Style=Line, Size=20, Category=Info, Name=Error">
                <circle
                  id="Ellipse 532"
                  cx="10"
                  cy="10"
                  r="7.5"
                  stroke={color ?? neutralDay.gray90}
                  strokeWidth="1.75"
                />
                <rect
                  id="Rectangle 6376"
                  x="9.16663"
                  y="12.7085"
                  width="1.66667"
                  height="1.66667"
                  rx="0.833333"
                  fill={color ?? neutralDay.gray90}
                />
                <path
                  id="Vector 165"
                  d="M10 6.41675L10 11.1334"
                  stroke={color ?? neutralDay.gray90}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </g>
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Style=Fill, Size=20, Category=Info, Name=Error">
                <path
                  id="Subtract"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10 1.625C5.37462 1.625 1.625 5.37462 1.625 10C1.625 14.6254 5.37462 18.375 10 18.375C14.6254 18.375 18.375 14.6254 18.375 10C18.375 5.37462 14.6254 1.625 10 1.625ZM9.16675 13.5418C9.16675 13.0816 9.53984 12.7085 10.0001 12.7085C10.4603 12.7085 10.8334 13.0816 10.8334 13.5418C10.8334 14.0021 10.4603 14.3752 10.0001 14.3752C9.53984 14.3752 9.16675 14.0021 9.16675 13.5418ZM10.75 6.41675C10.75 6.00253 10.4142 5.66675 10 5.66675C9.58579 5.66675 9.25 6.00253 9.25 6.41675L9.25 11.1334C9.25 11.5476 9.58578 11.8834 10 11.8834C10.4142 11.8834 10.75 11.5476 10.75 11.1334L10.75 6.41675Z"
                  fill={color ?? neutralDay.gray90}
                />
              </g>
            </svg>
          );
      }
      break;
    case 24:
      switch (style) {
        case 'line':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Style=Line, Size=24, Category=Info, Name=Error">
                <circle
                  id="Ellipse 532"
                  cx="12"
                  cy="12"
                  r="9"
                  stroke={color ?? neutralDay.gray90}
                  strokeWidth="2"
                />
                <rect
                  id="Rectangle 6376"
                  x="11"
                  y="15.25"
                  width="2"
                  height="2"
                  rx="1"
                  fill={color ?? neutralDay.gray90}
                />
                <path
                  id="Vector 165"
                  d="M12 7.69995L12 13.36"
                  stroke={color ?? neutralDay.gray90}
                  strokeWidth="1.75"
                  strokeLinecap="round"
                />
              </g>
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Style=Fill, Size=24, Category=Info, Name=Error">
                <path
                  id="Subtract"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM11 16.25C11 15.6977 11.4477 15.25 12 15.25C12.5523 15.25 13 15.6977 13 16.25C13 16.8023 12.5523 17.25 12 17.25C11.4477 17.25 11 16.8023 11 16.25ZM12.875 7.69995C12.875 7.2167 12.4832 6.82495 12 6.82495C11.5168 6.82495 11.125 7.2167 11.125 7.69995L11.125 13.36C11.125 13.8432 11.5167 14.235 12 14.235C12.4832 14.235 12.875 13.8432 12.875 13.36L12.875 7.69995Z"
                  fill={color ?? neutralDay.gray90}
                />
              </g>
            </svg>
          );
      }
  }
}
