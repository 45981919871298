import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function MyPageLine({ size, color }: LineIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="8.0021"
            cy="5.33333"
            r="2.58333"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
          />
          <path
            d="M2.79999 13.3344C3.83742 11.5409 5.77645 10.3344 7.99729 10.3344C10.2181 10.3344 12.1572 11.5409 13.1946 13.3344"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="9.99998"
            cy="6.66667"
            r="3.29167"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
          />
          <path
            d="M3.50262 16.6667C4.79941 14.4249 7.2232 12.9167 9.99926 12.9167C12.7753 12.9167 15.1991 14.4249 16.4959 16.6667"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="8" r="4" stroke={color ?? neutralDay.gray100} strokeWidth="2" />
          <path
            d="M4.20703 19.998C5.76317 17.3079 8.67172 15.498 12.003 15.498C15.3343 15.498 18.2428 17.3079 19.799 19.998"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      );
  }
}
