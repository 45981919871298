enum SccColors {
  Red100 = 'red100',
  Red75 = 'red75',
  Red50 = 'red50',
  Red25 = 'red25',
  Red15 = 'red15',
  Red10 = 'red10',
  Green120 = 'green120',
  Green100 = 'green100',
  Green75 = 'green75',
  Green10 = 'green10',
}

export const scc: Record<SccColors, string> = {
  [SccColors.Red100]: '#E8344E',
  [SccColors.Red75]: '#FF6C7A',
  [SccColors.Red50]: '#FF9EA9',
  [SccColors.Red25]: '#FFD0DA',
  [SccColors.Red15]: '#FFF1F4',
  [SccColors.Red10]: '#FFF6F8',
  [SccColors.Green120]: '#0E434A',
  [SccColors.Green100]: '#30725D',
  [SccColors.Green75]: '#57B094',
  [SccColors.Green10]: '#D6FAEF',
};
