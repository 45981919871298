import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type FillIcon } from '../type';

export function CheckCircleFill({
  size,
  primaryColor = neutralDay.white,
  secondaryColor = neutralDay.gray100,
}: FillIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="8"
            cy="8"
            r="6"
            fill={secondaryColor}
            stroke={secondaryColor}
            strokeWidth="1.5"
          />
          <path
            d="M5.33325 8.35151L6.99992 9.86666L10.6666 6.53333"
            stroke={primaryColor}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="10"
            cy="10"
            r="7.5"
            fill={secondaryColor}
            stroke={secondaryColor}
            strokeWidth="1.75"
          />
          <path
            d="M6.66675 10.4394L8.75008 12.3333L13.3334 8.16663"
            stroke={primaryColor}
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="12"
            cy="12"
            r="9"
            fill={secondaryColor}
            stroke={secondaryColor}
            strokeWidth="2"
          />
          <path
            d="M8 12.5273L10.5 14.8L16 9.80005"
            stroke={primaryColor}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
}
