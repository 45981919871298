import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function ArrowForwardLine({ size, color }: LineIcon) {
  switch (size) {
    case 20:
      return (
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_575_15937)">
            <path
              d="M10.0007 3.8335L8.82565 5.0085L13.4757 9.66683H3.33398V11.3335H13.4757L8.82565 15.9918L10.0007 17.1668L16.6673 10.5002L10.0007 3.8335Z"
              fill={color ?? neutralDay.gray100}
            />
          </g>
          <defs>
            <clipPath id="clip0_575_15937">
              <rect width="20" height="20" fill="none" transform="translate(0 0.5)" />
            </clipPath>
          </defs>
        </svg>
      );
    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_575_15220)">
            <path
              d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z"
              fill={color ?? neutralDay.gray100}
            />
          </g>
          <defs>
            <clipPath id="clip0_575_15220">
              <rect width="24" height="24" fill="none" />
            </clipPath>
          </defs>
        </svg>
      );
    default:
      return null;
  }
}
