import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function ArrowNewUpLine({ size, color }: LineIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 7.83325L8 3.83325L12 7.83325"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 4.5L8 13.1667"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 20:
      return (
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 9.66675L10 4.66675L15 9.66675"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 5.5L10 16.3333"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 24:
      return (
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 11.5L12 5.5L18 11.5"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 6.5L12 19.5"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
}
