import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineFillIcon } from '../type';

export function InfoClock({ size, style, color }: LineFillIcon) {
  switch (size) {
    case 16:
      switch (style) {
        case 'line':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="8" cy="8" r="6" stroke={color ?? neutralDay.gray90} strokeWidth="1.5" />
              <path
                d="M8 5.17163V8.66673"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 8.66675L10.6667 10.0001"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 1.25C4.27208 1.25 1.25 4.27208 1.25 8C1.25 11.7279 4.27208 14.75 8 14.75C11.7279 14.75 14.75 11.7279 14.75 8C14.75 4.27208 11.7279 1.25 8 1.25ZM8 4.67146C8.27614 4.67146 8.5 4.89532 8.5 5.17146V8.35754L10.8904 9.55273C11.1374 9.67623 11.2375 9.97656 11.114 10.2236C10.9905 10.4705 10.6902 10.5707 10.4432 10.4472L7.78608 9.11862C7.76149 9.10696 7.73799 9.09335 7.7158 9.07799C7.66631 9.04388 7.62452 9.0019 7.59128 8.95464C7.53378 8.87321 7.5 8.77383 7.5 8.66656V5.17146C7.5 4.89532 7.72386 4.67146 8 4.67146Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 20:
      switch (style) {
        case 'line':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="10"
                cy="10"
                r="7.5"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
              />
              <path
                d="M10 6.46436V10.8332"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M10 10.8333L13.3333 12.4999"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 1.625C5.37462 1.625 1.625 5.37462 1.625 10C1.625 14.6254 5.37462 18.375 10 18.375C14.6254 18.375 18.375 14.6254 18.375 10C18.375 5.37462 14.6254 1.625 10 1.625ZM10 5.83945C10.3452 5.83945 10.625 6.11927 10.625 6.46445V10.4472L13.6127 11.941C13.9215 12.0954 14.0466 12.4708 13.8922 12.7796C13.7379 13.0883 13.3625 13.2134 13.0537 13.0591L9.72626 11.3953C9.71757 11.3911 9.70899 11.3867 9.70053 11.382C9.57847 11.3155 9.487 11.2137 9.43254 11.0956C9.4144 11.0564 9.40019 11.0151 9.39044 10.972C9.3788 10.921 9.37352 10.8684 9.375 10.8155V6.46445C9.375 6.11927 9.65482 5.83945 10 5.83945Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 24:
      switch (style) {
        case 'line':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="12" cy="12" r="9" stroke={color ?? neutralDay.gray90} strokeWidth="2" />
              <path
                d="M12 7.75732V13"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
                strokeLinecap="round"
              />
              <path
                d="M12 13L16 15"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
                strokeLinecap="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM12 7.00744C12.4142 7.00744 12.75 7.34322 12.75 7.75744V12.5364L16.3353 14.3291C16.7058 14.5143 16.856 14.9648 16.6707 15.3353C16.4855 15.7058 16.035 15.856 15.6645 15.6707L11.6833 13.6801C11.6364 13.6582 11.5921 13.6317 11.5511 13.601C11.4766 13.5455 11.4146 13.4777 11.3667 13.402C11.2873 13.2767 11.2462 13.1295 11.25 12.9802V7.75744C11.25 7.34322 11.5858 7.00744 12 7.00744Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
  }
}
