import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineOnlyIcon } from '../type';

export function ContentsCode({ size, color }: LineOnlyIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 10.6655L1.33333 7.99886L4 5.33219"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 5.33447L14.6667 8.00114L12 10.6678"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 3.33447L6 12.6678"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 13.3333L1.66667 9.99992L5 6.66659"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15 6.66675L18.3333 10.0001L15 13.3334"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.5 4.16675L7.5 15.8334"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 16L2 12L6 8"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18 8L22 12L18 16"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15 5L9 19"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      );
  }
}
