import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function Info1Line({ size, color }: LineIcon) {
  switch (size) {
    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="10" cy="10" r="7.5" stroke={color ?? neutralDay.gray100} strokeWidth="1.75" />
          <rect
            x="10.8333"
            y="7.29169"
            width="1.66667"
            height="1.66667"
            rx="0.833333"
            transform="rotate(-180 10.8333 7.29169)"
            fill={color ?? neutralDay.gray100}
          />
          <path
            d="M10 13.5833L10 8.86665"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="12" r="9" stroke={color ?? neutralDay.gray100} strokeWidth="2" />
          <rect
            x="13"
            y="8.75"
            width="2"
            height="2"
            rx="1"
            transform="rotate(-180 13 8.75)"
            fill={color ?? neutralDay.gray100}
          />
          <path
            d="M12 16.3L12 10.64"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
        </svg>
      );
    default:
      return null;
  }
}
