import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function NewTabLine({ size, color }: LineIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 9.83333V12.1667C12 12.8083 11.475 13.3333 10.8334 13.3333H3.83335C3.19169 13.3333 2.66669 12.8083 2.66669 12.1667V5.16667C2.66669 4.525 3.19169 4 3.83335 4H6.16669"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M9.33331 2.66669H13.3333V6.66669"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.3333 2.66669L8 8.00002"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 12.2917V15.2083C15 16.0104 14.3437 16.6667 13.5416 16.6667H4.79165C3.98956 16.6667 3.33331 16.0104 3.33331 15.2083V6.45833C3.33331 5.65625 3.98956 5 4.79165 5H7.70831"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <path
            d="M11.6667 3.33331H16.6667V8.33331"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.6667 3.33331L10 9.99998"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 14.75V18.25C18 19.2125 17.2125 20 16.25 20H5.75C4.7875 20 4 19.2125 4 18.25V7.75C4 6.7875 4.7875 6 5.75 6H9.25"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14 4H20V10"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20 4L12 12"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
}
