import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function MessageLine({ size, color }: LineIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 13.999L7.364 14.3965C7.50106 14.6158 7.74141 14.749 8 14.749C8.25859 14.749 8.49895 14.6158 8.636 14.3965L8 13.999ZM9.47083 11.6457L8.83483 11.2482L9.47083 11.6457ZM3.33333 3.41565H12.6667V1.91565H3.33333V3.41565ZM13.25 3.99898V9.99898H14.75V3.99898H13.25ZM2.75 9.99898V3.99898H1.25V9.99898H2.75ZM8.636 13.6015L7.16517 11.2482L5.89317 12.0431L7.364 14.3965L8.636 13.6015ZM5.96384 10.5823H3.33333V12.0823H5.96384V10.5823ZM12.6667 10.5823H10.0362V12.0823H12.6667V10.5823ZM8.83483 11.2482L7.364 13.6015L8.636 14.3965L10.1068 12.0431L8.83483 11.2482ZM10.0362 10.5823C9.54771 10.5823 9.09371 10.8339 8.83483 11.2482L10.1068 12.0431C10.0916 12.0675 10.0649 12.0823 10.0362 12.0823V10.5823ZM1.25 9.99898C1.25 11.1496 2.18274 12.0823 3.33333 12.0823V10.5823C3.01117 10.5823 2.75 10.3211 2.75 9.99898H1.25ZM13.25 9.99898C13.25 10.3211 12.9888 10.5823 12.6667 10.5823V12.0823C13.8173 12.0823 14.75 11.1496 14.75 9.99898H13.25ZM12.6667 3.41565C12.9888 3.41565 13.25 3.67682 13.25 3.99898H14.75C14.75 2.84839 13.8173 1.91565 12.6667 1.91565V3.41565ZM7.16517 11.2482C6.90629 10.8339 6.45229 10.5823 5.96384 10.5823V12.0823C5.9351 12.0823 5.9084 12.0675 5.89317 12.0431L7.16517 11.2482ZM3.33333 1.91565C2.18274 1.91565 1.25 2.84839 1.25 3.99898H2.75C2.75 3.67682 3.01117 3.41565 3.33333 3.41565V1.91565Z"
            fill={color ?? neutralDay.gray100}
          />
          <rect
            x="4.58203"
            y="6.25"
            width="1.5"
            height="1.5"
            rx="0.75"
            fill={color ?? neutralDay.gray100}
          />
          <rect
            x="7.25"
            y="6.25"
            width="1.5"
            height="1.5"
            rx="0.75"
            fill={color ?? neutralDay.gray100}
          />
          <rect
            x="9.91797"
            y="6.25"
            width="1.5"
            height="1.5"
            rx="0.75"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 17.5L9.258 17.9637C9.4179 18.2196 9.69831 18.375 10 18.375C10.3017 18.375 10.5821 18.2196 10.742 17.9637L10 17.5ZM11.8385 14.5583L12.5805 15.0221L11.8385 14.5583ZM8.16146 14.5583L7.41946 15.0221L8.16146 14.5583ZM4.16667 4.20831H15.8333V2.45831H4.16667V4.20831ZM16.625 4.99998V12.5H18.375V4.99998H16.625ZM3.375 12.5V4.99998H1.625V12.5H3.375ZM10.742 17.0362L8.90346 14.0946L7.41946 15.0221L9.258 17.9637L10.742 17.0362ZM7.45479 13.2916H4.16667V15.0416H7.45479V13.2916ZM15.8333 13.2916H12.5452V15.0416H15.8333V13.2916ZM11.0965 14.0946L9.258 17.0362L10.742 17.9637L12.5805 15.0221L11.0965 14.0946ZM12.5452 13.2916C11.9562 13.2916 11.4087 13.5951 11.0965 14.0946L12.5805 15.0221C12.5729 15.0342 12.5596 15.0416 12.5452 15.0416V13.2916ZM1.625 12.5C1.625 13.9037 2.76294 15.0416 4.16667 15.0416V13.2916C3.72944 13.2916 3.375 12.9372 3.375 12.5H1.625ZM16.625 12.5C16.625 12.9372 16.2706 13.2916 15.8333 13.2916V15.0416C17.2371 15.0416 18.375 13.9037 18.375 12.5H16.625ZM15.8333 4.20831C16.2706 4.20831 16.625 4.56275 16.625 4.99998H18.375C18.375 3.59626 17.2371 2.45831 15.8333 2.45831V4.20831ZM8.90346 14.0946C8.59128 13.5951 8.04381 13.2916 7.45479 13.2916V15.0416C7.44043 15.0416 7.42707 15.0342 7.41946 15.0221L8.90346 14.0946ZM4.16667 2.45831C2.76294 2.45831 1.625 3.59625 1.625 4.99998H3.375C3.375 4.56275 3.72944 4.20831 4.16667 4.20831V2.45831Z"
            fill={color ?? neutralDay.gray100}
          />
          <rect
            x="5.78906"
            y="7.8811"
            width="1.75"
            height="1.75"
            rx="0.875"
            fill={color ?? neutralDay.gray100}
          />
          <rect
            x="9.125"
            y="7.8811"
            width="1.75"
            height="1.75"
            rx="0.875"
            fill={color ?? neutralDay.gray100}
          />
          <rect
            x="12.457"
            y="7.8811"
            width="1.75"
            height="1.75"
            rx="0.875"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 21L11.152 21.53C11.3347 21.8224 11.6552 22 12 22C12.3448 22 12.6653 21.8224 12.848 21.53L12 21ZM14.2062 17.47L15.0542 18L14.2062 17.47ZM9.79375 17.47L8.94575 18L9.79375 17.47ZM5 5H19V3H5V5ZM20 6V15H22V6H20ZM4 15V6H2V15H4ZM12.848 20.47L10.6417 16.94L8.94575 18L11.152 21.53L12.848 20.47ZM8.94575 16H5V18H8.94575V16ZM19 16H15.0542V18H19V16ZM13.3583 16.94L11.152 20.47L12.848 21.53L15.0542 18L13.3583 16.94ZM15.0542 16C14.3647 16 13.7237 16.3552 13.3583 16.94L15.0542 18V16ZM2 15C2 16.6569 3.34315 18 5 18V16C4.44772 16 4 15.5523 4 15H2ZM20 15C20 15.5523 19.5523 16 19 16V18C20.6569 18 22 16.6569 22 15H20ZM19 5C19.5523 5 20 5.44772 20 6H22C22 4.34315 20.6569 3 19 3V5ZM10.6417 16.94C10.2763 16.3552 9.63533 16 8.94575 16V18L8.94575 18L10.6417 16.94ZM5 3C3.34315 3 2 4.34315 2 6H4C4 5.44772 4.44772 5 5 5V3Z"
            fill={color ?? neutralDay.gray100}
          />
          <rect x="7" y="9.5" width="2" height="2" rx="1" fill={color ?? neutralDay.gray100} />
          <rect x="11" y="9.5" width="2" height="2" rx="1" fill={color ?? neutralDay.gray100} />
          <rect x="15" y="9.5" width="2" height="2" rx="1" fill={color ?? neutralDay.gray100} />
        </svg>
      );
  }
}
