import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type FillOnlyIcon } from '../type';

export function ContentsPlay({ size, color }: FillOnlyIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.32458 2.80621C5.27186 2.15839 3.9165 2.91577 3.9165 4.15183V11.8478C3.9165 13.0839 5.27187 13.8413 6.32458 13.1934L12.5776 9.34545C13.5801 8.72848 13.5801 7.27117 12.5776 6.65421L6.32458 2.80621Z"
            fill={color ?? neutralDay.gray90}
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.51888 3.36163C6.40023 2.67375 4.95996 3.47796 4.95996 4.79046V15.2098C4.95996 16.5223 6.40023 17.3265 7.51888 16.6386L15.9909 11.4289C17.0563 10.7738 17.0563 9.22639 15.9909 8.57128L7.51888 3.36163Z"
            fill={color ?? neutralDay.gray90}
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.04819 4.08622C7.71565 3.26619 6 4.22489 6 5.78953V18.2104C6 19.775 7.71565 20.7337 9.04819 19.9137L19.1402 13.7033C20.4092 12.9223 20.4092 11.0776 19.1402 10.2967L9.04819 4.08622Z"
            fill={color ?? neutralDay.gray90}
          />
        </svg>
      );
  }
}
