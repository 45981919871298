import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineFillIcon } from '../type';

export function ActionMessage({ size, style, color }: LineFillIcon) {
  switch (size) {
    case 16:
      switch (style) {
        case 'line':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 3.99898C2 3.2626 2.59695 2.66565 3.33333 2.66565H12.6667C13.403 2.66565 14 3.2626 14 3.99898V9.99898C14 10.7354 13.403 11.3323 12.6667 11.3323H10.0362C9.80631 11.3323 9.59266 11.4507 9.47083 11.6457L8 13.999L6.52917 11.6457C6.40734 11.4507 6.1937 11.3323 5.96384 11.3323H3.33333C2.59695 11.3323 2 10.7354 2 9.99898V3.99898Z"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinejoin="round"
              />
              <rect
                x="4.58203"
                y="6.25"
                width="1.5"
                height="1.5"
                rx="0.75"
                fill={color ?? neutralDay.gray90}
              />
              <rect
                x="7.25"
                y="6.25"
                width="1.5"
                height="1.5"
                rx="0.75"
                fill={color ?? neutralDay.gray90}
              />
              <rect
                x="9.91797"
                y="6.25"
                width="1.5"
                height="1.5"
                rx="0.75"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.25 3.99898C1.25 2.84839 2.18274 1.91565 3.33333 1.91565H12.6667C13.8173 1.91565 14.75 2.84839 14.75 3.99898V9.99898C14.75 11.1496 13.8173 12.0823 12.6667 12.0823H10.0824L8.636 14.3965C8.49895 14.6158 8.25859 14.749 8 14.749C7.74141 14.749 7.50106 14.6158 7.364 14.3965L5.91765 12.0823H3.33333C2.18274 12.0823 1.25 11.1496 1.25 9.99898V3.99898ZM5.33203 6.25C4.91782 6.25 4.58203 6.58579 4.58203 7C4.58203 7.41421 4.91782 7.75 5.33203 7.75C5.74624 7.75 6.08203 7.41421 6.08203 7C6.08203 6.58579 5.74624 6.25 5.33203 6.25ZM7.25 7C7.25 6.58579 7.58579 6.25 8 6.25C8.41421 6.25 8.75 6.58579 8.75 7C8.75 7.41421 8.41421 7.75 8 7.75C7.58579 7.75 7.25 7.41421 7.25 7ZM10.668 6.25C10.2538 6.25 9.91797 6.58579 9.91797 7C9.91797 7.41421 10.2538 7.75 10.668 7.75C11.0822 7.75 11.418 7.41421 11.418 7C11.418 6.58579 11.0822 6.25 10.668 6.25Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 20:
      switch (style) {
        case 'line':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.5 5.00004C2.5 4.07956 3.24619 3.33337 4.16667 3.33337H15.8333C16.7538 3.33337 17.5 4.07957 17.5 5.00004V12.5C17.5 13.4205 16.7538 14.1667 15.8333 14.1667H12.5452C12.2579 14.1667 11.9908 14.3147 11.8385 14.5584L10 17.5L8.16146 14.5584C8.00918 14.3147 7.74212 14.1667 7.45479 14.1667H4.16667C3.24619 14.1667 2.5 13.4205 2.5 12.5V5.00004Z"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
                strokeLinejoin="round"
              />
              <rect
                x="5.78906"
                y="7.8811"
                width="1.75"
                height="1.75"
                rx="0.875"
                fill={color ?? neutralDay.gray90}
              />
              <rect
                x="9.125"
                y="7.8811"
                width="1.75"
                height="1.75"
                rx="0.875"
                fill={color ?? neutralDay.gray90}
              />
              <rect
                x="12.457"
                y="7.8811"
                width="1.75"
                height="1.75"
                rx="0.875"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.625 5.00004C1.625 3.59631 2.76294 2.45837 4.16667 2.45837H15.8333C17.2371 2.45837 18.375 3.59632 18.375 5.00004V12.5C18.375 13.9038 17.2371 15.0417 15.8333 15.0417H12.5683L10.742 17.9638C10.5821 18.2196 10.3017 18.375 10 18.375C9.69831 18.375 9.4179 18.2196 9.258 17.9638L7.4317 15.0417H4.16667C2.76294 15.0417 1.625 13.9038 1.625 12.5V5.00004ZM6.66406 7.88281C6.18081 7.88281 5.78906 8.27456 5.78906 8.75781C5.78906 9.24106 6.18081 9.63281 6.66406 9.63281C7.14731 9.63281 7.53906 9.24106 7.53906 8.75781C7.53906 8.27456 7.14731 7.88281 6.66406 7.88281ZM9.125 8.75781C9.125 8.27456 9.51675 7.88281 10 7.88281C10.4832 7.88281 10.875 8.27456 10.875 8.75781C10.875 9.24106 10.4832 9.63281 10 9.63281C9.51675 9.63281 9.125 9.24106 9.125 8.75781ZM13.332 7.88281C12.8488 7.88281 12.457 8.27456 12.457 8.75781C12.457 9.24106 12.8488 9.63281 13.332 9.63281C13.8153 9.63281 14.207 9.24106 14.207 8.75781C14.207 8.27456 13.8153 7.88281 13.332 7.88281Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 24:
      switch (style) {
        case 'line':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 6C3 4.89543 3.89543 4 5 4H19C20.1046 4 21 4.89543 21 6V15C21 16.1046 20.1046 17 19 17H15.0542C14.7095 17 14.389 17.1776 14.2062 17.47L12 21L9.79375 17.47C9.61101 17.1776 9.29054 17 8.94575 17H5C3.89543 17 3 16.1046 3 15V6Z"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="2"
                strokeLinejoin="round"
              />
              <rect x="7" y="9.5" width="2" height="2" rx="1" fill={color ?? neutralDay.gray90} />
              <rect x="11" y="9.5" width="2" height="2" rx="1" fill={color ?? neutralDay.gray90} />
              <rect x="15" y="9.5" width="2" height="2" rx="1" fill={color ?? neutralDay.gray90} />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 6C2 4.34315 3.34315 3 5 3H19C20.6569 3 22 4.34315 22 6V15C22 16.6569 20.6569 18 19 18H15.0542L12.848 21.53C12.6653 21.8224 12.3448 22 12 22C11.6552 22 11.3347 21.8224 11.152 21.53L8.94575 18H5C3.34315 18 2 16.6569 2 15V6ZM8 9.5C7.44772 9.5 7 9.94772 7 10.5C7 11.0523 7.44772 11.5 8 11.5C8.55228 11.5 9 11.0523 9 10.5C9 9.94772 8.55228 9.5 8 9.5ZM11 10.5C11 9.94772 11.4477 9.5 12 9.5C12.5523 9.5 13 9.94772 13 10.5C13 11.0523 12.5523 11.5 12 11.5C11.4477 11.5 11 11.0523 11 10.5ZM16 9.5C15.4477 9.5 15 9.94772 15 10.5C15 11.0523 15.4477 11.5 16 11.5C16.5523 11.5 17 11.0523 17 10.5C17 9.94772 16.5523 9.5 16 9.5Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
  }
}
