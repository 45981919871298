import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineFillIcon } from '../type';

export function ContentsVideo({ size, style, color }: LineFillIcon) {
  switch (size) {
    case 16:
      switch (style) {
        case 'line':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 13.3345H12"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <rect
                x="2.66876"
                y="2.66553"
                width="10.6667"
                height="8"
                rx="1.33333"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
              />
              <path
                d="M7 5.60142V7.73192C7 7.99373 7.28798 8.15334 7.51 8.01458L9.2144 6.94933C9.42329 6.81878 9.42329 6.51456 9.2144 6.384L7.51 5.31875C7.28798 5.17999 7 5.3396 7 5.60142Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.0021 1.91553C2.8515 1.91553 1.91876 2.84827 1.91876 3.99886V9.33219C1.91876 10.4828 2.8515 11.4155 4.0021 11.4155H12.0021C13.1527 11.4155 14.0854 10.4828 14.0854 9.33219V3.99886C14.0854 2.84827 13.1527 1.91553 12.0021 1.91553H4.0021ZM7.00001 5.60142V7.73192C7.00001 7.99373 7.28799 8.15334 7.51001 8.01458L9.21441 6.94933C9.4233 6.81878 9.4233 6.51456 9.21441 6.384L7.51001 5.31875C7.28799 5.17999 7.00001 5.3396 7.00001 5.60142Z"
                fill={color ?? neutralDay.gray90}
              />
              <path
                d="M4.00001 12.5845C3.5858 12.5845 3.25001 12.9203 3.25001 13.3345C3.25001 13.7487 3.5858 14.0845 4.00001 14.0845H12C12.4142 14.0845 12.75 13.7487 12.75 13.3345C12.75 12.9203 12.4142 12.5845 12 12.5845H4.00001Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 20:
      switch (style) {
        case 'line':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 16.6667H15"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
                strokeLinecap="round"
              />
              <rect
                x="3.33331"
                y="3.33325"
                width="13.3333"
                height="10"
                rx="1.66667"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
              />
              <path
                d="M8.75 7.00177V9.6649C8.75 9.99216 9.10998 10.1917 9.3875 10.0182L11.518 8.68667C11.7791 8.52347 11.7791 8.1432 11.518 7.98L9.3875 6.64844C9.10998 6.47499 8.75 6.6745 8.75 7.00177Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.99998 2.45825C3.59626 2.45825 2.45831 3.5962 2.45831 4.99992V11.6666C2.45831 13.0703 3.59625 14.2083 4.99998 14.2083H15C16.4037 14.2083 17.5416 13.0703 17.5416 11.6666V4.99992C17.5416 3.5962 16.4037 2.45825 15 2.45825H4.99998ZM8.74998 7.00177V9.6649C8.74998 9.99216 9.10996 10.1917 9.38748 10.0182L11.518 8.68667C11.7791 8.52347 11.7791 8.1432 11.518 7.98L9.38748 6.64844C9.10996 6.47499 8.74998 6.6745 8.74998 7.00177Z"
                fill={color ?? neutralDay.gray90}
              />
              <path
                d="M4.99998 15.7917C4.51674 15.7917 4.12498 16.1835 4.12498 16.6667C4.12498 17.15 4.51674 17.5417 4.99998 17.5417H15C15.4832 17.5417 15.875 17.15 15.875 16.6667C15.875 16.1835 15.4832 15.7917 15 15.7917H4.99998Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 24:
      switch (style) {
        case 'line':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 20H18"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="2"
                strokeLinecap="round"
              />
              <rect
                x="4"
                y="4"
                width="16"
                height="12"
                rx="2"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="2"
              />
              <path
                d="M10.5 8.40212V11.5979C10.5 11.9906 10.932 12.23 11.265 12.0219L13.8216 10.424C14.1349 10.2282 14.1349 9.77183 13.8216 9.576L11.265 7.97812C10.932 7.76998 10.5 8.00941 10.5 8.40212Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 3C4.34315 3 3 4.34315 3 6V14C3 15.6569 4.34315 17 6 17H18C19.6569 17 21 15.6569 21 14V6C21 4.34315 19.6569 3 18 3H6ZM10.5 8.40212V11.5979C10.5 11.9906 10.932 12.23 11.265 12.0219L13.8216 10.424C14.1349 10.2282 14.1349 9.77183 13.8216 9.576L11.265 7.97812C10.932 7.76998 10.5 8.00941 10.5 8.40212Z"
                fill={color ?? neutralDay.gray90}
              />
              <path
                d="M6 19C5.44772 19 5 19.4477 5 20C5 20.5523 5.44772 21 6 21H18C18.5523 21 19 20.5523 19 20C19 19.4477 18.5523 19 18 19H6Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
  }
}
