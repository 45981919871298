import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type FillIcon } from '../type';

export function CloseCircleFill({ size, primaryColor, secondaryColor }: FillIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="8"
            cy="8"
            r="6"
            fill={primaryColor ?? neutralDay.gray100}
            stroke={primaryColor ?? neutralDay.gray100}
            strokeWidth="1.5"
          />
          <path
            d="M6 6L10 10"
            stroke={secondaryColor ?? 'white'}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 6L6 10"
            stroke={secondaryColor ?? 'white'}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="10"
            cy="10"
            r="7.5"
            fill={primaryColor ?? neutralDay.gray100}
            stroke={primaryColor ?? neutralDay.gray100}
            strokeWidth="1.75"
          />
          <path
            d="M7.5 7.5L12.5 12.5"
            stroke={secondaryColor ?? 'white'}
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.5 7.5L7.5 12.5"
            stroke={secondaryColor ?? 'white'}
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="12"
            cy="12"
            r="9"
            fill={primaryColor ?? neutralDay.gray100}
            stroke={primaryColor ?? neutralDay.gray100}
            strokeWidth="2"
          />
          <path
            d="M9 9L15 15"
            stroke={secondaryColor ?? 'white'}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15 9L9 15"
            stroke={secondaryColor ?? 'white'}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
}
