import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function ShareLine({ size, color }: LineIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="3.66667"
            cy="8.33232"
            r="1.66667"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
          />
          <circle
            cx="11.6667"
            cy="3.66667"
            r="1.66667"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
          />
          <path
            d="M5.6687 7.16768L7.6687 6.00102L9.6687 4.83435M5.6687 9.33435L7.6687 10.3344L9.6687 11.3344"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <circle
            cx="11.6667"
            cy="12.3323"
            r="1.66667"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="4.58333"
            cy="10.4166"
            r="2.08333"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
          />
          <circle
            cx="14.5833"
            cy="4.58333"
            r="2.08333"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
          />
          <path
            d="M7.08337 8.95835L9.58337 7.50002L12.0834 6.04169M7.08337 11.6667L9.58337 12.9167L12.0834 14.1667"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <circle
            cx="14.5833"
            cy="15.4166"
            r="2.08333"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="5.5" cy="12.5" r="2.5" stroke={color ?? neutralDay.gray100} strokeWidth="2" />
          <circle cx="17.5" cy="5.5" r="2.5" stroke={color ?? neutralDay.gray100} strokeWidth="2" />
          <path
            d="M8.5 10.75L11.5 9L14.5 7.25M8.5 14L11.5 15.5L14.5 17"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinecap="round"
          />
          <circle
            cx="17.5"
            cy="18.5"
            r="2.5"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
          />
        </svg>
      );
  }
}
