import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function PointLine({ size, color }: LineIcon) {
  switch (size) {
    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="10" cy="10" r="7.5" stroke={color ?? neutralDay.gray100} strokeWidth="1.75" />
          <path
            d="M8.49933 6.83398V13.5007"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M10.5827 6.83398H8.49933V11.0007H10.5827C11.9716 11.0007 12.666 10.1673 12.666 8.91732C12.666 7.66732 11.9716 6.83398 10.5827 6.83398Z"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="12" r="9" stroke={color ?? neutralDay.gray100} strokeWidth="2" />
          <path
            d="M10.1992 8.20078V16.2008"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <path
            d="M12.6992 8.20078H10.1992V13.2008H12.6992C14.3659 13.2008 15.1992 12.2008 15.1992 10.7008C15.1992 9.20078 14.3659 8.20078 12.6992 8.20078Z"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    default:
      return null;
  }
}
