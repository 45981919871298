import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineFillIcon } from '../type';

export function InfoCircle({ size, style, color }: LineFillIcon) {
  switch (size) {
    case 16:
      switch (style) {
        case 'line':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="8" cy="8" r="6" stroke={color ?? neutralDay.gray90} strokeWidth="1.4" />
              <rect
                x="8.66663"
                y="5.8335"
                width="1.33333"
                height="1.33333"
                rx="0.666667"
                transform="rotate(-180 8.66663 5.8335)"
                fill={color ?? neutralDay.gray90}
              />
              <path
                d="M8 10.8667L8 7.09337"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.2"
                strokeLinecap="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.00005 1.30005C4.29974 1.30005 1.30005 4.29974 1.30005 8.00005C1.30005 11.7004 4.29974 14.7 8.00005 14.7C11.7004 14.7 14.7 11.7004 14.7 8.00005C14.7 4.29974 11.7004 1.30005 8.00005 1.30005ZM8.66675 5.16683C8.66675 5.53502 8.36827 5.8335 8.00008 5.8335C7.63189 5.8335 7.33342 5.53502 7.33342 5.16683C7.33342 4.79864 7.63189 4.50016 8.00008 4.50016C8.36827 4.50016 8.66675 4.79864 8.66675 5.16683ZM7.4001 10.8666C7.4001 11.1979 7.66873 11.4666 8.0001 11.4666C8.33147 11.4666 8.6001 11.1979 8.6001 10.8666L8.6001 7.09322C8.6001 6.76185 8.33147 6.49322 8.0001 6.49322C7.66873 6.49322 7.4001 6.76185 7.4001 7.09322V10.8666Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 20:
      switch (style) {
        case 'line':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="10"
                cy="10"
                r="7.5"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
              />
              <rect
                x="10.8334"
                y="7.29175"
                width="1.66667"
                height="1.66667"
                rx="0.833333"
                transform="rotate(-180 10.8334 7.29175)"
                fill={color ?? neutralDay.gray90}
              />
              <path
                d="M10 13.5833L10 8.86658"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 1.625C5.37462 1.625 1.625 5.37462 1.625 10C1.625 14.6254 5.37462 18.375 10 18.375C14.6254 18.375 18.375 14.6254 18.375 10C18.375 5.37462 14.6254 1.625 10 1.625ZM10.8333 6.45842C10.8333 6.91865 10.4602 7.29175 9.99992 7.29175C9.53968 7.29175 9.16659 6.91865 9.16659 6.45841C9.16659 5.99818 9.53968 5.62508 9.99992 5.62508C10.4602 5.62508 10.8333 5.99818 10.8333 6.45842ZM9.25 13.5833C9.25 13.9975 9.58579 14.3333 10 14.3333C10.4142 14.3333 10.75 13.9975 10.75 13.5833L10.75 8.86658C10.75 8.45237 10.4142 8.11658 10 8.11658C9.58579 8.11658 9.25 8.45237 9.25 8.86658L9.25 13.5833Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 24:
      switch (style) {
        case 'line':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="12" cy="12" r="9" stroke={color ?? neutralDay.gray90} strokeWidth="2" />
              <rect
                x="13"
                y="8.75"
                width="2"
                height="2"
                rx="1"
                transform="rotate(-180 13 8.75)"
                fill={color ?? neutralDay.gray90}
              />
              <path
                d="M12 16.3L12 10.64"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
                strokeLinecap="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM13 7.75C13 8.30228 12.5523 8.75 12 8.75C11.4477 8.75 11 8.30228 11 7.75C11 7.19772 11.4477 6.75 12 6.75C12.5523 6.75 13 7.19772 13 7.75ZM11.125 16.3C11.125 16.7833 11.5168 17.175 12 17.175C12.4832 17.175 12.875 16.7833 12.875 16.3L12.875 10.64C12.875 10.1568 12.4832 9.76505 12 9.76505C11.5168 9.76505 11.125 10.1568 11.125 10.64L11.125 16.3Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
  }
}
