import { useEffect, useState } from 'react';
import { LMS_BREAKPOINT } from '@/_global/_constants/breakpoints';

const isBrowser = typeof window !== 'undefined';

const useIsMobile = (breakpoint: number = LMS_BREAKPOINT) => {
  const [isMobile, setIsMobile] = useState(() => {
    if (!isBrowser) return false;
    return window.innerWidth < breakpoint;
  });

  useEffect(() => {
    if (!isBrowser) return;
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < breakpoint);
    };

    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);

    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, [breakpoint]);

  return isMobile;
};

export default useIsMobile;
