import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineOnlyIcon } from '../type';

export function ActionFilter({ size, color }: LineOnlyIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.3334 5.33337L6.66671 5.33337"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M4 5.33337L2.66667 5.33337"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M13.3334 10.6666L12 10.6666"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M9.33337 10.6666L2.66671 10.6666"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M10.6667 12C9.93029 12 9.33333 11.4031 9.33333 10.6667C9.33333 9.93033 9.93029 9.33337 10.6667 9.33337C11.403 9.33337 12 9.93033 12 10.6667C12 11.4031 11.403 12 10.6667 12Z"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M5.33329 6.66667C4.59691 6.66667 3.99996 6.06971 3.99996 5.33333C3.99996 4.59695 4.59691 4 5.33329 4C6.06967 4 6.66663 4.59695 6.66663 5.33333C6.66663 6.06971 6.06967 6.66667 5.33329 6.66667Z"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.6666 6.66663L8.33329 6.66663"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <path
            d="M5 6.66663L3.33333 6.66663"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <path
            d="M16.6666 13.3334L15 13.3334"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <path
            d="M11.6666 13.3334L3.33329 13.3334"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <path
            d="M13.3333 15C12.4129 15 11.6667 14.2538 11.6667 13.3333C11.6667 12.4128 12.4129 11.6666 13.3333 11.6666C14.2538 11.6666 15 12.4128 15 13.3333C15 14.2538 14.2538 15 13.3333 15Z"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <path
            d="M6.66671 8.33333C5.74623 8.33333 5.00004 7.58714 5.00004 6.66667C5.00004 5.74619 5.74623 5 6.66671 5C7.58718 5 8.33337 5.74619 8.33337 6.66667C8.33337 7.58714 7.58718 8.33333 6.66671 8.33333Z"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 8H10"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M6 8L4 8"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M20 16L18 16"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M14 16L4 16"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M16 18C14.8954 18 14 17.1046 14 16C14 14.8954 14.8954 14 16 14C17.1046 14 18 14.8954 18 16C18 17.1046 17.1046 18 16 18Z"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M8 10C6.89543 10 6 9.10457 6 8C6 6.89543 6.89543 6 8 6C9.10457 6 10 6.89543 10 8C10 9.10457 9.10457 10 8 10Z"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      );
  }
}
