import { type LineIcon } from '../type';
import { neutralDay } from '@/_global/_styles/design-system/foundation/color';

export function DownloadLine({ size, color }: LineIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.3335 8L8.00016 10.6667L10.6668 8"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 10.6667V2.66675"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M13.3332 11.3333V12.4999C13.3332 12.9602 12.9601 13.3333 12.4998 13.3333H3.49984C3.0396 13.3333 2.6665 12.9602 2.6665 12.4999V11.3333"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      );
    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.0001 13.3333L9.38136 13.9521C9.72307 14.2938 10.2771 14.2938 10.6188 13.9521L10.0001 13.3333ZM7.28547 9.38128C6.94376 9.03957 6.38974 9.03957 6.04803 9.38128C5.70632 9.72299 5.70632 10.277 6.04803 10.6187L7.28547 9.38128ZM13.9521 10.6187C14.2938 10.277 14.2938 9.72299 13.9521 9.38128C13.6104 9.03957 13.0564 9.03957 12.7147 9.38128L13.9521 10.6187ZM6.04803 10.6187L9.38136 13.9521L10.6188 12.7146L7.28547 9.38128L6.04803 10.6187ZM10.6188 13.9521L13.9521 10.6187L12.7147 9.38128L9.38136 12.7146L10.6188 13.9521Z"
            fill={color ?? neutralDay.gray100}
          />
          <path
            d="M10 13.3335V3.3335"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <path
            d="M16.6666 14.1665V15.8332C16.6666 16.2934 16.2935 16.6665 15.8333 16.6665H4.16659C3.70635 16.6665 3.33325 16.2934 3.33325 15.8332V14.1665"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 16L11.2929 16.7071C11.6834 17.0976 12.3166 17.0976 12.7071 16.7071L12 16ZM8.70711 11.2929C8.31658 10.9024 7.68342 10.9024 7.29289 11.2929C6.90237 11.6834 6.90237 12.3166 7.29289 12.7071L8.70711 11.2929ZM16.7071 12.7071C17.0976 12.3166 17.0976 11.6834 16.7071 11.2929C16.3166 10.9024 15.6834 10.9024 15.2929 11.2929L16.7071 12.7071ZM7.29289 12.7071L11.2929 16.7071L12.7071 15.2929L8.70711 11.2929L7.29289 12.7071ZM12.7071 16.7071L16.7071 12.7071L15.2929 11.2929L11.2929 15.2929L12.7071 16.7071Z"
            fill={color ?? neutralDay.gray100}
          />
          <path
            d="M12 16V4"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M20 17V19C20 19.5523 19.5523 20 19 20H5C4.44772 20 4 19.5523 4 19V17"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      );
  }
}
