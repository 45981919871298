import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineOnlyIcon } from '../type';

export function ActionShare({ size, color }: LineOnlyIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="3.93324"
            cy="7.99976"
            r="1.53333"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
          />
          <circle
            cx="12.1332"
            cy="3.66639"
            r="1.53333"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
          />
          <path
            d="M5.16406 7.33327L10.8344 4.26184M5.16406 8.6666L10.6 11.611"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
          />
          <circle
            cx="12.1332"
            cy="12.3333"
            r="1.53333"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="4.91667"
            cy="10"
            r="1.91667"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
          />
          <circle
            cx="15.1667"
            cy="4.58341"
            r="1.91667"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
          />
          <path
            d="M6.45508 9.1668L13.543 5.32751M6.45508 10.8335L13.2499 14.514"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
          />
          <circle
            cx="15.1667"
            cy="15.4168"
            r="1.91667"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="5.9001" cy="12" r="2.3" stroke={color ?? neutralDay.gray90} strokeWidth="2" />
          <circle
            cx="18.2001"
            cy="5.49995"
            r="2.3"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
          />
          <path
            d="M7.74634 11L16.2518 6.39282M7.74634 13L15.9002 17.4166"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
          />
          <circle
            cx="18.2001"
            cy="18.5"
            r="2.3"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
          />
        </svg>
      );
  }
}
