import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineFillIcon } from '../type';

export function InfoWarning({ size, style, color }: LineFillIcon) {
  switch (size) {
    case 16:
      switch (style) {
        case 'line':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_4987_10203)">
                <path
                  d="M7.12588 3.23999C7.50689 2.55418 8.49319 2.55418 8.8742 3.23999L13.8413 12.1809C14.2116 12.8474 13.7297 13.6665 12.9672 13.6665H3.03289C2.2704 13.6665 1.78844 12.8474 2.15873 12.1809L7.12588 3.23999Z"
                  stroke={color ?? neutralDay.gray90}
                  strokeWidth="1.33333"
                />
                <g filter="url(#filter0_d_4987_10203)">
                  <rect
                    x="7.33337"
                    y="11.1147"
                    width="1.33333"
                    height="1.33333"
                    rx="0.666667"
                    fill={color ?? neutralDay.gray90}
                  />
                  <rect
                    x="7.66671"
                    y="11.4481"
                    width="0.666667"
                    height="0.666667"
                    rx="0.333333"
                    stroke={color ?? neutralDay.gray90}
                    strokeWidth="0.666667"
                  />
                </g>
                <path
                  d="M8 6L8 9.77333"
                  stroke={color ?? neutralDay.gray90}
                  strokeWidth="1.16667"
                  strokeLinecap="round"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_4987_10203"
                  x="4.66671"
                  y="11.1147"
                  width="6.66671"
                  height="6.66659"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="2.66667" />
                  <feGaussianBlur stdDeviation="1.33333" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_4987_10203"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_4987_10203"
                    result="shape"
                  />
                </filter>
                <clipPath id="clip0_4987_10203">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.96688 3.66267C9.10962 2.1196 6.89043 2.1196 6.03317 3.66267L1.91578 11.074C1.08261 12.5737 2.16704 14.4167 3.88263 14.4167H12.1174C13.833 14.4167 14.9174 12.5737 14.0843 11.074L9.96688 3.66267ZM7.4165 12.1978C7.4165 11.8757 7.67767 11.6145 7.99984 11.6145C8.322 11.6145 8.58317 11.8757 8.58317 12.1978C8.58317 12.52 8.322 12.7812 7.99984 12.7812C7.67767 12.7812 7.4165 12.52 7.4165 12.1978ZM8.5 6.5C8.5 6.22386 8.27614 6 8 6C7.72386 6 7.5 6.22386 7.5 6.5L7.5 10.2733C7.5 10.5495 7.72386 10.7733 8 10.7733C8.27614 10.7733 8.5 10.5495 8.5 10.2733L8.5 6.5Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 20:
      switch (style) {
        case 'line':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_4987_10192)">
                <path
                  d="M8.90732 4.05011C9.38358 3.19284 10.6165 3.19284 11.0927 4.05011L17.3017 15.2262C17.7645 16.0594 17.1621 17.0833 16.209 17.0833H3.79108C2.83797 17.0833 2.23551 16.0594 2.69838 15.2262L8.90732 4.05011Z"
                  stroke={color ?? neutralDay.gray90}
                  strokeWidth="1.66667"
                />
                <g filter="url(#filter0_d_4987_10192)">
                  <rect
                    x="9.16669"
                    y="13.8933"
                    width="1.66667"
                    height="1.66667"
                    rx="0.833333"
                    fill={color ?? neutralDay.gray90}
                  />
                  <rect
                    x="9.58335"
                    y="14.31"
                    width="0.833333"
                    height="0.833333"
                    rx="0.416667"
                    stroke={color ?? neutralDay.gray90}
                    strokeWidth="0.833333"
                  />
                </g>
                <path
                  d="M10 7.5L10 12.2167"
                  stroke={color ?? neutralDay.gray90}
                  strokeWidth="1.45833"
                  strokeLinecap="round"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_4987_10192"
                  x="5.83335"
                  y="13.8933"
                  width="8.33335"
                  height="8.33341"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="3.33333" />
                  <feGaussianBlur stdDeviation="1.66667" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_4987_10192"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_4987_10192"
                    result="shape"
                  />
                </filter>
                <clipPath id="clip0_4987_10192">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.0762 4.01847C11.1714 2.38967 8.82889 2.38968 7.924 4.01847L2.13994 14.4298C1.26049 16.0128 2.40516 17.9582 4.21606 17.9582H15.7842C17.5951 17.9582 18.7398 16.0128 17.8603 14.4298L12.0762 4.01847ZM9.271 14.9975C9.271 14.5948 9.59746 14.2683 10.0002 14.2683C10.4029 14.2683 10.7293 14.5948 10.7293 14.9975C10.7293 15.4002 10.4029 15.7266 10.0002 15.7266C9.59746 15.7266 9.271 15.4002 9.271 14.9975ZM10.625 7.875C10.625 7.52982 10.3452 7.25 10 7.25C9.65482 7.25 9.375 7.52982 9.375 7.875L9.375 12.5917C9.375 12.9368 9.65482 13.2167 10 13.2167C10.3452 13.2167 10.625 12.9368 10.625 12.5917L10.625 7.875Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 24:
      switch (style) {
        case 'line':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_4918_8734)">
                <path
                  d="M10.6888 4.86023C11.2603 3.83151 12.7397 3.83151 13.3112 4.86022L20.762 18.2715C21.3174 19.2713 20.5945 20.5 19.4507 20.5H4.54927C3.40554 20.5 2.68259 19.2713 3.23804 18.2715L10.6888 4.86023Z"
                  stroke={color ?? neutralDay.gray90}
                  strokeWidth="2"
                />
                <g filter="url(#filter0_d_4918_8734)">
                  <rect
                    x="11"
                    y="16.6719"
                    width="2"
                    height="2"
                    rx="1"
                    fill={color ?? neutralDay.gray90}
                  />
                  <rect
                    x="11.5"
                    y="17.1719"
                    width="1"
                    height="1"
                    rx="0.5"
                    stroke={color ?? neutralDay.gray90}
                  />
                </g>
                <path
                  d="M12 9L12 14.66"
                  stroke={color ?? neutralDay.gray90}
                  strokeWidth="1.75"
                  strokeLinecap="round"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_4918_8734"
                  x="7"
                  y="16.6719"
                  width="10"
                  height="10"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_4918_8734"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_4918_8734"
                    result="shape"
                  />
                </filter>
                <clipPath id="clip0_4918_8734">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.1854 4.37476C13.2329 2.66023 10.7671 2.66024 9.81458 4.37476L2.36386 17.7861C1.43812 19.4524 2.64304 21.5002 4.54925 21.5002H19.4507C21.3569 21.5002 22.5618 19.4524 21.6361 17.7861L14.1854 4.37476ZM11.125 17.7971C11.125 17.3139 11.5168 16.9221 12 16.9221C12.4832 16.9221 12.875 17.3139 12.875 17.7971C12.875 18.2804 12.4832 18.6721 12 18.6721C11.5168 18.6721 11.125 18.2804 11.125 17.7971ZM12.75 9.25024C12.75 8.83603 12.4142 8.50024 12 8.50024C11.5858 8.50024 11.25 8.83603 11.25 9.25024L11.25 14.9102C11.25 15.3245 11.5858 15.6602 12 15.6602C12.4142 15.6602 12.75 15.3245 12.75 14.9102L12.75 9.25024Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
  }
}
