import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function ThumbUpLine({ size, color }: LineIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="2"
            y="6.66565"
            width="1.33333"
            height="6"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <path
            d="M9.1972 2.16336L8.62103 1.68322L9.1972 2.16336ZM10.9725 11.9167H6.66667V13.4167H10.9725V11.9167ZM6.75 12V6.96547H5.25V12H6.75ZM13.25 6.66667V8.91304H14.75V6.66667H13.25ZM13.1446 9.24756L11.4503 11.6679L12.6792 12.528L14.3734 10.1078L13.1446 9.24756ZM11.3333 6.75H13.3333V5.25H11.3333V6.75ZM7.19424 5.73845L9.77336 2.6435L8.62103 1.68322L6.04191 4.77817L7.19424 5.73845ZM9.25 2.45402V4.66667H10.75V2.45402H9.25ZM9.54598 2.75C9.38252 2.75 9.25 2.61748 9.25 2.45402H10.75C10.75 1.78906 10.2109 1.25 9.54598 1.25V2.75ZM9.77336 2.6435C9.71713 2.71098 9.63382 2.75 9.54598 2.75V1.25C9.18866 1.25 8.84979 1.40872 8.62103 1.68322L9.77336 2.6435ZM11.3333 5.25C11.0112 5.25 10.75 4.98883 10.75 4.66667H9.25C9.25 5.81726 10.1827 6.75 11.3333 6.75V5.25ZM13.25 8.91304C13.25 9.03272 13.2132 9.14951 13.1446 9.24756L14.3734 10.1078C14.6185 9.75758 14.75 9.34048 14.75 8.91304H13.25ZM6.66667 11.9167C6.71269 11.9167 6.75 11.954 6.75 12H5.25C5.25 12.7824 5.88426 13.4167 6.66667 13.4167V11.9167ZM10.9725 13.4167C11.6523 13.4167 12.2893 13.085 12.6792 12.528L11.4503 11.6679C11.3412 11.8238 11.1628 11.9167 10.9725 11.9167V13.4167ZM14.75 6.66667C14.75 5.88426 14.1157 5.25 13.3333 5.25V6.75C13.2873 6.75 13.25 6.71269 13.25 6.66667H14.75ZM6.75 6.96547C6.75 6.51709 6.9072 6.0829 7.19424 5.73845L6.04191 4.77817C5.53022 5.3922 5.25 6.16618 5.25 6.96547H6.75Z"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="2.5"
            y="8.33331"
            width="1.66667"
            height="7.5"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinejoin="round"
          />
          <path
            d="M11.4965 2.7042L12.1687 3.26436L11.4965 2.7042ZM17.1987 12.0971L16.4819 11.5953L17.1987 12.0971ZM15.081 15.1224L14.3641 14.6207L15.081 15.1224ZM13.7156 14.9583H8.33333V16.7083H13.7156V14.9583ZM8.375 15V8.70683H6.625V15H8.375ZM16.625 8.33333V11.1413H18.375V8.33333H16.625ZM16.4819 11.5953L14.3641 14.6207L15.7978 15.6242L17.9155 12.5988L16.4819 11.5953ZM14.1667 8.375H16.6667V6.625H14.1667V8.375ZM8.94479 7.13305L12.1687 3.26436L10.8243 2.14404L7.6004 6.01272L8.94479 7.13305ZM11.625 3.06752V5.83333H13.375V3.06752H11.625ZM11.9325 3.375C11.7627 3.375 11.625 3.23734 11.625 3.06752H13.375C13.375 2.27084 12.7292 1.625 11.9325 1.625V3.375ZM12.1687 3.26436C12.1103 3.33447 12.0237 3.375 11.9325 3.375V1.625C11.5044 1.625 11.0984 1.81516 10.8243 2.14404L12.1687 3.26436ZM14.1667 6.625C13.7294 6.625 13.375 6.27056 13.375 5.83333H11.625C11.625 7.23706 12.7629 8.375 14.1667 8.375V6.625ZM16.625 11.1413C16.625 11.3037 16.575 11.4622 16.4819 11.5953L17.9155 12.5988C18.2146 12.1716 18.375 11.6628 18.375 11.1413H16.625ZM8.33333 14.9583C8.35634 14.9583 8.375 14.977 8.375 15H6.625C6.625 15.9435 7.38985 16.7083 8.33333 16.7083V14.9583ZM13.7156 16.7083C14.545 16.7083 15.3222 16.3037 15.7978 15.6242L14.3641 14.6207C14.216 14.8323 13.9739 14.9583 13.7156 14.9583V16.7083ZM18.375 8.33333C18.375 7.38985 17.6102 6.625 16.6667 6.625V8.375C16.6437 8.375 16.625 8.35635 16.625 8.33333H18.375ZM8.375 8.70683C8.375 8.13174 8.57662 7.57485 8.94479 7.13305L7.6004 6.01272C6.97015 6.76902 6.625 7.72235 6.625 8.70683H8.375Z"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="3"
            y="10"
            width="2"
            height="9"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinejoin="round"
          />
          <path
            d="M13.7958 3.24504L13.0276 2.60486L13.7958 3.24504ZM20.6385 14.5165L21.4577 15.0899L20.6385 14.5165ZM16.4587 18H10V20H16.4587V18ZM10 18V10.4482H8V18H10ZM20 10V13.3696H22V10H20ZM19.8192 13.943L17.2779 17.5735L18.9164 18.7204L21.4577 15.0899L19.8192 13.943ZM17 10H20V8H17V10ZM10.6953 8.52765L14.564 3.88523L13.0276 2.60486L9.15889 7.24728L10.6953 8.52765ZM14 3.68102V7H16V3.68102H14ZM14.319 4C14.1428 4 14 3.85719 14 3.68102H16C16 2.75262 15.2474 2 14.319 2V4ZM14.564 3.88523C14.5034 3.95795 14.4136 4 14.319 4V2C13.8201 2 13.347 2.2216 13.0276 2.60486L14.564 3.88523ZM17 8C16.4477 8 16 7.55228 16 7H14C14 8.65685 15.3431 10 17 10V8ZM20 13.3696C20 13.5747 19.9369 13.7749 19.8192 13.943L21.4577 15.0899C21.8107 14.5857 22 13.9851 22 13.3696H20ZM10 18H10H8C8 19.1046 8.89543 20 10 20V18ZM16.4587 20C17.4376 20 18.355 19.5224 18.9164 18.7204L17.2779 17.5735C17.0908 17.8408 16.785 18 16.4587 18V20ZM22 10C22 8.89543 21.1046 8 20 8V10H22ZM10 10.4482C10 9.74639 10.246 9.06679 10.6953 8.52765L9.15889 7.24728C8.41008 8.14585 8 9.27852 8 10.4482H10Z"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );
  }
}
