import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineFillIcon } from '../type';

export function ContentsImage({ size, style, color }: LineFillIcon) {
  switch (size) {
    case 16:
      switch (style) {
        case 'line':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="2.66562"
                y="2.66553"
                width="10.6667"
                height="10.6667"
                rx="1.33333"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinejoin="round"
              />
              <path
                d="M13.3344 9.99886L9.33438 6.66553L3.33438 12.6655"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <rect
                x="4.66562"
                y="4.66553"
                width="2"
                height="2"
                rx="1"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.66565 4.66553C5.11336 4.66553 4.66565 5.11324 4.66565 5.66553C4.66565 6.21781 5.11336 6.66553 5.66565 6.66553C6.21793 6.66553 6.66565 6.21781 6.66565 5.66553C6.66565 5.11324 6.21793 4.66553 5.66565 4.66553Z"
                fill={color ?? neutralDay.gray90}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.91565 3.99886C1.91565 2.84827 2.84839 1.91553 3.99898 1.91553H11.999C13.1496 1.91553 14.0823 2.84827 14.0823 3.99886V9.94317C14.0851 9.97994 14.0851 10.0171 14.0823 10.0544V11.9989C14.0823 13.1495 13.1496 14.0822 11.999 14.0822H3.99898C2.84839 14.0822 1.91565 13.1495 1.91565 11.9989V3.99886ZM12.5823 3.99886V8.39587L9.81451 6.08937C9.51652 5.84104 9.07833 5.86091 8.80404 6.1352L3.41565 11.5236V3.99886C3.41565 3.67669 3.67682 3.41553 3.99898 3.41553H11.999C12.3211 3.41553 12.5823 3.6767 12.5823 3.99886Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 20:
      switch (style) {
        case 'line':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="3.33334"
                y="3.33325"
                width="13.3333"
                height="13.3333"
                rx="1.66667"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
                strokeLinejoin="round"
              />
              <path
                d="M16.6667 12.4999L11.6667 8.33325L4.16666 15.8333"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <rect
                x="5.83334"
                y="5.83325"
                width="2.5"
                height="2.5"
                rx="1.25"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.08334 5.83325C6.39299 5.83325 5.83334 6.3929 5.83334 7.08325C5.83334 7.77361 6.39299 8.33325 7.08334 8.33325C7.7737 8.33325 8.33334 7.77361 8.33334 7.08325C8.33334 6.3929 7.7737 5.83325 7.08334 5.83325Z"
                fill={color ?? neutralDay.gray90}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.45834 4.99992C2.45834 3.59619 3.59629 2.45825 5.00001 2.45825H15C16.4037 2.45825 17.5417 3.5962 17.5417 4.99992V14.9999C17.5417 16.4036 16.4037 17.5416 15 17.5416H5.00001C3.59629 17.5416 2.45834 16.4036 2.45834 14.9999V4.99992ZM15.7917 4.99992V10.6318L12.2268 7.66106C11.8792 7.37135 11.368 7.39453 11.048 7.71454L4.20834 14.5542V4.99992C4.20834 4.56269 4.56278 4.20825 5.00001 4.20825H15C15.4372 4.20825 15.7917 4.56269 15.7917 4.99992Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 24:
      switch (style) {
        case 'line':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="4"
                y="4"
                width="16"
                height="16"
                rx="2"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="2"
                strokeLinejoin="round"
              />
              <path
                d="M20 15L14 10L5 19"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <rect x="7" y="7" width="3" height="3" rx="1.5" fill={color ?? neutralDay.gray90} />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.5 7C7.67157 7 7 7.67157 7 8.5C7 9.32843 7.67157 10 8.5 10C9.32843 10 10 9.32843 10 8.5C10 7.67157 9.32843 7 8.5 7Z"
                fill={color ?? neutralDay.gray90}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 6C3 4.34315 4.34315 3 6 3H18C19.6569 3 21 4.34315 21 6V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6ZM19 6V12.865L14.6402 9.23178C14.2429 8.90068 13.6586 8.92718 13.2929 9.2929L5 17.5858V6C5 5.44772 5.44772 5 6 5H18C18.5523 5 19 5.44772 19 6Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
  }
}
