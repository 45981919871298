import { type LineFillIcon } from '../type';
import { neutralDay } from '@/_global/_styles/design-system/foundation/color';

export function ArrowChevronLeftCircle({ size, style, color }: LineFillIcon) {
  switch (size) {
    case 16:
      switch (style) {
        case 'line':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="8" cy="8" r="6" stroke={color ?? neutralDay.gray90} strokeWidth="1.5" />
              <path
                d="M8.66663 10L6.66663 8L8.66663 6"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.3335 8.00004C1.3335 4.31814 4.31826 1.33337 8.00016 1.33337C11.6821 1.33337 14.6668 4.31814 14.6668 8.00004C14.6668 11.6819 11.6821 14.6667 8.00016 14.6667C4.31826 14.6667 1.3335 11.6819 1.3335 8.00004ZM8.31319 10.3536C8.50846 10.5488 8.82504 10.5488 9.0203 10.3536C9.21556 10.1583 9.21556 9.84171 9.0203 9.64645L7.37385 8L9.0203 6.35355C9.21556 6.15829 9.21556 5.84171 9.0203 5.64645C8.82504 5.45118 8.50846 5.45118 8.3132 5.64645L6.31319 7.64645C6.21943 7.74021 6.16675 7.86739 6.16675 8C6.16675 8.13261 6.21943 8.25979 6.31319 8.35355L8.31319 10.3536Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 20:
      switch (style) {
        case 'line':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="10"
                cy="10"
                r="7.5"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
              />
              <path
                d="M10.8334 12.5L8.33337 10L10.8334 7.5"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.66663 9.99996C1.66663 5.39759 5.39759 1.66663 9.99996 1.66663C14.6023 1.66663 18.3333 5.39759 18.3333 9.99996C18.3333 14.6023 14.6023 18.3333 9.99996 18.3333C5.39759 18.3333 1.66663 14.6023 1.66663 9.99996ZM10.3914 12.9419C10.6355 13.186 11.0312 13.186 11.2753 12.9419C11.5194 12.6979 11.5194 12.3021 11.2753 12.0581L9.21726 10L11.2753 7.94194C11.5194 7.69786 11.5194 7.30214 11.2753 7.05806C11.0312 6.81398 10.6355 6.81398 10.3914 7.05806L7.89143 9.55806C7.77422 9.67527 7.70837 9.83424 7.70837 10C7.70837 10.1658 7.77422 10.3247 7.89143 10.4419L10.3914 12.9419Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 24:
      switch (style) {
        case 'line':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="12" cy="12" r="9" stroke={color ?? neutralDay.gray90} strokeWidth="2" />
              <path
                d="M13 15L10 12L13 9"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12.4697 15.5303C12.7626 15.8232 13.2374 15.8232 13.5303 15.5303C13.8232 15.2374 13.8232 14.7626 13.5303 14.4697L11.0607 12L13.5303 9.53033C13.8232 9.23744 13.8232 8.76256 13.5303 8.46967C13.2374 8.17678 12.7626 8.17678 12.4697 8.46967L9.46967 11.4697C9.32902 11.6103 9.25 11.8011 9.25 12C9.25 12.1989 9.32902 12.3897 9.46967 12.5303L12.4697 15.5303Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
  }
}
