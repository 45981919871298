import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineFillIcon } from '../type';

export function InfoPlayCircle({ size, style, color }: LineFillIcon) {
  switch (size) {
    case 16:
      switch (style) {
        case 'line':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="8" cy="8" r="6" stroke={color ?? neutralDay.gray90} strokeWidth="1.5" />
              <path
                d="M5.33337 8.48493L7.00004 10.0001L10.6667 6.66675"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.25 8C1.25 4.27208 4.27208 1.25 8 1.25C11.7279 1.25 14.75 4.27208 14.75 8C14.75 11.7279 11.7279 14.75 8 14.75C4.27208 14.75 1.25 11.7279 1.25 8ZM11.0029 6.9033C11.2073 6.71755 11.2223 6.40132 11.0366 6.19699C10.8508 5.99266 10.5346 5.97761 10.3303 6.16336L6.99992 9.19093L5.66959 7.98154C5.46526 7.79579 5.14904 7.81085 4.96329 8.01517C4.77753 8.2195 4.79259 8.53573 4.99692 8.72148L6.66359 10.2366C6.8543 10.41 7.14555 10.41 7.33626 10.2366L11.0029 6.9033Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 20:
      switch (style) {
        case 'line':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="10"
                cy="10"
                r="7.5"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
              />
              <path
                d="M6.66663 10.606L8.74996 12.4999L13.3333 8.33325"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.625 10C1.625 5.37462 5.37462 1.625 10 1.625C14.6254 1.625 18.375 5.37462 18.375 10C18.375 14.6254 14.6254 18.375 10 18.375C5.37462 18.375 1.625 14.6254 1.625 10ZM13.7538 8.62909C14.0092 8.3969 14.0281 8.00162 13.7959 7.74621C13.5637 7.4908 13.1684 7.47198 12.913 7.70417L8.75009 11.4886L7.08717 9.97689C6.83176 9.7447 6.43648 9.76353 6.20429 10.0189C5.9721 10.2743 5.99092 10.6696 6.24633 10.9018L8.32967 12.7958C8.56805 13.0125 8.93212 13.0125 9.17051 12.7958L13.7538 8.62909Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 24:
      switch (style) {
        case 'line':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="12" cy="12" r="9" stroke={color ?? neutralDay.gray90} strokeWidth="2" />
              <path
                d="M8 12.7273L10.5 15L16 10"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM16.5045 10.355C16.811 10.0764 16.8336 9.60204 16.555 9.29555C16.2763 8.98906 15.802 8.96647 15.4955 9.2451L10.5 13.7865L8.50451 11.9724C8.19802 11.6937 7.72368 11.7163 7.44505 12.0228C7.16642 12.3293 7.18901 12.8037 7.4955 13.0823L9.9955 15.355C10.2816 15.6151 10.7184 15.6151 11.0045 15.355L16.5045 10.355Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
  }
}
