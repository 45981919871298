import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineOnlyIcon } from '../type';

export function ActionReset({ size, color }: LineOnlyIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.2999 11.2998C9.47742 13.1223 6.52265 13.1223 4.7002 11.2998C2.87776 9.4774 2.87776 6.52263 4.70021 4.70019C6.52265 2.87774 9.47742 2.87774 11.2999 4.70018C11.2999 4.70018 12 5.33335 12.6667 6.66667"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M12.6667 4L12.6667 6.66667L10.0001 6.66667"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.1248 14.1248C11.8468 16.4029 8.15332 16.4029 5.87526 14.1248C3.5972 11.8468 3.5972 8.15329 5.87526 5.87523C8.15332 3.59717 11.8468 3.59717 14.1248 5.87523C14.1248 5.87523 15.0001 6.66669 15.8334 8.33334"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <path
            d="M15.8333 5L15.8333 8.33333L12.4999 8.33333"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.9498 16.9498C14.2161 19.6834 9.78398 19.6834 7.05031 16.9498C4.31664 14.2161 4.31664 9.78395 7.05031 7.05028C9.78398 4.31661 14.2161 4.31661 16.9498 7.05028C16.9498 7.05028 18.0001 8.00003 19.0001 10"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M19 6L19 10L15 10"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
}
