import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function ArrowLeftLine({ size, color }: LineIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.46967 13.8647C9.76256 14.1576 10.2374 14.1576 10.5303 13.8647C10.8232 13.5718 10.8232 13.0969 10.5303 12.804L9.46967 13.8647ZM4.66667 8.00102L4.13634 7.47069C3.84344 7.76358 3.84344 8.23845 4.13634 8.53135L4.66667 8.00102ZM10.5303 3.19801C10.8232 2.90512 10.8232 2.43025 10.5303 2.13735C10.2374 1.84446 9.76256 1.84446 9.46967 2.13735L10.5303 3.19801ZM10.5303 12.804L5.197 7.47069L4.13634 8.53135L9.46967 13.8647L10.5303 12.804ZM5.197 8.53135L10.5303 3.19801L9.46967 2.13735L4.13634 7.47069L5.197 8.53135Z"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.8813 17.2854C12.223 17.6271 12.777 17.6271 13.1187 17.2854C13.4604 16.9437 13.4604 16.3897 13.1187 16.048L11.8813 17.2854ZM5.83333 10L5.21462 9.3813C4.87291 9.72301 4.87291 10.277 5.21462 10.6187L5.83333 10ZM13.1187 3.95207C13.4604 3.61036 13.4604 3.05634 13.1187 2.71464C12.777 2.37293 12.223 2.37293 11.8813 2.71464L13.1187 3.95207ZM13.1187 16.048L6.45205 9.3813L5.21462 10.6187L11.8813 17.2854L13.1187 16.048ZM6.45205 10.6187L13.1187 3.95207L11.8813 2.71464L5.21462 9.3813L6.45205 10.6187Z"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.2929 20.7071C14.6834 21.0976 15.3166 21.0976 15.7071 20.7071C16.0976 20.3166 16.0976 19.6834 15.7071 19.2929L14.2929 20.7071ZM7 12L6.29289 11.2929C5.90237 11.6834 5.90237 12.3166 6.29289 12.7071L7 12ZM15.7071 4.70711C16.0976 4.31658 16.0976 3.68342 15.7071 3.29289C15.3166 2.90237 14.6834 2.90237 14.2929 3.29289L15.7071 4.70711ZM15.7071 19.2929L7.70711 11.2929L6.29289 12.7071L14.2929 20.7071L15.7071 19.2929ZM7.70711 12.7071L15.7071 4.70711L14.2929 3.29289L6.29289 11.2929L7.70711 12.7071Z"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );
  }
}
