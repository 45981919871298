import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function ArrowRightSmallLine({ size, color }: LineIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.13532 11.4697C5.84243 11.7626 5.84243 12.2374 6.13532 12.5303C6.42821 12.8232 6.90309 12.8232 7.19598 12.5303L6.13532 11.4697ZM10.6656 8L11.196 8.53033C11.4889 8.23744 11.4889 7.76256 11.196 7.46967L10.6656 8ZM7.19598 3.46967C6.90309 3.17678 6.42821 3.17678 6.13532 3.46967C5.84243 3.76256 5.84243 4.23744 6.13532 4.53033L7.19598 3.46967ZM7.19598 12.5303L11.196 8.53033L10.1353 7.46967L6.13532 11.4697L7.19598 12.5303ZM11.196 7.46967L7.19598 3.46967L6.13532 4.53033L10.1353 8.53033L11.196 7.46967Z"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.71466 14.3813C7.37295 14.723 7.37295 15.277 7.71466 15.6187C8.05636 15.9604 8.61038 15.9604 8.95209 15.6187L7.71466 14.3813ZM13.3334 10L13.9521 10.6187C14.2938 10.277 14.2938 9.72299 13.9521 9.38128L13.3334 10ZM8.95209 4.38128C8.61038 4.03957 8.05636 4.03957 7.71466 4.38128C7.37295 4.72299 7.37295 5.27701 7.71466 5.61872L8.95209 4.38128ZM8.95209 15.6187L13.9521 10.6187L12.7147 9.38128L7.71466 14.3813L8.95209 15.6187ZM13.9521 9.38128L8.95209 4.38128L7.71466 5.61872L12.7147 10.6187L13.9521 9.38128Z"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.29289 17.2929C8.90237 17.6834 8.90237 18.3166 9.29289 18.7071C9.68342 19.0976 10.3166 19.0976 10.7071 18.7071L9.29289 17.2929ZM16 12L16.7071 12.7071C17.0976 12.3166 17.0976 11.6834 16.7071 11.2929L16 12ZM10.7071 5.29289C10.3166 4.90237 9.68342 4.90237 9.29289 5.29289C8.90237 5.68342 8.90237 6.31658 9.29289 6.70711L10.7071 5.29289ZM10.7071 18.7071L16.7071 12.7071L15.2929 11.2929L9.29289 17.2929L10.7071 18.7071ZM16.7071 11.2929L10.7071 5.29289L9.29289 6.70711L15.2929 12.7071L16.7071 11.2929Z"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );
  }
}
