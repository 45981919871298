import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineFillIcon } from '../type';

export function ContentsCopy({ size, style, color }: LineFillIcon) {
  switch (size) {
    case 16:
      switch (style) {
        case 'line':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.33125 3.13V3.13C5.33125 2.50592 5.83717 2 6.46125 2H11.9979C12.7343 2 13.3313 2.59695 13.3313 3.33333V10.2052C13.3313 10.8283 12.8262 11.3333 12.2031 11.3333V11.3333"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <rect
                x="2.66875"
                y="4.66553"
                width="8"
                height="9.33333"
                rx="1.33333"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.46124 1.25C5.42294 1.25 4.58124 2.0917 4.58124 3.13H6.08124C6.08124 2.92013 6.25137 2.75 6.46124 2.75H11.9979C12.3201 2.75 12.5812 3.01117 12.5812 3.33333V10.2052C12.5812 10.414 12.4119 10.5833 12.2031 10.5833V12.0833C13.2404 12.0833 14.0812 11.2425 14.0812 10.2052V3.33333C14.0812 2.18274 13.1485 1.25 11.9979 1.25H6.46124Z"
                fill={color ?? neutralDay.gray90}
              />
              <path
                d="M1.91876 5.99886C1.91876 4.84827 2.8515 3.91553 4.0021 3.91553H9.33543C10.486 3.91553 11.4188 4.84827 11.4188 5.99886V12.6655C11.4188 13.8161 10.486 14.7489 9.33543 14.7489H4.0021C2.8515 14.7489 1.91876 13.8161 1.91876 12.6655V5.99886Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 20:
      switch (style) {
        case 'line':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.66667 4.16667V4.16667C6.66667 3.24619 7.41286 2.5 8.33334 2.5H15C15.9205 2.5 16.6667 3.24619 16.6667 4.16667V12.5C16.6667 13.4205 15.9205 14.1667 15 14.1667V14.1667"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
                strokeLinecap="round"
              />
              <rect
                x="3.33333"
                y="5.83325"
                width="10"
                height="11.6667"
                rx="1.66667"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.33335 1.625C6.92963 1.625 5.79169 2.76294 5.79169 4.16667H7.54169C7.54169 3.72944 7.89613 3.375 8.33335 3.375H15C15.4372 3.375 15.7917 3.72944 15.7917 4.16667V12.5C15.7917 12.9372 15.4372 13.2917 15 13.2917V15.0417C16.4037 15.0417 17.5417 13.9037 17.5417 12.5V4.16667C17.5417 2.76294 16.4037 1.625 15 1.625H8.33335Z"
                fill={color ?? neutralDay.gray90}
              />
              <path
                d="M2.45831 7.49992C2.45831 6.0962 3.59625 4.95825 4.99998 4.95825H11.6666C13.0704 4.95825 14.2083 6.09619 14.2083 7.49992V15.8332C14.2083 17.237 13.0704 18.3749 11.6666 18.3749H4.99998C3.59626 18.3749 2.45831 17.237 2.45831 15.8333V7.49992Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 24:
      switch (style) {
        case 'line':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 6V5C8 3.89543 8.89543 3 10 3H18C19.1046 3 20 3.89543 20 5V15C20 16.1046 19.1046 17 18 17H17"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="2"
              />
              <rect
                x="4"
                y="7"
                width="12"
                height="14"
                rx="2"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="2"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 3.5C7 2.67157 7.67157 2 8.5 2H18C19.6569 2 21 3.34315 21 5V16.5C21 17.3284 20.3284 18 19.5 18H18.5V8.5C18.5 6.29086 16.7091 4.5 14.5 4.5H7V3.5Z"
                fill={color ?? neutralDay.gray90}
              />
              <path
                d="M3 9C3 7.34315 4.34315 6 6 6H14C15.6569 6 17 7.34315 17 9V19C17 20.6569 15.6569 22 14 22H6C4.34315 22 3 20.6569 3 19V9Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
  }
}
