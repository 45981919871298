import { type LineOnlyIcon } from '../type';
import { neutralDay } from '@/_global/_styles/design-system/foundation/color';

export function ActionAccountAdd({ size, color }: LineOnlyIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="7.99996"
            cy="5.33333"
            r="2.58333"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
          />
          <path
            d="M2.80469 13.3319C3.73653 11.7211 5.3958 10.5836 7.33333 10.3688"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M12 9.39966V14.5997"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.2"
            strokeLinecap="round"
          />
          <path
            d="M14.6 12L9.39998 12"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.2"
            strokeLinecap="round"
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="10"
            cy="6.66667"
            r="3.29167"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
          />
          <path
            d="M3.50586 16.665C4.67067 14.6514 6.74475 13.2296 9.16667 12.9611"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <path
            d="M15 11.7496V18.2496"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M18.25 15L11.75 15"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="8" r="4" stroke={color ?? neutralDay.gray90} strokeWidth="2" />
          <path
            d="M4.20703 19.9981C5.6048 17.5818 8.0937 15.8756 11 15.5533"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M18 14.0999V21.8999"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <path
            d="M21.9 18L14.1 18"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
        </svg>
      );
  }
}
