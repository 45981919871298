import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineOnlyIcon } from '../type';

export function ActionDownload({ size, color }: LineOnlyIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.3335 8L8.00016 10.6667L10.6668 8"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 10.6667V2.66675"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M13.3332 11.3333V12.4999C13.3332 12.9602 12.9601 13.3333 12.4998 13.3333H3.49984C3.0396 13.3333 2.6665 12.9602 2.6665 12.4999V11.3333"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.66675 10L10.0001 13.3333L13.3334 10"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 13.3335V3.3335"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <path
            d="M16.6666 14.1665V15.8332C16.6666 16.2934 16.2935 16.6665 15.8333 16.6665H4.16659C3.70635 16.6665 3.33325 16.2934 3.33325 15.8332V14.1665"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 12L12 16L16 12"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 16V4"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M20 17V19C20 19.5523 19.5523 20 19 20H5C4.44772 20 4 19.5523 4 19V17"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      );
  }
}
