import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function ArrowLeftSmallLine({ size, color }: LineIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.86468 4.53033C10.1576 4.23744 10.1576 3.76256 9.86468 3.46967C9.57179 3.17678 9.09691 3.17678 8.80402 3.46967L9.86468 4.53033ZM5.33435 8L4.80402 7.46967C4.51113 7.76256 4.51113 8.23744 4.80402 8.53033L5.33435 8ZM8.80402 12.5303C9.09691 12.8232 9.57179 12.8232 9.86468 12.5303C10.1576 12.2374 10.1576 11.7626 9.86468 11.4697L8.80402 12.5303ZM8.80402 3.46967L4.80402 7.46967L5.86468 8.53033L9.86468 4.53033L8.80402 3.46967ZM4.80402 8.53033L8.80402 12.5303L9.86468 11.4697L5.86468 7.46967L4.80402 8.53033Z"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.2853 5.61872C12.6271 5.27701 12.6271 4.72299 12.2853 4.38128C11.9436 4.03957 11.3896 4.03957 11.0479 4.38128L12.2853 5.61872ZM6.66663 10L6.04791 9.38128C5.7062 9.72299 5.7062 10.277 6.04791 10.6187L6.66663 10ZM11.0479 15.6187C11.3896 15.9604 11.9436 15.9604 12.2853 15.6187C12.6271 15.277 12.6271 14.723 12.2853 14.3813L11.0479 15.6187ZM11.0479 4.38128L6.04791 9.38128L7.28534 10.6187L12.2853 5.61872L11.0479 4.38128ZM6.04791 10.6187L11.0479 15.6187L12.2853 14.3813L7.28534 9.38128L6.04791 10.6187Z"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.7071 6.70711C15.0976 6.31658 15.0976 5.68342 14.7071 5.29289C14.3166 4.90237 13.6834 4.90237 13.2929 5.29289L14.7071 6.70711ZM8 12L7.29289 11.2929C6.90237 11.6834 6.90237 12.3166 7.29289 12.7071L8 12ZM13.2929 18.7071C13.6834 19.0976 14.3166 19.0976 14.7071 18.7071C15.0976 18.3166 15.0976 17.6834 14.7071 17.2929L13.2929 18.7071ZM13.2929 5.29289L7.29289 11.2929L8.70711 12.7071L14.7071 6.70711L13.2929 5.29289ZM7.29289 12.7071L13.2929 18.7071L14.7071 17.2929L8.70711 11.2929L7.29289 12.7071Z"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );
  }
}
