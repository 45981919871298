import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function VerifiedLine({ size, color }: LineIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.8615 7.6122L12.8152 6.36655L12.9615 4.71547C12.9896 4.42168 12.7984 4.1514 12.5284 4.08676L10.9759 3.71659L10.1659 2.28879C10.0197 2.03613 9.72156 1.93037 9.46282 2.04788L8.00035 2.71184L6.5435 2.05963C6.28476 1.94212 5.98101 2.04788 5.84039 2.30054L5.02478 3.72247L3.47794 4.08676C3.20232 4.1514 3.01107 4.4158 3.0392 4.71547L3.18544 6.36067L2.13922 7.6122C1.95359 7.83548 1.95359 8.16452 2.13922 8.3878L3.18544 9.63345L3.0392 11.2904C3.01107 11.5842 3.20232 11.8545 3.47231 11.9191L5.02478 12.2893L5.83477 13.7112C5.98101 13.9639 6.27913 14.0696 6.5435 13.9521L8.00035 13.2882L9.45719 13.9404C9.71594 14.0579 10.0197 13.9521 10.1603 13.6995L10.9703 12.2717L12.5228 11.9015C12.7984 11.8369 12.984 11.5666 12.9559 11.2728L12.8096 9.6217L13.8559 8.37605C14.0471 8.16452 14.0471 7.83548 13.8615 7.6122Z"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.86664 8.19396L7.2208 9.46669L10.2 6.66669"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.3268 9.51525L16.0191 7.95818L16.2019 5.89433C16.237 5.5271 15.998 5.18924 15.6605 5.10845L13.7199 4.64574L12.7074 2.86098C12.5246 2.54516 12.152 2.41296 11.8285 2.55985L10.0004 3.3898L8.17938 2.57454C7.85595 2.42765 7.47627 2.55985 7.30049 2.87567L6.28098 4.65308L4.34742 5.10845C4.0029 5.18924 3.76384 5.51975 3.799 5.89433L3.98181 7.95084L2.67402 9.51525C2.44199 9.79435 2.44199 10.2057 2.67402 10.4847L3.98181 12.0418L3.799 14.113C3.76384 14.4802 4.0029 14.8181 4.34039 14.8989L6.28098 15.3616L7.29346 17.139C7.47627 17.4548 7.84892 17.587 8.17938 17.4401L10.0004 16.6102L11.8215 17.4255C12.1449 17.5724 12.5246 17.4401 12.7004 17.1243L13.7129 15.3396L15.6534 14.8769C15.998 14.7961 16.23 14.4582 16.1948 14.091L16.012 12.0271L17.3198 10.4701C17.5589 10.2057 17.5589 9.79435 17.3268 9.51525Z"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.33331 10.2424L9.02602 11.8333L12.75 8.33331"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.7922 11.4183L19.2229 9.54982L19.4422 7.0732C19.4844 6.63252 19.1976 6.22709 18.7926 6.13014L16.4639 5.57489L15.2489 3.43318C15.0295 3.0542 14.5823 2.89555 14.1942 3.07182L12.0005 4.06776L9.81525 3.08945C9.42714 2.91318 8.97152 3.07182 8.76059 3.45081L7.53717 5.5837L5.21691 6.13014C4.80348 6.22709 4.51661 6.6237 4.5588 7.0732L4.77817 9.54101L3.20882 11.4183C2.93039 11.7532 2.93039 12.2468 3.20882 12.5817L4.77817 14.4502L4.5588 16.9356C4.51661 17.3763 4.80348 17.7817 5.20847 17.8787L7.53717 18.4339L8.75215 20.5668C8.97152 20.9458 9.4187 21.1044 9.81525 20.9282L12.0005 19.9322L14.1858 20.9106C14.5739 21.0868 15.0295 20.9282 15.2405 20.5492L16.4554 18.4075L18.7841 17.8522C19.1976 17.7553 19.476 17.3499 19.4338 16.9092L19.2144 14.4326L20.7838 12.5641C21.0707 12.2468 21.0707 11.7532 20.7922 11.4183Z"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.79999 12.2909L10.8312 14.2L15.3 10"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
}
