import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineOnlyIcon } from '../type';

export function NavigationIndex({ size, color }: LineOnlyIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.66666 4H13.3333"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M6.66666 8H13.3333"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M6.66666 12H13.3333"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <circle cx="3.41663" cy="4" r="0.75" fill={color ?? neutralDay.gray90} />
          <circle cx="3.41663" cy="8" r="0.75" fill={color ?? neutralDay.gray90} />
          <circle cx="3.41663" cy="12" r="0.75" fill={color ?? neutralDay.gray90} />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.33334 5H16.6667"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <path
            d="M8.33334 10H16.6667"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <path
            d="M8.33334 15H16.6667"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <circle cx="4.20825" cy="5" r="0.875" fill={color ?? neutralDay.gray90} />
          <circle cx="4.20825" cy="10" r="0.875" fill={color ?? neutralDay.gray90} />
          <circle cx="4.20825" cy="15" r="0.875" fill={color ?? neutralDay.gray90} />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 6H20"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M10 12H20"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M10 18H20"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
            strokeLinecap="round"
          />
          <circle cx="4.99985" cy="5.99976" r="1" fill={color ?? neutralDay.gray90} />
          <circle cx="4.99985" cy="11.9998" r="1" fill={color ?? neutralDay.gray90} />
          <circle cx="4.99985" cy="17.9998" r="1" fill={color ?? neutralDay.gray90} />
        </svg>
      );
  }
}
