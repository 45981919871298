import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineFillIcon } from '../type';

export function InfoCalendar({ size, style, color }: LineFillIcon) {
  switch (size) {
    case 16:
      switch (style) {
        case 'line':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="2.66663"
                y="3.33325"
                width="10.6667"
                height="10.6667"
                rx="1.33333"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
              />
              <path
                d="M2.66663 6.66675L13.3333 6.66675"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M5.33337 2V3.33333"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M10.6666 2V3.33333"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.0835 2C6.0835 1.58579 5.74771 1.25 5.3335 1.25C4.91928 1.25 4.5835 1.58579 4.5835 2V2.58325H3.99984C2.84924 2.58325 1.9165 3.51599 1.9165 4.66659V5.91675L14.0832 5.91675V4.66659C14.0832 3.51599 13.1504 2.58325 11.9998 2.58325H11.4165V2C11.4165 1.58579 11.0807 1.25 10.6665 1.25C10.2523 1.25 9.9165 1.58579 9.9165 2V2.58325H6.0835V2Z"
                fill={color ?? neutralDay.gray90}
              />
              <path
                d="M14.0832 7.41675L1.9165 7.41675V12.6666C1.9165 13.8172 2.84925 14.7499 3.99984 14.7499H11.9998C13.1504 14.7499 14.0832 13.8172 14.0832 12.6666V7.41675Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 20:
      switch (style) {
        case 'line':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="3.33337"
                y="4.16675"
                width="13.3333"
                height="13.3333"
                rx="1.66667"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.66667"
                strokeLinejoin="round"
              />
              <path
                d="M3.33337 8.33325L16.6667 8.33325"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.66667"
                strokeLinecap="round"
              />
              <path
                d="M6.66663 2.5V4.16667"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.66667"
                strokeLinecap="round"
              />
              <path
                d="M13.3334 2.5V4.16667"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.66667"
                strokeLinecap="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.49992 2.50008C7.49992 2.03984 7.12682 1.66675 6.66659 1.66675C6.20635 1.66675 5.83325 2.03984 5.83325 2.50008V3.33325H5C3.61929 3.33325 2.5 4.45254 2.5 5.83325V7.50008L17.5 7.50008V5.83325C17.5 4.45254 16.3807 3.33325 15 3.33325H14.1667V2.50008C14.1667 2.03984 13.7936 1.66675 13.3333 1.66675C12.8731 1.66675 12.5 2.03984 12.5 2.50008V3.33325H7.49992V2.50008Z"
                fill={color ?? neutralDay.gray90}
              />
              <path
                d="M17.5 9.16675L2.5 9.16675V15.8333C2.5 17.214 3.61929 18.3333 5 18.3333H15C16.3807 18.3333 17.5 17.214 17.5 15.8333V9.16675Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 24:
      switch (style) {
        case 'line':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="4"
                y="5"
                width="16"
                height="16"
                rx="2"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="2"
                strokeLinejoin="round"
              />
              <path
                d="M4 10L20 10"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M8 3V5"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M16 3V5"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 3C9 2.44772 8.55229 2 8 2C7.44772 2 7 2.44772 7 3V4H6C4.34315 4 3 5.34315 3 7V9L21 9V7C21 5.34315 19.6569 4 18 4H17V3C17 2.44772 16.5523 2 16 2C15.4477 2 15 2.44772 15 3V4H9V3Z"
                fill={color ?? neutralDay.gray90}
              />
              <path
                d="M21 11L3 11V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V11Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
  }
}
