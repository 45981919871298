import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type FillIcon } from '../type';

export function BookmarkFill({ size, primaryColor }: FillIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.33435 3.33333C3.33435 2.59695 3.9313 2 4.66768 2H11.3344C12.0707 2 12.6677 2.59695 12.6677 3.33333V14L8.00102 11L3.33435 14V3.33333Z"
            fill={primaryColor ?? neutralDay.gray100}
            stroke={primaryColor ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.16663 4.16667C4.16663 3.24619 4.91282 2.5 5.83329 2.5H14.1666C15.0871 2.5 15.8333 3.24619 15.8333 4.16667V17.5L9.99996 13.75L4.16663 17.5V4.16667Z"
            fill={primaryColor ?? neutralDay.gray100}
            stroke={primaryColor ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 5C5 3.89543 5.89543 3 7 3H17C18.1046 3 19 3.89543 19 5V21L12 16.5L5 21V5Z"
            fill={primaryColor ?? neutralDay.gray100}
            stroke={primaryColor ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
}
