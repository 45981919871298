import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineOnlyIcon } from '../type';

export function ActionVideoSetting({ size, color }: LineOnlyIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.8696 8C10.6381 8 10.4369 8.15896 10.3832 8.38419L10.2831 8.80477L9.66847 9.15962L9.25422 9.03607C9.03235 8.9699 8.79407 9.0647 8.6783 9.26522L8.21471 10.0682C8.09894 10.2687 8.13598 10.5225 8.30422 10.6815L8.61837 10.9785V11.6883L8.30428 11.9852C8.13603 12.1443 8.09899 12.398 8.21476 12.5985L8.67835 13.4015C8.79412 13.602 9.0324 13.6968 9.25427 13.6307L9.66853 13.5071L10.2831 13.862L10.3833 14.2825C10.4369 14.5077 10.6381 14.6667 10.8697 14.6667H11.7969C12.0284 14.6667 12.2296 14.5077 12.2833 14.2825L12.3834 13.862L12.9981 13.5071L13.4123 13.6306C13.6342 13.6968 13.8725 13.602 13.9882 13.4015L14.4518 12.5985C14.5676 12.398 14.5306 12.1442 14.3623 11.9852L14.0482 11.6882V10.9785L14.3623 10.6815C14.5306 10.5224 14.5676 10.2687 14.4518 10.0681L13.9882 9.26518C13.8725 9.06466 13.6342 8.96986 13.4123 9.03603L12.998 9.1596L12.3833 8.80471L12.2832 8.38419C12.2296 8.15896 12.0284 8 11.7968 8H10.8696ZM11.3333 10.3333C10.781 10.3333 10.3333 10.781 10.3333 11.3333C10.3333 11.8856 10.781 12.3333 11.3333 12.3333C11.8856 12.3333 12.3333 11.8856 12.3333 11.3333C12.3333 10.781 11.8856 10.3333 11.3333 10.3333Z"
            fill={color ?? neutralDay.gray90}
          />
          <path
            d="M4 13.3334H6.66667"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M6.66663 10.6666H3.99996C3.26358 10.6666 2.66663 10.0697 2.66663 9.33329V3.99996C2.66663 3.26358 3.26358 2.66663 3.99996 2.66663H12C12.7363 2.66663 13.3333 3.26358 13.3333 3.99996V7.33329"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M7 5.60142V7.73192C7 7.99373 7.28798 8.15334 7.51 8.01458L9.2144 6.94933C9.42329 6.81878 9.42329 6.51456 9.2144 6.384L7.51 5.31875C7.28798 5.17999 7 5.3396 7 5.60142Z"
            fill={color ?? neutralDay.gray90}
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.5871 10C13.2977 10 13.0461 10.1987 12.9791 10.4802L12.8539 11.006L12.0856 11.4495L11.5678 11.2951C11.2905 11.2124 10.9926 11.3309 10.8479 11.5815L10.2684 12.5852C10.1237 12.8359 10.17 13.1531 10.3803 13.3519L10.773 13.7231V14.6103L10.3804 14.9815C10.1701 15.1803 10.1238 15.4975 10.2685 15.7482L10.848 16.7519C10.9927 17.0025 11.2906 17.121 11.5679 17.0383L12.0857 16.8839L12.854 17.3274L12.9792 17.8531C13.0462 18.1346 13.2977 18.3333 13.5872 18.3333H14.7461C15.0356 18.3333 15.2871 18.1346 15.3541 17.8531L15.4793 17.3275L16.2477 16.8838L16.7655 17.0383C17.0428 17.121 17.3406 17.0025 17.4854 16.7518L18.0648 15.7481C18.2095 15.4975 18.1633 15.1803 17.9529 14.9815L17.5603 14.6102V13.7231L17.953 13.3518C18.1633 13.153 18.2096 12.8358 18.0649 12.5852L17.4854 11.5815C17.3407 11.3308 17.0428 11.2123 16.7655 11.295L16.2476 11.4495L15.4792 11.0059L15.3541 10.4802C15.2871 10.1987 15.0355 10 14.7461 10H13.5871ZM14.1667 12.9167C13.4764 12.9167 12.9167 13.4763 12.9167 14.1667C12.9167 14.857 13.4764 15.4167 14.1667 15.4167C14.8571 15.4167 15.4167 14.857 15.4167 14.1667C15.4167 13.4763 14.8571 12.9167 14.1667 12.9167Z"
            fill={color ?? neutralDay.gray90}
          />
          <path
            d="M5 16.6666H8.33333"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <path
            d="M8.33337 13.3334H5.33337C4.2288 13.3334 3.33337 12.4379 3.33337 11.3334V5.33337C3.33337 4.2288 4.2288 3.33337 5.33337 3.33337H14.6667C15.7713 3.33337 16.6667 4.2288 16.6667 5.33337V9.16671"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <path
            d="M8.75 7.15212V9.51454C8.75 9.90726 9.18198 10.1467 9.515 9.93854L11.4049 8.75733C11.7183 8.5615 11.7183 8.10517 11.4049 7.90933L9.515 6.72812C9.18198 6.51998 8.75 6.75941 8.75 7.15212Z"
            fill={color ?? neutralDay.gray90}
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.3046 12C15.9573 12 15.6554 12.2384 15.575 12.5763L15.4248 13.2072L14.5028 13.7394L13.8815 13.5541C13.5486 13.4548 13.1912 13.5971 13.0176 13.8978L12.3222 15.1023C12.1485 15.403 12.2041 15.7837 12.4565 16.0223L12.9277 16.4678V17.5324L12.4565 17.9778C12.2042 18.2164 12.1486 18.5971 12.3223 18.8978L13.0177 20.1023C13.1913 20.403 13.5487 20.5453 13.8815 20.446L14.5029 20.2607L15.4248 20.7929L15.575 21.4237C15.6555 21.7616 15.9573 22 16.3046 22H17.6954C18.0427 22 18.3446 21.7616 18.425 21.4237L18.5752 20.7929L19.4972 20.2606L20.1186 20.4459C20.4514 20.5452 20.8088 20.403 20.9825 20.1022L21.6779 18.8978C21.8515 18.597 21.796 18.2164 21.5436 17.9778L21.0724 17.5323V16.4677L21.5436 16.0222C21.796 15.7836 21.8515 15.403 21.6779 15.1022L20.9825 13.8978C20.8088 13.597 20.4514 13.4548 20.1186 13.554L19.4972 13.7394L18.5751 13.2071L18.425 12.5763C18.3445 12.2384 18.0427 12 17.6954 12H16.3046ZM17.0001 15.5C16.1717 15.5 15.5001 16.1716 15.5001 17C15.5001 17.8284 16.1717 18.5 17.0001 18.5C17.8285 18.5 18.5001 17.8284 18.5001 17C18.5001 16.1716 17.8285 15.5 17.0001 15.5Z"
            fill={color ?? neutralDay.gray90}
          />
          <path
            d="M6 20H10"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M10 16H6C4.89543 16 4 15.1046 4 14V6C4 4.89543 4.89543 4 6 4H18C19.1046 4 20 4.89543 20 6V11"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M10.5 8.40212V11.5979C10.5 11.9906 10.932 12.23 11.265 12.0219L13.8216 10.424C14.1349 10.2282 14.1349 9.77183 13.8216 9.576L11.265 7.97812C10.932 7.76998 10.5 8.00941 10.5 8.40212Z"
            fill={color ?? neutralDay.gray90}
          />
        </svg>
      );
  }
}
