import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function EyeLine({ size, color }: LineIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.6638 7.99711L15.3116 8.37501L15.5321 7.99711L15.3116 7.61921L14.6638 7.99711ZM1.33044 7.99711L0.68261 7.61921L0.462167 7.99711L0.68261 8.37501L1.33044 7.99711ZM14.0159 7.61921C12.7105 9.85709 10.9045 11.9138 7.99711 11.9138V13.4138C11.7564 13.4138 13.9504 10.7086 15.3116 8.37501L14.0159 7.61921ZM7.99711 11.9138C5.08973 11.9138 3.28371 9.85709 1.97828 7.61921L0.68261 8.37501C2.04385 10.7086 4.23783 13.4138 7.99711 13.4138V11.9138ZM1.97828 8.37501C3.28371 6.13714 5.08973 4.08044 7.99711 4.08044V2.58044C4.23783 2.58044 2.04385 5.28566 0.68261 7.61921L1.97828 8.37501ZM7.99711 4.08044C10.9045 4.08044 12.7105 6.13714 14.0159 8.37501L15.3116 7.61921C13.9504 5.28566 11.7564 2.58044 7.99711 2.58044V4.08044Z"
            fill={color ?? neutralDay.gray100}
          />
          <circle cx="7.99813" cy="7.99813" r="2.33333" fill={color ?? neutralDay.gray100} />
          <circle cx="7.99711" cy="7.99711" r="1.66667" fill={color ?? neutralDay.gray100} />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.3321 9.99868L19.0879 10.4396L19.3451 9.99868L19.0879 9.55779L18.3321 9.99868ZM1.66541 9.99868L0.909599 9.55779L0.652415 9.99868L0.909599 10.4396L1.66541 9.99868ZM17.5763 9.55779C15.9422 12.3591 13.6685 14.957 9.99874 14.957V16.707C14.6623 16.707 17.3887 13.3525 19.0879 10.4396L17.5763 9.55779ZM9.99874 14.957C6.32902 14.957 4.05532 12.3591 2.42121 9.55779L0.909599 10.4396C2.60882 13.3525 5.33513 16.707 9.99874 16.707V14.957ZM2.42121 10.4396C4.05532 7.63823 6.32902 5.04034 9.99874 5.04034V3.29034C5.33513 3.29034 2.60882 6.64484 0.909599 9.55779L2.42121 10.4396ZM9.99874 5.04034C13.6685 5.04034 15.9422 7.63823 17.5763 10.4396L19.0879 9.55779C17.3887 6.64484 14.6623 3.29034 9.99874 3.29034V5.04034Z"
            fill={color ?? neutralDay.gray100}
          />
          <circle cx="9.9987" cy="9.9987" r="2.91667" fill={color ?? neutralDay.gray100} />
          <circle cx="9.99874" cy="9.99868" r="2.08333" fill={color ?? neutralDay.gray100} />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22 12L22.8638 12.5039L23.1577 12L22.8638 11.4961L22 12ZM2 12L1.13622 11.4961L0.842296 12L1.13622 12.5039L2 12ZM21.1362 11.4961C19.1734 14.8609 16.4321 18 12 18V20C17.5679 20 20.8266 15.9962 22.8638 12.5039L21.1362 11.4961ZM12 18C7.56794 18 4.82657 14.8609 2.86378 11.4961L1.13622 12.5039C3.17343 15.9962 6.43206 20 12 20V18ZM2.86378 12.5039C4.82657 9.13908 7.56794 6 12 6V4C6.43206 4 3.17343 8.00378 1.13622 11.4961L2.86378 12.5039ZM12 6C16.4321 6 19.1734 9.13908 21.1362 12.5039L22.8638 11.4961C20.8266 8.00378 17.5679 4 12 4V6Z"
            fill={color ?? neutralDay.gray100}
          />
          <circle cx="12" cy="12" r="3.5" fill={color ?? neutralDay.gray100} />
          <circle cx="12" cy="12" r="2.5" fill={color ?? neutralDay.gray100} />
        </svg>
      );
  }
}
