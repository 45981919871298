import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function CloseCircleLine({ size, color }: LineIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="8" cy="8" r="6" stroke={color ?? neutralDay.gray100} strokeWidth="1.5" />
          <path
            d="M6.42426 5.57574C6.18995 5.34142 5.81005 5.34142 5.57574 5.57574C5.34142 5.81005 5.34142 6.18995 5.57574 6.42426L6.42426 5.57574ZM9.57574 10.4243C9.81005 10.6586 10.1899 10.6586 10.4243 10.4243C10.6586 10.1899 10.6586 9.81005 10.4243 9.57574L9.57574 10.4243ZM5.57574 6.42426L9.57574 10.4243L10.4243 9.57574L6.42426 5.57574L5.57574 6.42426Z"
            fill={color ?? neutralDay.gray100}
          />
          <path
            d="M10.4243 6.42426C10.6586 6.18995 10.6586 5.81005 10.4243 5.57574C10.1899 5.34142 9.81005 5.34142 9.57574 5.57574L10.4243 6.42426ZM5.57574 9.57574C5.34142 9.81005 5.34142 10.1899 5.57574 10.4243C5.81005 10.6586 6.18995 10.6586 6.42426 10.4243L5.57574 9.57574ZM9.57574 5.57574L5.57574 9.57574L6.42426 10.4243L10.4243 6.42426L9.57574 5.57574Z"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="10" cy="10" r="7.5" stroke={color ?? neutralDay.gray100} strokeWidth="1.75" />
          <path
            d="M8.03033 6.96967C7.73744 6.67678 7.26256 6.67678 6.96967 6.96967C6.67678 7.26256 6.67678 7.73744 6.96967 8.03033L8.03033 6.96967ZM11.9697 13.0303C12.2626 13.3232 12.7374 13.3232 13.0303 13.0303C13.3232 12.7374 13.3232 12.2626 13.0303 11.9697L11.9697 13.0303ZM6.96967 8.03033L11.9697 13.0303L13.0303 11.9697L8.03033 6.96967L6.96967 8.03033Z"
            fill={color ?? neutralDay.gray100}
          />
          <path
            d="M13.0303 8.03033C13.3232 7.73744 13.3232 7.26256 13.0303 6.96967C12.7374 6.67678 12.2626 6.67678 11.9697 6.96967L13.0303 8.03033ZM6.96967 11.9697C6.67678 12.2626 6.67678 12.7374 6.96967 13.0303C7.26256 13.3232 7.73744 13.3232 8.03033 13.0303L6.96967 11.9697ZM11.9697 6.96967L6.96967 11.9697L8.03033 13.0303L13.0303 8.03033L11.9697 6.96967Z"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="12" r="9" stroke={color ?? neutralDay.gray100} strokeWidth="2" />
          <path
            d="M9.61872 8.38128C9.27701 8.03957 8.72299 8.03957 8.38128 8.38128C8.03957 8.72299 8.03957 9.27701 8.38128 9.61872L9.61872 8.38128ZM14.3813 15.6187C14.723 15.9604 15.277 15.9604 15.6187 15.6187C15.9604 15.277 15.9604 14.723 15.6187 14.3813L14.3813 15.6187ZM8.38128 9.61872L14.3813 15.6187L15.6187 14.3813L9.61872 8.38128L8.38128 9.61872Z"
            fill={color ?? neutralDay.gray100}
          />
          <path
            d="M15.6187 9.61872C15.9604 9.27701 15.9604 8.72299 15.6187 8.38128C15.277 8.03957 14.723 8.03957 14.3813 8.38128L15.6187 9.61872ZM8.38128 14.3813C8.03957 14.723 8.03957 15.277 8.38128 15.6187C8.72299 15.9604 9.27701 15.9604 9.61872 15.6187L8.38128 14.3813ZM14.3813 8.38128L8.38128 14.3813L9.61872 15.6187L15.6187 9.61872L14.3813 8.38128Z"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );
  }
}
