enum HanghaeColors {
  Red120 = 'red120',
  Red100 = 'red100',
  Red60 = 'red60',
  Red40 = 'red40',
  Red20 = 'red20',
  Red0 = 'red0',
}

export const hanghae: Record<HanghaeColors, string> = {
  [HanghaeColors.Red120]: '#BB150F',
  [HanghaeColors.Red100]: '#DE1912',
  [HanghaeColors.Red60]: '#FF5C5C',
  [HanghaeColors.Red40]: '#FF8F8F',
  [HanghaeColors.Red20]: '#FFC2C2',
  [HanghaeColors.Red0]: '#FFEBEB',
};
