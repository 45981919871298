import { type LineOnlyIcon } from '../type';
import { neutralDay } from '@/_global/_styles/design-system/foundation/color';

export function ArrowLeft({ size, color }: LineOnlyIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.33325 4L3.33325 8L7.33325 12"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4 8L12.6667 8"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.16675 5L4.16675 10L9.16675 15"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5 10L15.8333 10"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 6L5 12L11 18"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6 12L19 12"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
}
