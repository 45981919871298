import { type LineOnlyIcon } from '../type';
import { neutralDay } from '@/_global/_styles/design-system/foundation/color';

export function ArrowChevronDown({ size, color }: LineOnlyIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 6.66406L8 10.6641L12 6.66406"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 8.33203L10 13.332L15 8.33203"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 10L12 16L18 10"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
}
