enum ChangColors {
  Indigo = 'indigo',
  Blue = 'blue',
  BlueSub = 'blueSub',
}

export const chang: Record<ChangColors, string> = {
  [ChangColors.Indigo]: '#0D1F7A',
  [ChangColors.Blue]: '#0F16AE',
  [ChangColors.BlueSub]: '#A8C5FF',
};
