import { type LineIcon } from '../type';

export function IconLoading24Line({ size }: LineIcon) {
  switch (size) {
    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20"
            stroke="url(#paint0_angular_674_4867)"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 13.4571 19.6104 14.8233 18.9297 16"
            stroke="url(#paint1_angular_674_4867)"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <defs>
            <radialGradient
              id="paint0_angular_674_4867"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(12 12) rotate(72.646) scale(8.38153)"
            >
              <stop stopColor="#1C1D1E" />
              <stop offset="0.495286" stopColor="#1C1D1E" />
              <stop offset="0.818132" stopColor="#1C1D1E" stopOpacity="0" />
              <stop offset="1" stopColor="#1C1D1E" stopOpacity="0" />
            </radialGradient>
            <radialGradient
              id="paint1_angular_674_4867"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(12 12) rotate(90) scale(8)"
            >
              <stop stopColor="#1C1D1E" />
              <stop offset="0.703125" stopColor="#1C1D1E" stopOpacity="0.208333" />
              <stop offset="0.848958" stopColor="#1C1D1E" stopOpacity="0" />
              <stop offset="1" stopColor="#1C1D1E" stopOpacity="0" />
            </radialGradient>
          </defs>
        </svg>
      );
    default:
      return null;
  }
}
