import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineFillIcon } from '../type';

export function InfoPoint({ size, style, color }: LineFillIcon) {
  switch (size) {
    case 16:
      switch (style) {
        case 'line':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="8" cy="8" r="6" stroke={color ?? neutralDay.gray90} strokeWidth="1.5" />
              <path
                d="M6.79944 5.46729V10.8006"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.2"
                strokeLinecap="round"
              />
              <path
                d="M8.4661 5.46729H6.79944V8.80062H8.4661C9.57722 8.80062 10.1328 8.13395 10.1328 7.13395C10.1328 6.13395 9.57722 5.46729 8.4661 5.46729Z"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.29956 5.96729V8.30062H8.46623C8.92018 8.30062 9.19257 8.16712 9.35416 7.99746C9.51972 7.82362 9.63289 7.54457 9.63289 7.13395C9.63289 6.72334 9.51972 6.44429 9.35416 6.27045C9.19257 6.10078 8.92018 5.96729 8.46623 5.96729H7.29956Z"
                fill={color ?? neutralDay.gray90}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.00005 1.30005C4.29974 1.30005 1.30005 4.29974 1.30005 8.00005C1.30005 11.7004 4.29974 14.7 8.00005 14.7C11.7004 14.7 14.7 11.7004 14.7 8.00005C14.7 4.29974 11.7004 1.30005 8.00005 1.30005ZM6.29956 5.46729C6.29956 5.19114 6.52342 4.96729 6.79956 4.96729H8.46623C9.12338 4.96729 9.68433 5.16712 10.0783 5.58079C10.4683 5.99028 10.6329 6.54457 10.6329 7.13395C10.6329 7.72334 10.4683 8.27762 10.0783 8.68711C9.68433 9.10078 9.12338 9.30062 8.46623 9.30062H7.29956V10.8006C7.29956 11.0768 7.0757 11.3006 6.79956 11.3006C6.52342 11.3006 6.29956 11.0768 6.29956 10.8006V5.46729Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 20:
      switch (style) {
        case 'line':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="10"
                cy="10"
                r="7.5"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
              />
              <path
                d="M8.49933 6.83398V13.5007"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M10.5827 6.83398H8.49933V11.0007H10.5827C11.9716 11.0007 12.666 10.1673 12.666 8.91732C12.666 7.66732 11.9716 6.83398 10.5827 6.83398Z"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.12427 7.45898V10.3757H10.5826C11.15 10.3757 11.4905 10.2088 11.6925 9.9967C11.8995 9.7794 12.0409 9.43059 12.0409 8.91732C12.0409 8.40405 11.8995 8.05524 11.6925 7.83794C11.4905 7.62585 11.15 7.45898 10.5826 7.45898H9.12427Z"
                fill={color ?? neutralDay.gray90}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 1.625C5.37462 1.625 1.625 5.37462 1.625 10C1.625 14.6254 5.37462 18.375 10 18.375C14.6254 18.375 18.375 14.6254 18.375 10C18.375 5.37462 14.6254 1.625 10 1.625ZM9.12427 11.6257H10.5826C11.404 11.6257 12.1052 11.3759 12.5977 10.8588C13.0852 10.3469 13.2909 9.65405 13.2909 8.91732C13.2909 8.18059 13.0852 7.48773 12.5977 6.97587C12.1052 6.45878 11.404 6.20898 10.5826 6.20898H8.49927C8.15409 6.20898 7.87427 6.48881 7.87427 6.83398V13.5007C7.87427 13.8458 8.15409 14.1257 8.49927 14.1257C8.84445 14.1257 9.12427 13.8458 9.12427 13.5007V11.6257Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 24:
      switch (style) {
        case 'line':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="12" cy="12" r="9" stroke={color ?? neutralDay.gray90} strokeWidth="2" />
              <path
                d="M10.1992 8.20068V16.2007"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
                strokeLinecap="round"
              />
              <path
                d="M12.6992 8.20068H10.1992V13.2007H12.6992C14.3659 13.2007 15.1992 12.2007 15.1992 10.7007C15.1992 9.20068 14.3659 8.20068 12.6992 8.20068Z"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.9492 8.95068V12.4507H12.6992C13.3802 12.4507 13.7887 12.2504 14.0311 11.9959C14.2795 11.7352 14.4492 11.3166 14.4492 10.7007C14.4492 10.0848 14.2795 9.66619 14.0311 9.40543C13.7887 9.15093 13.3802 8.95068 12.6992 8.95068H10.9492Z"
                fill={color ?? neutralDay.gray90}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM10.9492 13.9507H12.6992C13.6849 13.9507 14.5264 13.6509 15.1173 13.0304C15.7023 12.4162 15.9492 11.5848 15.9492 10.7007C15.9492 9.81661 15.7023 8.98518 15.1173 8.37094C14.5264 7.75044 13.6849 7.45068 12.6992 7.45068H10.1992C9.78501 7.45068 9.44922 7.78647 9.44922 8.20068V16.2007C9.44922 16.6149 9.78501 16.9507 10.1992 16.9507C10.6134 16.9507 10.9492 16.6149 10.9492 16.2007V13.9507Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
  }
}
