import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function CodeLine({ size, color }: LineIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.46967 11.196C3.76256 11.4889 4.23744 11.4889 4.53033 11.196C4.82322 10.9031 4.82322 10.4282 4.53033 10.1353L3.46967 11.196ZM1.33333 7.99898L0.803004 7.46865C0.51011 7.76155 0.51011 8.23642 0.803003 8.52931L1.33333 7.99898ZM4.53033 5.86265C4.82322 5.56975 4.82322 5.09488 4.53033 4.80199C4.23744 4.50909 3.76256 4.50909 3.46967 4.80199L4.53033 5.86265ZM4.53033 10.1353L1.86366 7.46865L0.803003 8.52931L3.46967 11.196L4.53033 10.1353ZM1.86366 8.52931L4.53033 5.86265L3.46967 4.80199L0.803004 7.46865L1.86366 8.52931Z"
            fill={color ?? neutralDay.gray100}
          />
          <path
            d="M12.5303 4.80402C12.2374 4.51113 11.7626 4.51113 11.4697 4.80402C11.1768 5.09691 11.1768 5.57179 11.4697 5.86468L12.5303 4.80402ZM14.6667 8.00102L15.197 8.53135C15.4899 8.23845 15.4899 7.76358 15.197 7.47069L14.6667 8.00102ZM11.4697 10.1374C11.1768 10.4302 11.1768 10.9051 11.4697 11.198C11.7626 11.4909 12.2374 11.4909 12.5303 11.198L11.4697 10.1374ZM11.4697 5.86468L14.1363 8.53135L15.197 7.47069L12.5303 4.80402L11.4697 5.86468ZM14.1363 7.47069L11.4697 10.1374L12.5303 11.198L15.197 8.53135L14.1363 7.47069Z"
            fill={color ?? neutralDay.gray100}
          />
          <path
            d="M10 3.33435L6 12.6677"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.38128 13.952C4.72299 14.2937 5.27701 14.2937 5.61872 13.952C5.96043 13.6103 5.96043 13.0563 5.61872 12.7146L4.38128 13.952ZM1.66667 9.99998L1.04795 9.38126C0.70624 9.72297 0.70624 10.277 1.04795 10.6187L1.66667 9.99998ZM5.61872 7.28537C5.96043 6.94366 5.96043 6.38964 5.61872 6.04793C5.27701 5.70622 4.72299 5.70622 4.38128 6.04793L5.61872 7.28537ZM5.61872 12.7146L2.28539 9.38126L1.04795 10.6187L4.38128 13.952L5.61872 12.7146ZM2.28539 10.6187L5.61872 7.28537L4.38128 6.04793L1.04795 9.38126L2.28539 10.6187Z"
            fill={color ?? neutralDay.gray100}
          />
          <path
            d="M15.6187 6.04797C15.277 5.70626 14.723 5.70626 14.3813 6.04797C14.0396 6.38968 14.0396 6.9437 14.3813 7.28541L15.6187 6.04797ZM18.3333 10L18.9521 10.6187C19.2938 10.277 19.2938 9.72301 18.9521 9.3813L18.3333 10ZM14.3813 12.7146C14.0396 13.0563 14.0396 13.6104 14.3813 13.9521C14.723 14.2938 15.277 14.2938 15.6187 13.9521L14.3813 12.7146ZM14.3813 7.28541L17.7146 10.6187L18.9521 9.3813L15.6187 6.04797L14.3813 7.28541ZM17.7146 9.3813L14.3813 12.7146L15.6187 13.9521L18.9521 10.6187L17.7146 9.3813Z"
            fill={color ?? neutralDay.gray100}
          />
          <path
            d="M12.5 4.16669L7.5 15.8334"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.29289 16.7071C5.68342 17.0976 6.31658 17.0976 6.70711 16.7071C7.09763 16.3166 7.09763 15.6834 6.70711 15.2929L5.29289 16.7071ZM2 12L1.29289 11.2929C0.902369 11.6834 0.902369 12.3166 1.29289 12.7071L2 12ZM6.70711 8.70711C7.09763 8.31658 7.09763 7.68342 6.70711 7.29289C6.31658 6.90237 5.68342 6.90237 5.29289 7.29289L6.70711 8.70711ZM6.70711 15.2929L2.70711 11.2929L1.29289 12.7071L5.29289 16.7071L6.70711 15.2929ZM2.70711 12.7071L6.70711 8.70711L5.29289 7.29289L1.29289 11.2929L2.70711 12.7071Z"
            fill={color ?? neutralDay.gray100}
          />
          <path
            d="M18.7071 7.29289C18.3166 6.90237 17.6834 6.90237 17.2929 7.29289C16.9024 7.68342 16.9024 8.31658 17.2929 8.70711L18.7071 7.29289ZM22 12L22.7071 12.7071C23.0976 12.3166 23.0976 11.6834 22.7071 11.2929L22 12ZM17.2929 15.2929C16.9024 15.6834 16.9024 16.3166 17.2929 16.7071C17.6834 17.0976 18.3166 17.0976 18.7071 16.7071L17.2929 15.2929ZM17.2929 8.70711L21.2929 12.7071L22.7071 11.2929L18.7071 7.29289L17.2929 8.70711ZM21.2929 11.2929L17.2929 15.2929L18.7071 16.7071L22.7071 12.7071L21.2929 11.2929Z"
            fill={color ?? neutralDay.gray100}
          />
          <path
            d="M15 5L9 19"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      );
  }
}
