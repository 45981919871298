import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineFillIcon } from '../type';

export function InfoCoupon({ size, style, color }: LineFillIcon) {
  switch (size) {
    case 16:
      switch (style) {
        case 'line':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask id="path-1-inside-1_8993_24842" fill="white">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1.33325 3.90909C1.33325 3.40701 1.74027 3 2.24234 3H13.7575C14.2596 3 14.6666 3.40701 14.6666 3.90909V6.66667C13.9302 6.66667 13.3333 7.26362 13.3333 8C13.3333 8.73638 13.9302 9.33333 14.6666 9.33333V12.0909C14.6666 12.593 14.2596 13 13.7575 13H2.24234C1.74026 13 1.33325 12.593 1.33325 12.0909V9.33333C2.06963 9.33333 2.66659 8.73638 2.66659 8C2.66659 7.26362 2.06963 6.66667 1.33325 6.66667V3.90909Z"
                />
              </mask>
              <path
                d="M14.6666 6.66667V8.16667H16.1666V6.66667H14.6666ZM14.6666 9.33333H16.1666V7.83333H14.6666V9.33333ZM1.33325 9.33333V7.83333H-0.166748V9.33333H1.33325ZM1.33325 6.66667H-0.166748V8.16667H1.33325V6.66667ZM2.24234 1.5C0.911838 1.5 -0.166748 2.57859 -0.166748 3.90909H2.83325C2.83325 4.23544 2.56869 4.5 2.24234 4.5V1.5ZM13.7575 1.5H2.24234V4.5H13.7575V1.5ZM16.1666 3.90909C16.1666 2.57859 15.088 1.5 13.7575 1.5V4.5C13.4311 4.5 13.1666 4.23544 13.1666 3.90909H16.1666ZM16.1666 6.66667V3.90909H13.1666V6.66667H16.1666ZM14.8333 8C14.8333 8.09205 14.7586 8.16667 14.6666 8.16667V5.16667C13.1018 5.16667 11.8333 6.43519 11.8333 8H14.8333ZM14.6666 7.83333C14.7586 7.83333 14.8333 7.90795 14.8333 8H11.8333C11.8333 9.56481 13.1018 10.8333 14.6666 10.8333V7.83333ZM16.1666 12.0909V9.33333H13.1666V12.0909H16.1666ZM13.7575 14.5C15.088 14.5 16.1666 13.4214 16.1666 12.0909H13.1666C13.1666 11.7646 13.4311 11.5 13.7575 11.5V14.5ZM2.24234 14.5H13.7575V11.5H2.24234V14.5ZM-0.166748 12.0909C-0.166748 13.4214 0.911836 14.5 2.24234 14.5V11.5C2.56869 11.5 2.83325 11.7646 2.83325 12.0909H-0.166748ZM-0.166748 9.33333V12.0909H2.83325V9.33333H-0.166748ZM1.16659 8C1.16659 7.90795 1.24121 7.83333 1.33325 7.83333V10.8333C2.89806 10.8333 4.16659 9.56481 4.16659 8H1.16659ZM1.33325 8.16667C1.2412 8.16667 1.16659 8.09205 1.16659 8H4.16659C4.16659 6.43519 2.89806 5.16667 1.33325 5.16667V8.16667ZM-0.166748 3.90909V6.66667H2.83325V3.90909H-0.166748Z"
                fill={color ?? neutralDay.gray90}
                mask="url(#path-1-inside-1_8993_24842)"
              />
              <path
                d="M9.33325 6.66675L6.66659 9.33341"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <circle cx="6.66667" cy="6.66667" r="0.666667" fill={color ?? neutralDay.gray90} />
              <circle cx="9.33342" cy="9.33342" r="0.666667" fill={color ?? neutralDay.gray90} />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.24234 3C1.74027 3 1.33325 3.40701 1.33325 3.90909V6.1138C2.11005 6.38836 2.66659 7.12919 2.66659 8C2.66659 8.87081 2.11005 9.61164 1.33325 9.8862V12.0909C1.33325 12.593 1.74026 13 2.24234 13H13.7575C14.2596 13 14.6666 12.593 14.6666 12.0909V9.8862C13.8898 9.61164 13.3333 8.87081 13.3333 8C13.3333 7.12919 13.8898 6.38836 14.6666 6.1138V3.90909C14.6666 3.40701 14.2596 3 13.7575 3H2.24234ZM9.68681 7.02022C9.88207 6.82496 9.88207 6.50838 9.68681 6.31311C9.49154 6.11785 9.17496 6.11785 8.9797 6.31311L6.31303 8.97978C6.11777 9.17504 6.11777 9.49162 6.31303 9.68689C6.50829 9.88215 6.82488 9.88215 7.02014 9.68689L9.68681 7.02022ZM7.33325 6.66667C7.33325 7.03486 7.03477 7.33333 6.66658 7.33333C6.2984 7.33333 5.99992 7.03486 5.99992 6.66667C5.99992 6.29848 6.2984 6 6.66658 6C7.03477 6 7.33325 6.29848 7.33325 6.66667ZM9.33325 10C9.70144 10 9.99992 9.70152 9.99992 9.33333C9.99992 8.96514 9.70144 8.66667 9.33325 8.66667C8.96506 8.66667 8.66658 8.96514 8.66658 9.33333C8.66658 9.70152 8.96506 10 9.33325 10Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 20:
      switch (style) {
        case 'line':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask id="path-1-inside-1_8993_24856" fill="white">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1.66663 4.50758C1.66663 4.08918 2.0058 3.75 2.4242 3.75H17.5757C17.9941 3.75 18.3333 4.08918 18.3333 4.50758V8.33333H18.3333C17.4128 8.33333 16.6666 9.07953 16.6666 10C16.6666 10.9205 17.4128 11.6667 18.3333 11.6667H18.3333V15.4924C18.3333 15.9108 17.9941 16.25 17.5757 16.25H2.4242C2.0058 16.25 1.66663 15.9108 1.66663 15.4924V11.6667C2.58709 11.6667 3.33327 10.9205 3.33327 10C3.33327 9.07953 2.58709 8.33334 1.66663 8.33333V4.50758Z"
                />
              </mask>
              <path
                d="M18.3333 8.33333V10H20V8.33333H18.3333ZM18.3333 11.6667H20V10H18.3333V11.6667ZM1.66663 11.6667L1.66661 10L-4.06504e-05 10V11.6667H1.66663ZM1.66663 8.33333H-4.06504e-05V9.99998L1.66661 10L1.66663 8.33333ZM2.4242 2.08333C1.08533 2.08333 -4.06504e-05 3.1687 -4.06504e-05 4.50758H3.33329C3.33329 5.00965 2.92628 5.41667 2.4242 5.41667V2.08333ZM17.5757 2.08333H2.4242V5.41667H17.5757V2.08333ZM20 4.50758C20 3.16871 18.9146 2.08333 17.5757 2.08333V5.41667C17.0736 5.41667 16.6666 5.00965 16.6666 4.50758H20ZM20 8.33333V4.50758H16.6666V8.33333H20ZM18.3333 10H18.3333V6.66667H18.3333V10ZM18.3333 10V6.66667C16.4923 6.66667 14.9999 8.15905 14.9999 10H18.3333ZM18.3333 10V10H14.9999C14.9999 11.841 16.4923 13.3333 18.3333 13.3333V10ZM18.3333 10H18.3333V13.3333H18.3333V10ZM20 15.4924V11.6667H16.6666V15.4924H20ZM17.5757 17.9167C18.9146 17.9167 20 16.8313 20 15.4924H16.6666C16.6666 14.9903 17.0736 14.5833 17.5757 14.5833V17.9167ZM2.4242 17.9167H17.5757V14.5833H2.4242V17.9167ZM-4.06504e-05 15.4924C-4.06504e-05 16.8313 1.08533 17.9167 2.4242 17.9167V14.5833C2.92628 14.5833 3.33329 14.9903 3.33329 15.4924H-4.06504e-05ZM-4.06504e-05 11.6667V15.4924H3.33329V11.6667H-4.06504e-05ZM1.66661 10L1.66661 10L1.66665 13.3333C3.50758 13.3333 4.99994 11.8409 4.99994 10H1.66661ZM1.66661 10H1.66661H4.99994C4.99994 8.15906 3.50758 6.66669 1.66665 6.66667L1.66661 10ZM-4.06504e-05 4.50758V8.33333H3.33329V4.50758H-4.06504e-05Z"
                fill={color ?? neutralDay.gray90}
                mask="url(#path-1-inside-1_8993_24856)"
              />
              <path
                d="M11.6666 8.33325L8.33329 11.6666"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <circle cx="8.33333" cy="8.33333" r="0.833333" fill={color ?? neutralDay.gray90} />
              <circle cx="11.6667" cy="11.6666" r="0.833333" fill={color ?? neutralDay.gray90} />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.42432 3.75C2.00593 3.75 1.66675 4.08918 1.66675 4.50758V7.64224C2.63776 7.98543 3.33346 8.91147 3.33346 10C3.33346 11.0885 2.63776 12.0146 1.66675 12.3578V15.4924C1.66675 15.9108 2.00593 16.25 2.42432 16.25H17.5758C17.9942 16.25 18.3334 15.9108 18.3334 15.4924V12.3577C17.3624 12.0145 16.6668 11.0885 16.6668 10C16.6668 8.9115 17.3624 7.98548 18.3334 7.64227V4.50758C18.3334 4.08918 17.9942 3.75 17.5758 3.75H2.42432ZM12.1087 7.89139C11.8647 7.64731 11.4689 7.64731 11.2248 7.89139L7.89151 11.2247C7.64744 11.4688 7.64744 11.8645 7.89151 12.1086C8.13559 12.3527 8.53132 12.3527 8.7754 12.1086L12.1087 8.77528C12.3528 8.5312 12.3528 8.13547 12.1087 7.89139ZM9.16679 8.33333C9.16679 8.79357 8.79369 9.16667 8.33346 9.16667C7.87322 9.16667 7.50012 8.79357 7.50012 8.33333C7.50012 7.8731 7.87322 7.5 8.33346 7.5C8.79369 7.5 9.16679 7.8731 9.16679 8.33333ZM11.6668 12.5C12.127 12.5 12.5001 12.1269 12.5001 11.6667C12.5001 11.2064 12.127 10.8333 11.6668 10.8333C11.2066 10.8333 10.8335 11.2064 10.8335 11.6667C10.8335 12.1269 11.2066 12.5 11.6668 12.5Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 24:
      switch (style) {
        case 'line':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask id="path-1-inside-1_8993_24828" fill="white">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2 5.40909C2 4.90701 2.40701 4.5 2.90909 4.5H21.0909C21.593 4.5 22 4.90701 22 5.40909V10C20.8954 10 20 10.8954 20 12C20 13.1046 20.8954 14 22 14V18.5909C22 19.093 21.593 19.5 21.0909 19.5H2.90909C2.40701 19.5 2 19.093 2 18.5909V14C3.10457 14 4 13.1046 4 12C4 10.8954 3.10457 10 2 10V5.40909Z"
                />
              </mask>
              <path
                d="M22 10V12H24V10H22ZM22 14H24V12H22V14ZM2 14V12H0V14H2ZM2 10H0V12H2V10ZM2.90909 2.5C1.30244 2.5 0 3.80244 0 5.40909H4C4 6.01158 3.51158 6.5 2.90909 6.5V2.5ZM21.0909 2.5H2.90909V6.5H21.0909V2.5ZM24 5.40909C24 3.80245 22.6976 2.5 21.0909 2.5V6.5C20.4884 6.5 20 6.01158 20 5.40909H24ZM24 10V5.40909H20V10H24ZM22 12V8C19.7909 8 18 9.79086 18 12H22ZM22 12H18C18 14.2091 19.7909 16 22 16V12ZM24 18.5909V14H20V18.5909H24ZM21.0909 21.5C22.6976 21.5 24 20.1976 24 18.5909H20C20 17.9884 20.4884 17.5 21.0909 17.5V21.5ZM2.90909 21.5H21.0909V17.5H2.90909V21.5ZM0 18.5909C0 20.1976 1.30244 21.5 2.90909 21.5V17.5C3.51159 17.5 4 17.9884 4 18.5909H0ZM0 14V18.5909H4V14H0ZM2 12V16C4.20914 16 6 14.2091 6 12H2ZM2 12H6C6 9.79086 4.20914 8 2 8V12ZM0 5.40909V10H4V5.40909H0Z"
                fill={color ?? neutralDay.gray90}
                mask="url(#path-1-inside-1_8993_24828)"
              />
              <path
                d="M14 10L10 14"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <circle cx="10" cy="10" r="1" fill={color ?? neutralDay.gray90} />
              <circle cx="14" cy="14" r="1" fill={color ?? neutralDay.gray90} />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.90909 4.5C2.40701 4.5 2 4.90701 2 5.40909V9.17071C3.16519 9.58254 4 10.6938 4 12C4 13.3062 3.16519 14.4175 2 14.8293V18.5909C2 19.093 2.40701 19.5 2.90909 19.5H21.0909C21.593 19.5 22 19.093 22 18.5909V14.8293C20.8348 14.4175 20 13.3062 20 12C20 10.6938 20.8348 9.58254 22 9.17071V5.40909C22 4.90701 21.593 4.5 21.0909 4.5H2.90909ZM14.5303 9.46967C14.2374 9.17678 13.7626 9.17678 13.4697 9.46967L9.46967 13.4697C9.17678 13.7626 9.17678 14.2374 9.46967 14.5303C9.76256 14.8232 10.2374 14.8232 10.5303 14.5303L14.5303 10.5303C14.8232 10.2374 14.8232 9.76256 14.5303 9.46967ZM11 10C11 10.5523 10.5523 11 10 11C9.44772 11 9 10.5523 9 10C9 9.44772 9.44772 9 10 9C10.5523 9 11 9.44772 11 10ZM14 15C14.5523 15 15 14.5523 15 14C15 13.4477 14.5523 13 14 13C13.4477 13 13 13.4477 13 14C13 14.5523 13.4477 15 14 15Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
  }
}
