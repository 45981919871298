import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function IndexLine({ size, color }: LineIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.66663 4H13.3333"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M6.66663 8H13.3333"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M6.66663 12H13.3333"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <circle cx="3.41663" cy="4" r="0.75" fill={color ?? neutralDay.gray100} />
          <circle cx="3.41663" cy="8" r="0.75" fill={color ?? neutralDay.gray100} />
          <circle cx="3.41663" cy="12" r="0.75" fill={color ?? neutralDay.gray100} />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.33331 5H16.6666"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <path
            d="M8.33331 10H16.6666"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <path
            d="M8.33331 15H16.6666"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <circle cx="4.20825" cy="5" r="0.875" fill={color ?? neutralDay.gray100} />
          <circle cx="4.20825" cy="10" r="0.875" fill={color ?? neutralDay.gray100} />
          <circle cx="4.20825" cy="15" r="0.875" fill={color ?? neutralDay.gray100} />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 6H20"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M10 12H20"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M10 18H20"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinecap="round"
          />
          <circle cx="4.99988" cy="5.99973" r="1" fill={color ?? neutralDay.gray100} />
          <circle cx="4.99988" cy="11.9997" r="1" fill={color ?? neutralDay.gray100} />
          <circle cx="4.99988" cy="17.9997" r="1" fill={color ?? neutralDay.gray100} />
        </svg>
      );
  }
}
