import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineFillIcon } from '../type';

export function ContentsNote({ size, style, color }: LineFillIcon) {
  switch (size) {
    case 16:
      switch (style) {
        case 'line':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="2.66666"
                y="2"
                width="10.6667"
                height="12"
                rx="1.33333"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinejoin="round"
              />
              <path
                d="M5.33334 5.33325L10.6667 5.33325"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.33334 8L10.6667 8"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.33334 10.6667L8.66668 10.6667"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.91666 3.33333C1.91666 2.18274 2.8494 1.25 3.99999 1.25H12C13.1506 1.25 14.0833 2.18274 14.0833 3.33333V12.6667C14.0833 13.8173 13.1506 14.75 12 14.75H3.99999C2.8494 14.75 1.91666 13.8173 1.91666 12.6667V3.33333ZM5.33334 4.83325C5.0572 4.83325 4.83334 5.05711 4.83334 5.33325C4.83334 5.60939 5.0572 5.83325 5.33334 5.83325L10.6667 5.83325C10.9428 5.83325 11.1667 5.60939 11.1667 5.33325C11.1667 5.05711 10.9428 4.83325 10.6667 4.83325L5.33334 4.83325ZM4.83334 8C4.83334 7.72386 5.0572 7.5 5.33334 7.5L10.6667 7.5C10.9428 7.5 11.1667 7.72386 11.1667 8C11.1667 8.27614 10.9428 8.5 10.6667 8.5L5.33334 8.5C5.0572 8.5 4.83334 8.27614 4.83334 8ZM5.33334 10.1667C5.0572 10.1667 4.83334 10.3906 4.83334 10.6667C4.83334 10.9429 5.0572 11.1667 5.33334 11.1667L8.66668 11.1667C8.94282 11.1667 9.16668 10.9429 9.16668 10.6667C9.16668 10.3906 8.94282 10.1667 8.66668 10.1667L5.33334 10.1667Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 20:
      switch (style) {
        case 'line':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="3.33325"
                y="2.5"
                width="13.3333"
                height="15"
                rx="1.66667"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
                strokeLinejoin="round"
              />
              <path
                d="M6.66675 6.6665L13.3334 6.6665"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M6.66675 10L13.3334 10"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M6.66675 13.3335L10.8334 13.3335"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.45825 4.16667C2.45825 2.76294 3.59619 1.625 4.99992 1.625H14.9999C16.4036 1.625 17.5416 2.76294 17.5416 4.16667V15.8333C17.5416 17.2371 16.4036 18.375 14.9999 18.375H4.99992C3.59619 18.375 2.45825 17.2371 2.45825 15.8333V4.16667ZM6.66675 5.9165C6.25253 5.9165 5.91675 6.25229 5.91675 6.6665C5.91675 7.08072 6.25253 7.4165 6.66675 7.4165H13.3334C13.7476 7.4165 14.0834 7.08072 14.0834 6.6665C14.0834 6.25229 13.7476 5.9165 13.3334 5.9165H6.66675ZM5.91675 10C5.91675 9.58579 6.25253 9.25 6.66675 9.25H13.3334C13.7476 9.25 14.0834 9.58579 14.0834 10C14.0834 10.4142 13.7476 10.75 13.3334 10.75H6.66675C6.25253 10.75 5.91675 10.4142 5.91675 10ZM6.66675 12.5835C6.25253 12.5835 5.91675 12.9193 5.91675 13.3335C5.91675 13.7477 6.25253 14.0835 6.66675 14.0835H10.8334C11.2476 14.0835 11.5834 13.7477 11.5834 13.3335C11.5834 12.9193 11.2476 12.5835 10.8334 12.5835H6.66675Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 24:
      switch (style) {
        case 'line':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="4"
                y="3"
                width="16"
                height="18"
                rx="2"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="2"
                strokeLinejoin="round"
              />
              <path
                d="M8 8L16 8"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
                strokeLinecap="round"
              />
              <path
                d="M8 12L16 12"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
                strokeLinecap="round"
              />
              <path
                d="M8 16L13 16"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
                strokeLinecap="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 5C3 3.34315 4.34315 2 6 2H18C19.6569 2 21 3.34315 21 5V19C21 20.6569 19.6569 22 18 22H6C4.34315 22 3 20.6569 3 19V5ZM8 7.25C7.58579 7.25 7.25 7.58579 7.25 8C7.25 8.41421 7.58579 8.75 8 8.75L16 8.75C16.4142 8.75 16.75 8.41421 16.75 8C16.75 7.58579 16.4142 7.25 16 7.25L8 7.25ZM7.25 12C7.25 11.5858 7.58579 11.25 8 11.25H16C16.4142 11.25 16.75 11.5858 16.75 12C16.75 12.4142 16.4142 12.75 16 12.75H8C7.58579 12.75 7.25 12.4142 7.25 12ZM8 15.25C7.58579 15.25 7.25 15.5858 7.25 16C7.25 16.4142 7.58579 16.75 8 16.75L13 16.75C13.4142 16.75 13.75 16.4142 13.75 16C13.75 15.5858 13.4142 15.25 13 15.25L8 15.25Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
  }
}
