import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineFillIcon } from '../type';

export function ActionHeart({ size, style, color }: LineFillIcon) {
  switch (size) {
    case 16:
      switch (style) {
        case 'line':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1538_7596)">
                <path
                  d="M7.99991 3.69693L7.63628 3.3333C6.23046 1.92748 3.95118 1.92748 2.54537 3.3333V3.3333C1.13955 4.73911 1.13955 7.01839 2.54536 8.42421L7.99991 13.8788L11.6363 10.2424"
                  stroke={color ?? neutralDay.gray90}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.00009 3.69693L8.36372 3.3333C9.76954 1.92748 12.0488 1.92748 13.4546 3.3333V3.3333C14.8604 4.73911 14.8605 7.01839 13.4546 8.42421L8.70719 13.1716C8.31667 13.5622 7.68351 13.5622 7.29298 13.1716L5.091 10.9697"
                  stroke={color ?? neutralDay.gray90}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_1538_7596">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.1728 10.7668L13.9849 8.95465C15.6836 7.25594 15.6836 4.50179 13.9849 2.80308C12.3407 1.15889 9.70774 1.10613 8.00009 2.6448C6.29243 1.10613 3.65944 1.15889 2.01524 2.80308C0.316535 4.50179 0.316533 7.25594 2.01524 8.95465L4.55468 11.4941C4.55665 11.4961 4.55863 11.4981 4.56063 11.5001L6.76262 13.7021C6.76569 13.7052 6.76877 13.7082 6.77186 13.7113L7.46979 14.4092C7.76268 14.7021 8.23756 14.7021 8.53045 14.4092L12.1668 10.7728C12.1688 10.7708 12.1708 10.7688 12.1728 10.7668Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 20:
      switch (style) {
        case 'line':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1538_7556)">
                <path
                  d="M9.99989 4.62123L9.54534 4.16668C7.78807 2.40941 4.93898 2.40941 3.18171 4.16668V4.16668C1.42444 5.92395 1.42444 8.77305 3.18171 10.5303L9.99989 17.3485L14.5453 12.803"
                  stroke={color ?? neutralDay.gray90}
                  strokeWidth="1.75"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.0001 4.62123L10.4547 4.16668C12.2119 2.40941 15.061 2.40941 16.8183 4.16668V4.16668C18.5756 5.92395 18.5756 8.77305 16.8183 10.5303L10.7072 16.6414C10.3167 17.0319 9.68353 17.0319 9.293 16.6414L6.36375 13.7121"
                  stroke={color ?? neutralDay.gray90}
                  strokeWidth="1.75"
                  strokeLinecap="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_1538_7556">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.168 13.4177L17.4368 11.1489C19.5358 9.04996 19.5358 5.64684 17.4368 3.54787C15.3917 1.50277 12.1086 1.45027 9.99989 3.39037C7.89123 1.45027 4.6081 1.50277 2.563 3.54787C0.464025 5.64684 0.464025 9.04996 2.563 11.1489L5.741 14.3269L5.7448 14.3308L8.67406 17.26C8.67593 17.2619 8.67782 17.2638 8.6797 17.2656L9.38119 17.9671C9.72289 18.3088 10.2769 18.3088 10.6186 17.9671L15.1641 13.4217L15.168 13.4177Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 24:
      switch (style) {
        case 'line':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1536_7417)">
                <path
                  d="M12.0001 5.5454L11.4547 4.99995C9.34593 2.89122 5.92702 2.89122 3.81829 4.99994V4.99994C1.70957 7.10867 1.70957 10.5276 3.81829 12.6363L12.0001 20.8181L17.4547 15.3636"
                  stroke={color ?? neutralDay.gray90}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.9999 5.5454L12.5453 4.99995C14.6541 2.89122 18.073 2.89122 20.1817 4.99994V4.99994C22.2904 7.10867 22.2904 10.5276 20.1817 12.6363L12.707 20.111C12.3165 20.5015 11.6833 20.5015 11.2928 20.111L7.63625 16.4545"
                  stroke={color ?? neutralDay.gray90}
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_1536_7417">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.8889 13.3435C23.3881 10.8442 23.3881 6.79214 20.8889 4.29289C18.4428 1.84683 14.5093 1.79478 11.9999 4.13673C9.49058 1.79478 5.55707 1.84683 3.11101 4.29289C0.611761 6.79214 0.61176 10.8442 3.11101 13.3435L11.2928 21.5253C11.6834 21.9158 12.3165 21.9158 12.707 21.5253L13.4086 20.8237C13.4105 20.8219 13.4123 20.82 13.4142 20.8182L20.8889 13.3435Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
  }
}
