import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineOnlyIcon } from '../type';

export function ContentsLink({ size, color }: LineOnlyIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 8.66675L13 7.66675C14.2887 6.37808 14.2887 4.28875 13 3.00008V3.00008C11.7114 1.71142 9.62202 1.71142 8.33335 3.00008L7.33335 4.00008"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M3.99998 7.33325L2.99998 8.33325C1.71132 9.62192 1.71131 11.7113 2.99998 12.9999V12.9999C4.28864 14.2886 6.37798 14.2886 7.66665 12.9999L8.66665 11.9999"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M6 10L10 6"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 10.8333L16.25 9.58325C17.8608 7.97242 17.8608 5.36075 16.25 3.74992V3.74992C14.6391 2.13909 12.0275 2.13909 10.4166 3.74992L9.16665 4.99992"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <path
            d="M5.00002 9.16675L3.75002 10.4167C2.13919 12.0276 2.13919 14.6393 3.75002 16.2501V16.2501C5.36085 17.8609 7.97252 17.8609 9.58335 16.2501L10.8334 15.0001"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <path
            d="M7.5 12.5L12.5 7.5"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 13L19.5 11.5C21.433 9.567 21.433 6.433 19.5 4.5V4.5C17.567 2.567 14.433 2.567 12.5 4.5L11 6"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M6 11L4.5 12.5C2.567 14.433 2.567 17.567 4.5 19.5V19.5C6.433 21.433 9.567 21.433 11.5 19.5L13 18"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M9 15L15 9"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      );
  }
}
