import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function MenuKebabLine({ size, color }: LineIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="7" y="3" width="2" height="2" rx="1" fill={color ?? neutralDay.gray100} />
          <rect x="7" y="7" width="2" height="2" rx="1" fill={color ?? neutralDay.gray100} />
          <rect x="7" y="11" width="2" height="2" rx="1" fill={color ?? neutralDay.gray100} />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="8.75"
            y="3.75"
            width="2.5"
            height="2.5"
            rx="1.25"
            fill={color ?? neutralDay.gray100}
          />
          <rect
            x="8.75"
            y="8.75"
            width="2.5"
            height="2.5"
            rx="1.25"
            fill={color ?? neutralDay.gray100}
          />
          <rect
            x="8.75"
            y="13.75"
            width="2.5"
            height="2.5"
            rx="1.25"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="10.5" y="4.5" width="3" height="3" rx="1.5" fill={color ?? neutralDay.gray100} />
          <rect
            x="10.5"
            y="10.5"
            width="3"
            height="3"
            rx="1.5"
            fill={color ?? neutralDay.gray100}
          />
          <rect
            x="10.5"
            y="16.5"
            width="3"
            height="3"
            rx="1.5"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );
  }
}
