import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type FillIcon } from '../type';

export function InfoFill({
  size,
  primaryColor = neutralDay.white,
  secondaryColor = neutralDay.gray100,
}: FillIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="8"
            cy="8"
            r="6"
            fill={secondaryColor}
            stroke={secondaryColor}
            strokeWidth="1.4"
          />
          <rect
            x="8.33341"
            y="5.50016"
            width="0.666667"
            height="0.666667"
            rx="0.333333"
            transform="rotate(-180 8.33341 5.50016)"
            fill={primaryColor}
            stroke={primaryColor}
            strokeWidth="0.666667"
          />
          <path
            d="M8 10.8667L8 7.09337"
            stroke={primaryColor}
            strokeWidth="1.2"
            strokeLinecap="round"
          />
        </svg>
      );
    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="10"
            cy="10"
            r="7.5"
            fill={secondaryColor}
            stroke={secondaryColor}
            strokeWidth="1.75"
          />
          <rect
            x="10.4166"
            y="6.87508"
            width="0.833333"
            height="0.833333"
            rx="0.416667"
            transform="rotate(-180 10.4166 6.87508)"
            fill={primaryColor}
            stroke={primaryColor}
            strokeWidth="0.833333"
          />
          <path
            d="M10 13.5833L10 8.86658"
            stroke={primaryColor}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      );
    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="12"
            cy="12"
            r="9"
            fill={secondaryColor}
            stroke={secondaryColor}
            strokeWidth="2"
          />
          <rect
            x="12.5"
            y="8.25"
            width="1"
            height="1"
            rx="0.5"
            transform="rotate(-180 12.5 8.25)"
            fill={primaryColor}
            stroke={primaryColor}
          />
          <path
            d="M12 16.3L12 10.64"
            stroke={primaryColor}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
        </svg>
      );
  }
}
