import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineOnlyIcon } from '../type';

export function ContentsOnlineInterview({ size, color }: LineOnlyIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="2.33337"
            y="2.66663"
            width="11.3333"
            height="8"
            rx="1.66667"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <ellipse
            cx="8.00195"
            cy="6.05372"
            rx="1.45373"
            ry="1.45375"
            fill={color ?? neutralDay.gray90}
          />
          <path
            d="M7.99997 7.80298C6.80682 7.80298 5.19585 8.46019 4.77067 10.2666L4.58167 11.0697H11.3775L11.2359 10.2983C10.895 8.44153 9.16073 7.80298 7.99997 7.80298Z"
            fill={color ?? neutralDay.gray90}
          />
          <path
            d="M5.33337 13.3334H10.6667"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="2.91663"
            y="3.33337"
            width="14.1667"
            height="10"
            rx="1.66667"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <ellipse
            cx="10.0026"
            cy="7.56706"
            rx="1.81716"
            ry="1.81718"
            fill={color ?? neutralDay.gray90}
          />
          <path
            d="M9.99998 9.91687C8.55684 9.91687 6.63087 10.71 6.12236 12.8705L5.93335 13.6735H14.0258L13.8843 12.9022C13.4784 10.6914 11.4107 9.91687 9.99998 9.91687Z"
            fill={color ?? neutralDay.gray90}
          />
          <path
            d="M6.66663 16.6666H13.3333"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="3.5"
            y="4"
            width="17"
            height="12"
            rx="2"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
            strokeLinejoin="round"
          />
          <ellipse
            cx="12.0031"
            cy="9.08052"
            rx="2.1806"
            ry="2.18062"
            fill={color ?? neutralDay.gray90}
          />
          <path
            d="M12 12.031C10.3069 12.031 8.06589 12.9601 7.47404 15.4747L7.28503 16.2777H16.6742L16.5326 15.5064C16.0617 12.9414 13.6608 12.031 12 12.031Z"
            fill={color ?? neutralDay.gray90}
          />
          <path
            d="M8 20H16"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
}
