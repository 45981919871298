import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function TimerLine({ size, color }: LineIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.3323 8.66768C13.3323 11.6132 10.9445 14.001 7.99898 14.001C5.05346 14.001 2.66565 11.6132 2.66565 8.66768C2.66565 5.72217 5.05346 3.33435 7.99898 3.33435C10.9445 3.33435 13.3323 5.72217 13.3323 8.66768Z"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
          />
          <path
            d="M8 6V8.66667"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M13 3.66565L12.1144 4.55127"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M6 1.33435H10"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.6667 10.8334C16.6667 14.5153 13.6819 17.5 10 17.5C6.31814 17.5 3.33337 14.5153 3.33337 10.8334C3.33337 7.15146 6.31814 4.16669 10 4.16669C13.6819 4.16669 16.6667 7.15146 16.6667 10.8334Z"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
          />
          <path
            d="M10 7.5V10.8333"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <path
            d="M16.25 4.58331L15.143 5.69034"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <path
            d="M7.5 1.66669H12.5"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 13C20 17.4183 16.4183 21 12 21C7.58172 21 4 17.4183 4 13C4 8.58172 7.58172 5 12 5C16.4183 5 20 8.58172 20 13Z"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
          />
          <path
            d="M12 9V13"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M19.5 5.5L18.1716 6.82843"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M9 2H15"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      );
  }
}
