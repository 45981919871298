'use client';

import React from 'react';
import {
  buttonBaseCss,
  ButtonFill,
  buttonFillStyles,
  buttonRadiusStyles,
  buttonSizes,
  buttonVariants,
} from './button.css';
import { themeClass } from './theme.css';
import { Responsive } from '@/_global/_styles/design-system/types/responsive';
import useIsMobile from '@/_global/_domain/_business/_hook/useIsMobile';

export type ButtonSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type ButtonVariant = 'solid' | 'outline' | 'ghost' | 'link';
export type ButtonColorScheme = 'primary' | 'secondary' | 'tertiary';
export type ButtonRadius = 'squircle' | 'round';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: Responsive<ButtonSize>;
  variant?: ButtonVariant;
  colorScheme?: ButtonColorScheme;
  radius?: ButtonRadius;
  additional?: { left?: React.ReactNode; right?: React.ReactNode };
  fill?: ButtonFill;
}

export const Button = ({
  size = 'md',
  variant = 'solid',
  colorScheme = 'primary',
  radius = 'squircle',
  additional,
  children,
  fill,
  onClick = () => {},
  ...restProps
}: ButtonProps) => {
  const isNarrow = useIsMobile();

  // size prop을 기반으로 실제 적용할 size 결정
  let appliedSize: ButtonSize = 'md';
  if (typeof size === 'string') {
    appliedSize = size;
  } else if (typeof size === 'object') {
    appliedSize = isNarrow ? size.narrow || 'sm' : size.wide || 'md';
  }
  const sizeClass = buttonSizes[appliedSize];
  const variantClass = buttonVariants[colorScheme][variant];
  const radiusClass =
    radius === 'squircle'
      ? buttonRadiusStyles[radius][appliedSize]
      : buttonRadiusStyles[radius]['default'];
  const fillClass = fill ? buttonFillStyles[fill] : '';

  const className = `${buttonBaseCss} ${sizeClass} ${variantClass} ${radiusClass} ${themeClass} ${fillClass}`;

  return (
    <button onClick={onClick} className={className} {...restProps}>
      {additional?.left && <span className={buttonBaseCss}>{additional.left}</span>}
      {children}
      {additional?.right && <span className={buttonBaseCss}>{additional.right}</span>}
    </button>
  );
};
