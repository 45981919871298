import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineFillIcon } from '../type';

export function ContentsLockOpen({ size, style, color }: LineFillIcon) {
  switch (size) {
    case 16:
      switch (style) {
        case 'line':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="6.66669"
                y="8.66675"
                width="2.66667"
                height="2.66667"
                rx="1.33333"
                fill={color ?? neutralDay.gray90}
              />
              <path
                d="M5.33331 2.60667C5.74665 1.66 6.68665 1 7.77998 1C9.25331 1 10.4466 2.19333 10.4466 3.66667V6"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <rect
                x="3.33331"
                y="6"
                width="9.33333"
                height="8"
                rx="1.33333"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinejoin="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.77997 1.75C6.99698 1.75 6.31961 2.22205 6.02065 2.90677C5.8549 3.28638 5.41281 3.45975 5.0332 3.29401C4.65359 3.12826 4.48022 2.68617 4.64597 2.30656C5.17367 1.09795 6.37631 0.25 7.77997 0.25C9.66752 0.25 11.1966 1.77912 11.1966 3.66667V5.25H11.3333C12.4839 5.25 13.4166 6.18274 13.4166 7.33333V12.6667C13.4166 13.8173 12.4839 14.75 11.3333 14.75H4.66665C3.51605 14.75 2.58331 13.8173 2.58331 12.6667V7.33333C2.58331 6.18274 3.51605 5.25 4.66665 5.25H9.69664V3.66667C9.69664 2.60755 8.83909 1.75 7.77997 1.75ZM7.99998 8.66675C7.2636 8.66675 6.66665 9.2637 6.66665 10.0001C6.66665 10.7365 7.2636 11.3334 7.99998 11.3334C8.73636 11.3334 9.33332 10.7365 9.33332 10.0001C9.33332 9.2637 8.73636 8.66675 7.99998 8.66675Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 20:
      switch (style) {
        case 'line':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="8.33331"
                y="10.8333"
                width="3.33333"
                height="3.33333"
                rx="1.66667"
                fill={color ?? neutralDay.gray90}
              />
              <path
                d="M6.66669 3.25833C7.18335 2.075 8.35835 1.25 9.72502 1.25C11.5667 1.25 13.0584 2.74167 13.0584 4.58333V7.5"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
                strokeLinecap="round"
              />
              <rect
                x="4.16669"
                y="7.5"
                width="11.6667"
                height="10"
                rx="2"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.72501 2.125C8.7204 2.125 7.85181 2.73072 7.46858 3.60846C7.27521 4.05133 6.75943 4.2536 6.31656 4.06023C5.87368 3.86686 5.67141 3.35108 5.86478 2.90821C6.51488 1.41928 7.99629 0.375 9.72501 0.375C12.0499 0.375 13.9333 2.25842 13.9333 4.58333V6.62671C15.4749 6.6794 16.7084 7.94565 16.7084 9.5V15.5C16.7084 17.0878 15.4212 18.375 13.8334 18.375H6.16669C4.57887 18.375 3.29169 17.0878 3.29169 15.5V9.5C3.29169 7.91218 4.57887 6.625 6.16669 6.625H12.1833V4.58333C12.1833 3.22492 11.0834 2.125 9.72501 2.125ZM10 10.8333C9.07954 10.8333 8.33335 11.5794 8.33335 12.4999C8.33335 13.4204 9.07954 14.1666 10 14.1666C10.9205 14.1666 11.6667 13.4204 11.6667 12.4999C11.6667 11.5794 10.9205 10.8333 10 10.8333Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 24:
      switch (style) {
        case 'line':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="10" y="13" width="4" height="4" rx="2" fill={color ?? neutralDay.gray90} />
              <path
                d="M8 5.41C8.62 3.99 10.03 3 11.67 3C13.88 3 15.67 4.79 15.67 7V9"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="2"
                strokeLinecap="round"
              />
              <rect
                x="5"
                y="9"
                width="14"
                height="12"
                rx="2"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="2"
                strokeLinejoin="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.67 4C10.4438 4 9.38395 4.7394 8.91645 5.81014C8.69545 6.31629 8.106 6.54745 7.59985 6.32645C7.09371 6.10546 6.86255 5.516 7.08354 5.00986C7.85603 3.2406 9.61621 2 11.67 2C14.4323 2 16.67 4.23772 16.67 7V8H17C18.6569 8 20 9.34315 20 11V19C20 20.6569 18.6569 22 17 22H7C5.34315 22 4 20.6569 4 19V11C4 9.34315 5.34315 8 7 8H14.67V7C14.67 5.34228 13.3277 4 11.67 4ZM12 13C10.8954 13 10 13.8954 10 15C10 16.1046 10.8954 17 12 17C13.1046 17 14 16.1046 14 15C14 13.8954 13.1046 13 12 13Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
  }
}
