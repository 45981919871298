import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type FillIcon } from '../type';

export function ThumbUpFill({ size, primaryColor, secondaryColor }: FillIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="2"
            y="7.16553"
            width="1.33333"
            height="6"
            fill={secondaryColor ?? neutralDay.gray100}
            stroke={primaryColor ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <path
            d="M6 7.46547C6 6.84164 6.21871 6.23755 6.61808 5.75831L9.1972 2.66336C9.28346 2.55985 9.41124 2.5 9.54598 2.5C9.79673 2.5 10 2.70327 10 2.95402V5.16667C10 5.90305 10.597 6.5 11.3333 6.5H13.3333C13.7015 6.5 14 6.79848 14 7.16667V9.41304C14 9.6866 13.9159 9.95354 13.759 10.1777L12.0648 12.5979C11.8153 12.9544 11.4075 13.1667 10.9725 13.1667H6.66667C6.29848 13.1667 6 12.8682 6 12.5V7.46547Z"
            fill={secondaryColor ?? neutralDay.gray100}
          />
          <path
            d="M6 7.46547C6 6.84164 6.21871 6.23755 6.61808 5.75831L9.1972 2.66336C9.28346 2.55985 9.41124 2.5 9.54598 2.5C9.79673 2.5 10 2.70327 10 2.95402V5.16667C10 5.90305 10.597 6.5 11.3333 6.5H13.3333C13.7015 6.5 14 6.79848 14 7.16667V9.41304C14 9.6866 13.9159 9.95354 13.759 10.1777L12.0648 12.5979C11.8153 12.9544 11.4075 13.1667 10.9725 13.1667H6.66667C6.29848 13.1667 6 12.8682 6 12.5V7.46547Z"
            stroke={primaryColor ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 20:
      return (
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="2.5"
            y="8.83325"
            width="1.66667"
            height="7.5"
            fill={secondaryColor ?? neutralDay.gray100}
            stroke={primaryColor ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinejoin="round"
          />
          <path
            d="M7.5 9.20683C7.5 8.42704 7.77339 7.67194 8.2726 7.07289L11.4965 3.2042C11.6043 3.07481 11.7641 3 11.9325 3C12.2459 3 12.5 3.25409 12.5 3.56752V6.33333C12.5 7.25381 13.2462 8 14.1667 8H16.6667C17.1269 8 17.5 8.3731 17.5 8.83333V11.6413C17.5 11.9832 17.3948 12.3169 17.1987 12.5971L15.081 15.6224C14.7691 16.068 14.2594 16.3333 13.7156 16.3333H8.33333C7.8731 16.3333 7.5 15.9602 7.5 15.5V9.20683Z"
            fill={secondaryColor ?? neutralDay.gray100}
          />
          <path
            d="M7.5 9.20683C7.5 8.42704 7.77339 7.67194 8.2726 7.07289L11.4965 3.2042C11.6043 3.07481 11.7641 3 11.9325 3C12.2459 3 12.5 3.25409 12.5 3.56752V6.33333C12.5 7.25381 13.2462 8 14.1667 8H16.6667C17.1269 8 17.5 8.3731 17.5 8.83333V11.6413C17.5 11.9832 17.3948 12.3169 17.1987 12.5971L15.081 15.6224C14.7691 16.068 14.2594 16.3333 13.7156 16.3333H8.33333C7.8731 16.3333 7.5 15.9602 7.5 15.5V9.20683Z"
            stroke={primaryColor ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 24:
      return (
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="3"
            y="10.5"
            width="2"
            height="9"
            fill={secondaryColor ?? neutralDay.gray100}
            stroke={primaryColor ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinejoin="round"
          />
          <path
            d="M9 10.9482C9 10.0125 9.32806 9.10632 9.92711 8.38746L13.7958 3.74504C13.9252 3.58977 14.1169 3.5 14.319 3.5C14.6951 3.5 15 3.80491 15 4.18102V7.5C15 8.60457 15.8954 9.5 17 9.5H20C20.5523 9.5 21 9.94772 21 10.5V13.8696C21 14.2799 20.8738 14.6803 20.6385 15.0165L18.0972 18.6469C17.7229 19.1816 17.1113 19.5 16.4587 19.5H10C9.44772 19.5 9 19.0523 9 18.5V10.9482Z"
            fill={secondaryColor ?? neutralDay.gray100}
          />
          <path
            d="M9 10.9482C9 10.0125 9.32806 9.10632 9.92711 8.38746L13.7958 3.74504C13.9252 3.58977 14.1169 3.5 14.319 3.5C14.6951 3.5 15 3.80491 15 4.18102V7.5C15 8.60457 15.8954 9.5 17 9.5H20C20.5523 9.5 21 9.94772 21 10.5V13.8696C21 14.2799 20.8738 14.6803 20.6385 15.0165L18.0972 18.6469C17.7229 19.1816 17.1113 19.5 16.4587 19.5H10C9.44772 19.5 9 19.0523 9 18.5V10.9482Z"
            stroke={primaryColor ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
}
