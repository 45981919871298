import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function MoreLine({ size, color }: LineIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="7" y="3.5" width="2" height="2" rx="1" fill={color ?? neutralDay.gray100} />
          <rect x="7" y="7.5" width="2" height="2" rx="1" fill={color ?? neutralDay.gray100} />
          <rect x="7" y="11.5" width="2" height="2" rx="1" fill={color ?? neutralDay.gray100} />
        </svg>
      );
    case 20:
      return (
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="8.75"
            y="4.25"
            width="2.5"
            height="2.5"
            rx="1.25"
            fill={color ?? neutralDay.gray100}
          />
          <rect
            x="8.75"
            y="9.25"
            width="2.5"
            height="2.5"
            rx="1.25"
            fill={color ?? neutralDay.gray100}
          />
          <rect
            x="8.75"
            y="14.25"
            width="2.5"
            height="2.5"
            rx="1.25"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );
    case 24:
      return (
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="10" y="5.5" width="3" height="3" rx="1.5" fill={color ?? neutralDay.gray100} />
          <rect x="10" y="11.5" width="3" height="3" rx="1.5" fill={color ?? neutralDay.gray100} />
          <rect x="10" y="17.5" width="3" height="3" rx="1.5" fill={color ?? neutralDay.gray100} />
        </svg>
      );
  }
}
