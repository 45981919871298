import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineFillIcon } from '../type';

export function ActionBookmark({ size, style, color }: LineFillIcon) {
  switch (size) {
    case 16:
      switch (style) {
        case 'line':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.33447 3.33333C3.33447 2.59695 3.93143 2 4.66781 2H11.3345C12.0709 2 12.6678 2.59695 12.6678 3.33333V14L8.00114 11L3.33447 14V3.33333Z"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.5"
                strokeLinejoin="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.66781 1.25C3.51721 1.25 2.58447 2.18274 2.58447 3.33333V14C2.58447 14.2744 2.73428 14.5268 2.97508 14.6583C3.21589 14.7898 3.50926 14.7792 3.74004 14.6309L8.00114 11.8916L12.2622 14.6309C12.493 14.7792 12.7864 14.7898 13.0272 14.6583C13.268 14.5268 13.4178 14.2744 13.4178 14V3.33333C13.4178 2.18274 12.4851 1.25 11.3345 1.25H4.66781Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 20:
      switch (style) {
        case 'line':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.16675 4.16667C4.16675 3.24619 4.91294 2.5 5.83341 2.5H14.1667C15.0872 2.5 15.8334 3.24619 15.8334 4.16667V17.5L10.0001 13.75L4.16675 17.5V4.16667Z"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="1.75"
                strokeLinejoin="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.83341 1.625C4.42969 1.625 3.29175 2.76294 3.29175 4.16667V17.5C3.29175 17.8201 3.46652 18.1146 3.74746 18.268C4.0284 18.4214 4.37067 18.4091 4.63991 18.236L10.0001 14.7902L15.3603 18.236C15.6295 18.4091 15.9718 18.4214 16.2527 18.268C16.5336 18.1146 16.7084 17.8201 16.7084 17.5V4.16667C16.7084 2.76294 15.5705 1.625 14.1667 1.625H5.83341Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
      break;
    case 24:
      switch (style) {
        case 'line':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 5C5 3.89543 5.89543 3 7 3H17C18.1046 3 19 3.89543 19 5V21L12 16.5L5 21V5Z"
                stroke={color ?? neutralDay.gray90}
                strokeWidth="2"
                strokeLinejoin="round"
              />
            </svg>
          );
        case 'fill':
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 2C5.34315 2 4 3.34315 4 5V21C4 21.3658 4.19974 21.7024 4.52081 21.8777C4.84189 22.053 5.23305 22.039 5.54076 21.8412L12 17.6888L18.4592 21.8412C18.767 22.039 19.1581 22.053 19.4792 21.8777C19.8003 21.7024 20 21.3658 20 21V5C20 3.34315 18.6569 2 17 2H7Z"
                fill={color ?? neutralDay.gray90}
              />
            </svg>
          );
      }
  }
}
