import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function ArrowDropDownLine({ size, color }: LineIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.53033 6.13373C4.23744 5.84084 3.76256 5.84084 3.46967 6.13373C3.17678 6.42663 3.17678 6.9015 3.46967 7.19439L4.53033 6.13373ZM8 10.6641L7.46967 11.1944C7.76256 11.4873 8.23744 11.4873 8.53033 11.1944L8 10.6641ZM12.5303 7.19439C12.8232 6.9015 12.8232 6.42663 12.5303 6.13373C12.2374 5.84084 11.7626 5.84084 11.4697 6.13373L12.5303 7.19439ZM3.46967 7.19439L7.46967 11.1944L8.53033 10.1337L4.53033 6.13373L3.46967 7.19439ZM8.53033 11.1944L12.5303 7.19439L11.4697 6.13373L7.46967 10.1337L8.53033 11.1944Z"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.61872 7.71331C5.27701 7.3716 4.72299 7.3716 4.38128 7.71331C4.03957 8.05502 4.03957 8.60904 4.38128 8.95075L5.61872 7.71331ZM10 13.332L9.38128 13.9507C9.72299 14.2925 10.277 14.2925 10.6187 13.9507L10 13.332ZM15.6187 8.95075C15.9604 8.60904 15.9604 8.05502 15.6187 7.71331C15.277 7.3716 14.723 7.3716 14.3813 7.71331L15.6187 8.95075ZM4.38128 8.95075L9.38128 13.9507L10.6187 12.7133L5.61872 7.71331L4.38128 8.95075ZM10.6187 13.9507L15.6187 8.95075L14.3813 7.71331L9.38128 12.7133L10.6187 13.9507Z"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.70711 9.29289C6.31658 8.90237 5.68342 8.90237 5.29289 9.29289C4.90237 9.68342 4.90237 10.3166 5.29289 10.7071L6.70711 9.29289ZM12 16L11.2929 16.7071C11.6834 17.0976 12.3166 17.0976 12.7071 16.7071L12 16ZM18.7071 10.7071C19.0976 10.3166 19.0976 9.68342 18.7071 9.29289C18.3166 8.90237 17.6834 8.90237 17.2929 9.29289L18.7071 10.7071ZM5.29289 10.7071L11.2929 16.7071L12.7071 15.2929L6.70711 9.29289L5.29289 10.7071ZM12.7071 16.7071L18.7071 10.7071L17.2929 9.29289L11.2929 15.2929L12.7071 16.7071Z"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );
  }
}
