import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function LockLine({ size, color }: LineIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="3.33331"
            y="6"
            width="9.33333"
            height="8"
            rx="1.33333"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <rect
            x="6.66669"
            y="8.66669"
            width="2.66667"
            height="2.66667"
            rx="1.33333"
            fill={color ?? neutralDay.gray100}
          />
          <path
            d="M5.33331 6V4.66667C5.33331 3.19333 6.52665 2 7.99998 2C9.47331 2 10.6666 3.19333 10.6666 4.66667V6"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="4.16669"
            y="7.5"
            width="11.6667"
            height="10"
            rx="2"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
          />
          <rect
            x="8.33331"
            y="10.8333"
            width="3.33333"
            height="3.33333"
            rx="1.66667"
            fill={color ?? neutralDay.gray100}
          />
          <path
            d="M6.66669 7.5V5.83333C6.66669 3.99167 8.15835 2.5 10 2.5C11.8417 2.5 13.3334 3.99167 13.3334 5.83333V7.5"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="5"
            y="9"
            width="14"
            height="12"
            rx="2"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinejoin="round"
          />
          <rect x="10" y="13" width="4" height="4" rx="2" fill={color ?? neutralDay.gray100} />
          <path
            d="M8 9V7C8 4.79 9.79 3 12 3C14.21 3 16 4.79 16 7V9"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
          />
        </svg>
      );
  }
}
