import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function MyClassLine({ size, color }: LineIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 13.3344H12"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <rect
            x="2.6687"
            y="2.66565"
            width="10.6667"
            height="8"
            rx="1.33333"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.5"
          />
          <path
            d="M7 5.60142V7.73192C7 7.99373 7.28798 8.15334 7.51 8.01458L9.2144 6.94933C9.42329 6.81878 9.42329 6.51456 9.2144 6.384L7.51 5.31875C7.28798 5.17999 7 5.3396 7 5.60142Z"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 16.6667H15"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <rect
            x="3.33331"
            y="3.33331"
            width="13.3333"
            height="10"
            rx="1.66667"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="1.75"
          />
          <path
            d="M8.75 7.00177V9.6649C8.75 9.99216 9.10998 10.1917 9.3875 10.0182L11.518 8.68667C11.7791 8.52347 11.7791 8.1432 11.518 7.98L9.3875 6.64844C9.10998 6.47499 8.75 6.6745 8.75 7.00177Z"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 20H18"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
            strokeLinecap="round"
          />
          <rect
            x="4"
            y="4"
            width="16"
            height="12"
            rx="2"
            stroke={color ?? neutralDay.gray100}
            strokeWidth="2"
          />
          <path
            d="M10.5 8.40212V11.5979C10.5 11.9906 10.932 12.23 11.265 12.0219L13.8216 10.424C14.1349 10.2282 14.1349 9.77183 13.8216 9.576L11.265 7.97812C10.932 7.76998 10.5 8.00941 10.5 8.40212Z"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );
  }
}
