enum AdditionalColors {
  Red100 = 'red100',
  Red80 = 'red80',
  Red10 = 'red10',
  Green100 = 'green100',
  Green80 = 'green80',
  Green10 = 'green10',
  Yellow100 = 'yellow100',
  Yellow80 = 'yellow80',
  Yellow10 = 'yellow10',
  Blue100 = 'blue100',
  Blue50 = 'blue50',
  Blue10 = 'blue10',
  Orange100 = 'orange100',
  Orange10 = 'orange10',
  Mint100 = 'mint100',
  Navy100 = 'navy100',
}

export const additional: Record<AdditionalColors, string> = {
  [AdditionalColors.Red100]: '#FF3E3E',
  [AdditionalColors.Red80]: '#FF6666',
  [AdditionalColors.Red10]: '#FFEBEB',
  [AdditionalColors.Green100]: '#31B32E',
  [AdditionalColors.Green80]: '#66E063',
  [AdditionalColors.Green10]: '#E7F9E7',
  [AdditionalColors.Yellow100]: '#F5B200',
  [AdditionalColors.Yellow80]: '#FFCB40',
  [AdditionalColors.Yellow10]: '#FFF7E0',
  [AdditionalColors.Blue100]: '#0085FF',
  [AdditionalColors.Blue50]: '#80C2FF',
  [AdditionalColors.Blue10]: '#EDF3FF',
  [AdditionalColors.Orange100]: '#FF7005',
  [AdditionalColors.Orange10]: '#FFF0E5',
  [AdditionalColors.Mint100]: '#2EC4B6',
  [AdditionalColors.Navy100]: '#031D44',
};
