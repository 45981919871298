import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function ArrowBackLine({ size, color }: LineIcon) {
  switch (size) {
    case 20:
      return (
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_575_15936)">
            <path
              d="M16.6673 9.66683H6.52565L11.184 5.0085L10.0007 3.8335L3.33398 10.5002L10.0007 17.1668L11.1757 15.9918L6.52565 11.3335H16.6673V9.66683Z"
              fill={color ?? neutralDay.gray100}
            />
          </g>
          <defs>
            <clipPath id="clip0_575_15936">
              <rect width="20" height="20" fill="none" transform="translate(0 0.5)" />
            </clipPath>
          </defs>
        </svg>
      );
    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_575_15648)">
            <path
              d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z"
              fill={color ?? neutralDay.gray100}
            />
          </g>
          <defs>
            <clipPath id="clip0_575_15648">
              <rect width="24" height="24" fill="none" />
            </clipPath>
          </defs>
        </svg>
      );
    default:
      return null;
  }
}
