import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineOnlyIcon } from '../type';

export function ActionSorting({ size, color }: LineOnlyIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.61423 3.13442C7.81422 2.89194 8.18569 2.89194 8.38569 3.13442L10.2119 5.34861C10.4809 5.67473 10.2489 6.16675 9.82619 6.16675H6.17373C5.75099 6.16675 5.51902 5.67473 5.788 5.34861L7.61423 3.13442Z"
            fill={color ?? neutralDay.gray90}
          />
          <path
            d="M8.38577 12.8656C8.18578 13.1081 7.81431 13.1081 7.61431 12.8656L5.78808 10.6514C5.5191 10.3253 5.75108 9.83325 6.17381 9.83325L9.82627 9.83325C10.249 9.83325 10.481 10.3253 10.212 10.6514L8.38577 12.8656Z"
            fill={color ?? neutralDay.gray90}
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.53717 3.89458C9.77716 3.6036 10.2229 3.60361 10.4629 3.89458L12.7987 6.7266C13.1215 7.11795 12.8431 7.70837 12.3359 7.70837H7.66423C7.15695 7.70837 6.87858 7.11795 7.20135 6.7266L9.53717 3.89458Z"
            fill={color ?? neutralDay.gray90}
          />
          <path
            d="M10.4628 16.1054C10.2228 16.3964 9.77708 16.3964 9.53709 16.1054L7.20127 13.2734C6.8785 12.8821 7.15686 12.2916 7.66414 12.2916L12.3358 12.2916C12.8431 12.2916 13.1214 12.8821 12.7986 13.2734L10.4628 16.1054Z"
            fill={color ?? neutralDay.gray90}
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.46 4.65474C11.74 4.31527 12.26 4.31527 12.54 4.65474L15.3854 8.1046C15.762 8.56117 15.4372 9.25 14.8454 9.25H9.1546C8.56277 9.25 8.23801 8.56117 8.61458 8.1046L11.46 4.65474Z"
            fill={color ?? neutralDay.gray90}
          />
          <path
            d="M12.54 19.3453C12.26 19.6847 11.74 19.6847 11.46 19.3453L8.61458 15.8954C8.23801 15.4388 8.56277 14.75 9.1546 14.75L14.8454 14.75C15.4372 14.75 15.762 15.4388 15.3854 15.8954L12.54 19.3453Z"
            fill={color ?? neutralDay.gray90}
          />
        </svg>
      );
  }
}
