import { type LineOnlyIcon } from '../type';
import { neutralDay } from '@/_global/_styles/design-system/foundation/color';

export function ContentsEdit({ size, color }: LineOnlyIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 9.83333V12.1667C12 12.8083 11.475 13.3333 10.8333 13.3333H3.83333C3.19166 13.3333 2.66666 12.8083 2.66666 12.1667V5.16667C2.66666 4.525 3.19166 4 3.83333 4H6.16667"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M6.66666 9.33333L7.33333 6.66667L12 2L14 4L9.33333 8.66667L6.66666 9.33333Z"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 12.2917V15.2083C15 16.0104 14.3437 16.6667 13.5417 16.6667H4.79167C3.98958 16.6667 3.33333 16.0104 3.33333 15.2083V6.45833C3.33333 5.65625 3.98958 5 4.79167 5H7.70833"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
            strokeLinecap="round"
          />
          <path
            d="M8.33333 11.6667L9.16667 8.33333L15 2.5L17.5 5L11.6667 10.8333L8.33333 11.6667Z"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="1.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 14.75V18.25C18 19.2125 17.2125 20 16.25 20H5.75C4.7875 20 4 19.2125 4 18.25V7.75C4 6.7875 4.7875 6 5.75 6H9.25"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 14L11 10L18 3L21 6L14 13L10 14Z"
            stroke={color ?? neutralDay.gray90}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
}
