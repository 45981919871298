import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { type LineIcon } from '../type';

export function BookmarkLine({ size, color }: LineIcon) {
  switch (size) {
    case 16:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.6677 14L12.2621 14.6309C12.4929 14.7792 12.7863 14.7898 13.0271 14.6583C13.2679 14.5268 13.4177 14.2744 13.4177 14H12.6677ZM3.33435 14H2.58435C2.58435 14.2744 2.73415 14.5268 2.97496 14.6583C3.21576 14.7898 3.50914 14.7792 3.73992 14.6309L3.33435 14ZM8.00102 11L8.40659 10.3691C8.15953 10.2103 7.8425 10.2103 7.59545 10.3691L8.00102 11ZM4.66768 2.75H11.3344V1.25H4.66768V2.75ZM11.9177 3.33333V14H13.4177V3.33333H11.9177ZM4.08435 14V3.33333H2.58435V14H4.08435ZM13.0733 13.3691L8.40659 10.3691L7.59545 11.6309L12.2621 14.6309L13.0733 13.3691ZM7.59545 10.3691L2.92878 13.3691L3.73992 14.6309L8.40659 11.6309L7.59545 10.3691ZM11.3344 2.75C11.6565 2.75 11.9177 3.01117 11.9177 3.33333H13.4177C13.4177 2.18274 12.4849 1.25 11.3344 1.25V2.75ZM4.66768 1.25C3.51709 1.25 2.58435 2.18274 2.58435 3.33333H4.08435C4.08435 3.01117 4.34552 2.75 4.66768 2.75V1.25Z"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );

    case 20:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.8333 17.5L15.3601 18.236C15.6294 18.4091 15.9716 18.4214 16.2526 18.268C16.5335 18.1146 16.7083 17.8201 16.7083 17.5H15.8333ZM4.16663 17.5H3.29163C3.29163 17.8201 3.4664 18.1146 3.74734 18.268C4.02828 18.4214 4.37054 18.4091 4.63979 18.236L4.16663 17.5ZM9.99996 13.75L10.4731 13.014C10.1849 12.8287 9.81503 12.8287 9.5268 13.014L9.99996 13.75ZM5.83329 3.375H14.1666V1.625H5.83329V3.375ZM14.9583 4.16667V17.5H16.7083V4.16667H14.9583ZM5.04163 17.5V4.16667H3.29163V17.5H5.04163ZM16.3065 16.764L10.4731 13.014L9.5268 14.486L15.3601 18.236L16.3065 16.764ZM9.5268 13.014L3.69346 16.764L4.63979 18.236L10.4731 14.486L9.5268 13.014ZM14.1666 3.375C14.6039 3.375 14.9583 3.72944 14.9583 4.16667H16.7083C16.7083 2.76294 15.5703 1.625 14.1666 1.625V3.375ZM5.83329 1.625C4.42957 1.625 3.29163 2.76294 3.29163 4.16667H5.04163C5.04163 3.72944 5.39607 3.375 5.83329 3.375V1.625Z"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );

    case 24:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 21L18.4592 21.8412C18.767 22.039 19.1581 22.053 19.4792 21.8777C19.8003 21.7024 20 21.3658 20 21H19ZM5 21H4C4 21.3658 4.19974 21.7024 4.52081 21.8777C4.84189 22.053 5.23305 22.039 5.54076 21.8412L5 21ZM12 16.5L12.5408 15.6588C12.2113 15.4471 11.7887 15.4471 11.4592 15.6588L12 16.5ZM7 4H17V2H7V4ZM18 5V21H20V5H18ZM6 21V5H4V21H6ZM19.5408 20.1588L12.5408 15.6588L11.4592 17.3412L18.4592 21.8412L19.5408 20.1588ZM11.4592 15.6588L4.45924 20.1588L5.54076 21.8412L12.5408 17.3412L11.4592 15.6588ZM17 4C17.5523 4 18 4.44772 18 5H20C20 3.34315 18.6569 2 17 2V4ZM7 2C5.34315 2 4 3.34315 4 5H6C6 4.44772 6.44772 4 7 4V2Z"
            fill={color ?? neutralDay.gray100}
          />
        </svg>
      );
  }
}
